import { Tooltip } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import Calculator from '../../../images/Calculator.svg';
import { GEORGIA_CODE, LANGUAGE_CODES, NUMBER_REGX, NUMBER_REGX_DECIMAL, SCREEN_NAMES } from '../../../shared/globalConstants';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { DEPENDENTS, ELIGIBILITIES, HOUSEHOLDINCOME } from '../../public/estimate-savings/constants';
import { subsidyCalculator } from '../../public/estimate-savings/estimateSavings.service';
import { default as Content } from './subsidyBlockForm.json';
import "./subsidyBlockForm.scss";

interface calculateForm {
    houseHoldIncome: string;
    dependents: string;
}

const SubsidyBlockForm: React.FC = (props) => {
    const { updateEstimateSavingsResponse, updateIsSubsidydataApplied, householdMemberValues, updateHouseholdMemberValues, agentZipcode, agentState, agentCountyCode, agentCoverageDate, agentApplicantFormDetails, agentFormSubmitedWithSubsidy, subsidyresponse, agentUpdateApplicantFormDetails } = useGlobalStore((state) => state);
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [subsidySuccess, setSubsidySuccess] = useState<any>(null);
    const [subsidyAmount, setSubsidyAmount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [medicaidligibilitylist, setMedicaidEligibilitylist] = useState<Applicant[]>([]);
    const [notMedicaidligibilitylist, setNotMedicaidEligibilitylist] = useState<Applicant[]>([]);
    const [isPrimaryApplicantEligible, setisPrimaryApplicantEligible] = useState(false);
    const { register, control, handleSubmit, formState } = useForm<calculateForm>({
        mode: 'all',
        defaultValues: {
            houseHoldIncome: '',
            dependents: '0'
        },
        values: {
            houseHoldIncome: householdMemberValues.houseHoldIncome,
            dependents: householdMemberValues.dependents
        }
    });
    // const [inputs, setInputs] = useState({
    //     houseHoldIncome: '',
    //     dependents: '0'
    // });

    const { errors } = formState;

    useEffect(() => {
        initiate();
    }, []);

    useEffect(() => {
        if (agentFormSubmitedWithSubsidy && subsidyresponse && subsidyresponse.subsidyCalc && subsidyresponse.subsidyCalc.length > 0) {
            setSubsidySuccess(true);
            setSubsidyAmount(subsidyresponse.subsidyCalc[0].subsidyAmount);
        }
    }, [agentFormSubmitedWithSubsidy])


    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.SUBSIDY_BLOCK_FORM);
            let cmsResponse = response.data;
            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.agentSubsidy);
        } catch (error) {
            console.error('agent Estimate savings.tsx getContent error: ', error);
            setContent(Content.data.iospagecontentList.items[0].pageContent.agentSubsidy);
            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };


    const submitCaculateForm = handleSubmit((data) => {
        updateHouseholdMemberValues(data);
        setIsLoading(true);
        subsidyCalculator(data, agentZipcode, agentCountyCode, agentCoverageDate, agentApplicantFormDetails).then((response) => {
            setIsLoading(false);
            if (response.data.status === 'SUCCESS') {
                if (response.data.subsidyCalc[0].subsidyAmount > 0) {
                    getMedicAidEligibilityList(response.data);
                    setSubsidySuccess(true);
                    setSubsidyAmount(response.data.subsidyCalc[0].subsidyAmount);
                } else {
                    getMedicAidEligibilityList(response.data);
                    setSubsidySuccess(false);
                    setSubsidyAmount(0);
                }
            } else {
                setSubsidySuccess(null);
            }
            updateEstimateSavingsResponse(response.data);
            updateIsSubsidydataApplied(true);
        });
    });

    const getMedicAidEligibilityList = (data) => {
        const responseData = data && data?.subsidyCalc && data.subsidyCalc[0].householdMemberElig;
        if (responseData) {
            const result = agentApplicantFormDetails.filter(
                (o1) => !responseData.some((o2) => o1.applicantId === o2.memberTempId && !ELIGIBILITIES.includes(o2.eligibility))
            );
            setMedicaidEligibilitylist(result);
            const notEligibleList = agentApplicantFormDetails.filter(
                (o1) => !responseData.some((o2) => o1.applicantId === o2.memberTempId && ELIGIBILITIES.includes(o2.eligibility))
            );
            setNotMedicaidEligibilitylist(notEligibleList);
            const medicaidLookUp = new Map<string | undefined, boolean>();
            responseData.forEach(obj => medicaidLookUp.set(obj.memberTempId, ELIGIBILITIES.includes(obj.eligibility)));
            const updatedResult = agentApplicantFormDetails.map(obj => {
                const isMedicAidEligible = medicaidLookUp.get(obj.applicantId);
                return { ...obj, isMedicAidEligible };
            });
            agentUpdateApplicantFormDetails(updatedResult);
            const uniqueGroups = [...new Set(result.map((item) => item.applicantId))];
            if (uniqueGroups.includes(agentApplicantFormDetails[0].applicantId)) {
                setisPrimaryApplicantEligible(true);
            }
        }
    };

    const handleValue = (event) => {
        const name = event.target.name;
        const value = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = value;
        // setInputs((values) => ({ ...values, [name]: value }));
    };
    // Add your component logic here

    if (!contentLoaded || isLoading) {
        return <FullPageLoader />;
    } else {
        return (
            <div>
                <div className="estimatesavings agent">

                    <div className="fwc-row">
                        <div className="fwc-col-12">
                            <div className="dependents-block">
                                <div className="estimate-options-flex dflex" id="estimate-options-flex">
                                    <div className="icon-container" id="icon-container">
                                        <img id="calculator-image" alt="" src={Calculator} />
                                    </div>
                                    <div className="estimateLandingPage-description-container">
                                        <h3 id="getMonthlyDiscount-title">{pageContent?.TITLE}</h3>
                                    </div>
                                </div>
                                <p className="mb30" id="getMonthlyDiscount-description">{pageContent?.DESCRIPTION}</p>
                                <div className='fwc-row content-space-between'>
                                    <div className='fwc-col-4'>
                                        <form className="registration-wrapper" onSubmit={submitCaculateForm} noValidate>
                                            <div className="form-field-area">
                                                <div className="fwc-input">
                                                    <div className="form-control bold-label">
                                                        <div className="label-wrapper">
                                                            <label htmlFor="householdincome">{pageContent?.FORM_CONTENT.LABELS.HOUSE_HOLD_INCOME}</label>
                                                            <Tooltip icon message={pageContent?.FORM_CONTENT.TOOLTIPS.HOUSE_HOLD_INCOME}></Tooltip>
                                                        </div>
                                                        <div className="input-icon-wrapper">
                                                            <i className="sae-icon sae-icon-cheap-2"></i>
                                                            <input
                                                                id="householdincome"
                                                                type="text"
                                                                maxLength={HOUSEHOLDINCOME.MAX_LENGTH}
                                                                className={errors.houseHoldIncome ? 'is-invalid' : ''}
                                                                {...register('houseHoldIncome', {
                                                                    required: {
                                                                        value: true,
                                                                        message: pageContent?.ERRORS.HOUSE_HOLD_INCOME.REQUIRED
                                                                    },
                                                                    pattern: {
                                                                        value: NUMBER_REGX_DECIMAL,
                                                                        message: pageContent?.ERRORS.HOUSE_HOLD_INCOME.NUMBERS_ONLY
                                                                    },
                                                                    maxLength: {
                                                                        value: HOUSEHOLDINCOME.MAX_LENGTH,
                                                                        message: pageContent?.ERRORS.HOUSE_HOLD_INCOME.MAX
                                                                    },
                                                                    max: {
                                                                        value: HOUSEHOLDINCOME.MAX_VALUE,
                                                                        message: pageContent?.ERRORS.HOUSE_HOLD_INCOME.INVALID_INCOME
                                                                    },
                                                                    onChange: (e) => {
                                                                        handleValue(e);
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        {errors.houseHoldIncome?.message && <p className="error">{errors.houseHoldIncome?.message}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-field-area">
                                                <div className="fwc-input">
                                                    <div className="form-control bold-label">
                                                        <div className="label-wrapper">
                                                            <label htmlFor="dependentscount">{pageContent?.FORM_CONTENT.LABELS.ADDITIONAL_DEPS}</label>
                                                            <Tooltip
                                                                icon
                                                                message={pageContent?.FORM_CONTENT.TOOLTIPS.ADDITIONAL_DEPS}
                                                            ></Tooltip>
                                                        </div>
                                                        <input
                                                            id="dependentscount"
                                                            type="string"
                                                            maxLength={DEPENDENTS.MAX_LENGTH}
                                                            className={errors.dependents ? 'is-invalid' : ''}
                                                            {...register('dependents', {
                                                                required: false,
                                                                pattern: {
                                                                    value: NUMBER_REGX,
                                                                    message: pageContent?.ERRORS.DEPENDENTS.NUMBERS_ONLY
                                                                },
                                                                maxLength: {
                                                                    value: DEPENDENTS.MAX_LENGTH,
                                                                    message: pageContent?.ERRORS.DEPENDENTS.MAX
                                                                },
                                                                max: {
                                                                    value: DEPENDENTS.MAX_VALUE,
                                                                    message: pageContent?.ERRORS.DEPENDENTS.INVALID_VALUE
                                                                },
                                                                min: {
                                                                    value: DEPENDENTS.MIN_VALUE,
                                                                    message: pageContent?.ERRORS.DEPENDENTS.INVALID_VALUE
                                                                },
                                                                onChange: (e) => {
                                                                    handleValue(e);
                                                                }
                                                            })}
                                                        />
                                                        {errors.dependents?.message && <p className="error">{errors.dependents?.message}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cost-actions fwc-row">
                                                <div className="button-wrapper">
                                                    <button data-analytics="calcDiscountBtnFedTaxCreditIos" className="fwc-btn fwc-btn-primary" id="calculateDiscount" type="submit" onClick={submitCaculateForm}>
                                                        {pageContent?.FORM_CONTENT.LABELS.ESTIMATE_SUBSIDY}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='fwc-col-7'>
                                        {subsidySuccess === null && <div className="subsidy-response">
                                            <i className='sae-icon sae-icon-question'></i>
                                            <p>{pageContent?.RESPONSE_BLOCK}</p>
                                        </div>}
                                        {subsidySuccess !== null && subsidySuccess && <div className="subsidy-response success">
                                            <div className='block'>
                                                <i className='sae-icon sae-icon-checked'></i>
                                                <div>
                                                    <h5>{pageContent?.SUBSIDY_SUCCESS.TITLE}</h5>
                                                    <p>{pageContent?.SUBSIDY_SUCCESS.DESCRIPTION}</p>
                                                    <p><span className='amount'>{getFormattedCurrency(subsidyAmount)}</span>{pageContent?.SUBSIDY_SUCCESS.MONTH}<Tooltip
                                                        icon
                                                        message={pageContent?.FORM_CONTENT.TOOLTIPS.TAX_SUBSIDY}
                                                    ></Tooltip></p>
                                                </div>
                                            </div>
                                            <div className='block'>
                                                <i className='sae-icon sae-icon-checked visibilityhid'></i>
                                                <div>
                                                    <h5>{pageContent?.COST_SHARE.TITLE} <Tooltip
                                                        icon
                                                        message={pageContent?.FORM_CONTENT.TOOLTIPS.CSR}
                                                    ></Tooltip></h5>
                                                    <p>{pageContent?.COST_SHARE.DESCRIPTION}</p>
                                                </div>
                                            </div>
                                        </div>}
                                        {subsidySuccess !== null && !subsidySuccess && medicaidligibilitylist?.length === 0 &&
                                            <div className="subsidy-response fail">
                                                <div className='block'>
                                                    <i className='sae-icon sae-icon-cancel'></i>
                                                    <div>
                                                        <h5>{pageContent?.SUBSIDY_FAIL.TITLE}</h5>
                                                        <p>{pageContent?.SUBSIDY_FAIL.DESCRIPTION} <Tooltip
                                                            icon
                                                            message={pageContent?.FORM_CONTENT.TOOLTIPS.TAX_SUBSIDY}
                                                        ></Tooltip></p>
                                                    </div>
                                                </div>
                                                <div className='block'>
                                                    <i className='sae-icon sae-icon-cancel visibilityhid'></i>
                                                    <div>
                                                        <h5>{pageContent?.COST_SHARE.TITLE}<Tooltip
                                                            icon
                                                            message={pageContent?.FORM_CONTENT.TOOLTIPS.CSR}
                                                        ></Tooltip></h5>
                                                        <p>{pageContent?.COST_SHARE.DESCRIPTION}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {subsidySuccess !== null && !subsidySuccess && medicaidligibilitylist?.length !== 0 &&
                                            <div className="subsidy-response fail">
                                                <div className='block'>
                                                    <i className='sae-icon sae-icon-cancel visibilityhid'></i>
                                                    <div>
                                                        <h5>{pageContent?.COST_SHARE.TITLE}<Tooltip
                                                            icon
                                                            message={pageContent?.FORM_CONTENT.TOOLTIPS.CSR}
                                                        ></Tooltip></h5>
                                                        <p>{pageContent?.COST_SHARE.DESCRIPTION}</p>
                                                    </div>
                                                </div>
                                                {agentState === GEORGIA_CODE && <div className='block'>
                                                    <i className='sae-icon sae-icon-cancel'></i>
                                                    <div>
                                                        <h5>{pageContent?.NOT_ELIGIBLE_GA.TITLE}</h5>
                                                        <p>{pageContent?.NOT_ELIGIBLE_GA.DESCRIPTION}</p>
                                                    </div>
                                                </div>}
                                                {agentState !== GEORGIA_CODE && <div className='block'>
                                                    <i className='sae-icon sae-icon-cancel'></i>
                                                    <div>
                                                        <h5>{pageContent?.NOT_ELIGIBLE.TITLE}<Tooltip
                                                            icon
                                                            message={pageContent?.FORM_CONTENT.TOOLTIPS.MEDIC_AID}
                                                        ></Tooltip></h5>
                                                        {medicaidligibilitylist?.length === agentApplicantFormDetails?.length && <p>{pageContent?.NOT_ELIGIBLE.DESCRIPTION.ALL}</p>}
                                                        {medicaidligibilitylist?.length !== agentApplicantFormDetails?.length && (isPrimaryApplicantEligible ? <p>{pageContent?.NOT_ELIGIBLE.DESCRIPTION.PRIMARY}</p> : <p>{pageContent?.NOT_ELIGIBLE.DESCRIPTION.SOME}</p>)}
                                                    </div>
                                                </div>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
};

export default SubsidyBlockForm;