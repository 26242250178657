import { USER_DOMAINS } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { CHANNEL_TYPES, TWO_FA_USE_CASES, VALIDATE_ANSWER_ERROR_CODES } from './constants';

const getOrderedContacts = (contacts: Contacts, helpdeskValue: string, useCase: string, hideEmailWLP: boolean): Contacts => {
  const orderedContacts: Contacts = [];
  const { isWellpoint } = useUserStore.getState();
  if (contacts?.length > 0) {
    Object.values(CHANNEL_TYPES).map((channel) => {
      const matchingContact = contacts.find((contact) => contact.channel === channel);

      if (matchingContact && !(isWellpoint && matchingContact.channel == CHANNEL_TYPES.EMAIL && hideEmailWLP)) {
        orderedContacts.push(matchingContact);
      }

      return null;
    });
  }
  if (useCase !== TWO_FA_USE_CASES.CREATE_PROFILE) {
    orderedContacts.push({ channel: CHANNEL_TYPES.HELP_DESK, contactValue: helpdeskValue });
  }
  return orderedContacts;
};

const getChannelIcon = (channel: string) => {
  switch (channel) {
    case CHANNEL_TYPES.TEXT:
      return 'sae-icon-touchscreen-smartphone';
    case CHANNEL_TYPES.VOICE:
      return 'sae-icon-phone';
    case CHANNEL_TYPES.EMAIL:
      return 'sae-icon-message';
    case CHANNEL_TYPES.HELP_DESK:
      return 'sae-icon-headset';
    default:
      return '';
  }
};

const getChannelLabel = (channel: string, Strings) => {
  switch (channel) {
    case CHANNEL_TYPES.TEXT:
      return Strings.TWOFA_OPTIONS.CHANNEL_TEXT;
    case CHANNEL_TYPES.VOICE:
      return Strings.TWOFA_OPTIONS.CHANNEL_VOICE;
    case CHANNEL_TYPES.EMAIL:
      return Strings.TWOFA_OPTIONS.CHANNEL_EMAIL;
    case CHANNEL_TYPES.HELP_DESK:
      return Strings.TWOFA_OPTIONS.CHANNEL_HELP_DESK;
    default:
      return '';
  }
};

const getPayloadForSendOtp = (selectedChannel: Contact, userName: string, role: string, pingRiskId: string): SendOtpPayload => {
  const payload: SendOtpPayload = {
    channel: selectedChannel.channel,
    recoveryContact: selectedChannel.contactValue,
    brand: useGlobalStore.getState().brand,
    role,
    userName,
    pingRiskId
  };

  if (selectedChannel?.contactUid) {
    payload.contactUid = selectedChannel.contactUid;
  }

  return payload;
};

const getPayloadForForgotPwdSendOtp = (selectedChannel: Contact, resetId: string, brand: string, pingRiskId: string): ForgotPwdSendOtpPayload => {
  return {
    resetId,
    brand,
    pingRiskId,
    channel: selectedChannel.channel
  };
};

const getPayloadForForgotUsernameSendOtp = (
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  emailAddress: string,
  pingRiskId: string,
  selectedChannel: Contact
): ForgotUsernameSendOtpPayload => {
  return {
    firstName,
    lastName,
    dateOfBirth: formatDateToYYYYMMDD(dateOfBirth),
    brand: useGlobalStore.getState().brand,
    emailAddress,
    pingRiskId,
    channel: selectedChannel.channel
  };
};

const getForgotPwdValidateOTPPayload = (
  resetId: string,
  role: string,
  otp: string,
  pingRiskId: string,
  pingDeviceId: string,
  pingUserId: string,
  alreadyHaveAnOtp: boolean,
  selectedChannel: string
): ForgotPwdValidateOtpPayload => {
  const payload: ForgotPwdValidateOtpPayload = {
    resetId,
    role,
    otp,
    pingRiskId
  };
  if (alreadyHaveAnOtp || selectedChannel === CHANNEL_TYPES.HELP_DESK) {
    return payload;
  } else {
    payload.pingDeviceId = pingDeviceId;
    payload.pingUserId = pingUserId;
  }
  return payload;
};

const getForgotUsernameValidateOTPPayload = (
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  otp: string,
  emailAddress: string,
  pingRiskId: string,
  pingDeviceId: string,
  pingUserId: string,
  alreadyHaveAnOtp: boolean,
  selectedChannel: string
): ForgotUsernameValidateOtpPayload => {
  const payload: ForgotUsernameValidateOtpPayload = {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    firstName,
    lastName,
    dateOfBirth: formatDateToYYYYMMDD(dateOfBirth),
    emailAddress,
    otp,
    pingRiskId
  };

  if (alreadyHaveAnOtp || selectedChannel === CHANNEL_TYPES.HELP_DESK) {
    return payload;
  } else {
    payload.pingDeviceId = pingDeviceId;
    payload.pingUserId = pingUserId;
  }

  return payload;
};
const getValidateOTPPayload = (
  userName: string,
  password: string,
  role: string,
  otp: string,
  rememberDevice: string,
  pingRiskId: string,
  pingDeviceId: string,
  pingUserId: string,
  alreadyHaveAnOtp: boolean,
  selectedChannel: string
): ValidateOTPPayload => {
  const payload: ValidateOTPPayload = {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    userName,
    password,
    role,
    otp,
    rememberDevice,
    pingRiskId
  };

  if (useGlobalStore.getState().deepLinkInfo?.call || useGlobalStore.getState().deepLinkInfo?.refID) {
    payload.saleSource = {
      mutable: false,
      channel: 'APE-CD',
      cid: useGlobalStore.getState().deepLinkInfo?.refID,
      cidPhone: useGlobalStore.getState().deepLinkInfo?.call,
      quoteSrc: ''
    };
  }

  if (alreadyHaveAnOtp || selectedChannel === CHANNEL_TYPES.HELP_DESK) {
    return payload;
  } else {
    payload.pingDeviceId = pingDeviceId;
    payload.pingUserId = pingUserId;
  }

  return payload;
};

const getValidateOTPAndRegPayload = (
  userName: string,
  password: string,
  role: string,
  otp: string,
  rememberDevice: string,
  pingRiskId: string,
  pingDeviceId: string,
  pingUserId: string,
  secretQuestionAnswers: SecurityQAndA[],
  shopperProfile: {
    person: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      emailAddress: string;
      emailConsent: string;
      phone: {
        type: string;
        number: string;
      };
    };
  },
  saleSource?: SaleSource
): ValidateOTPAndRegPayload => {
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand,
      ...(useGlobalStore.getState().validState !== '' && { stateCd: useGlobalStore.getState().validState })
    },
    userName,
    password,
    role,
    otp,
    rememberDevice,
    pingRiskId,
    pingDeviceId,
    pingUserId,
    shopperAccount: {
      username: userName,
      password,
      role,
      secretQuestionAnswers,
      rememberDevice,
      saleSource
    },
    shopperProfile: shopperProfile
  };
};

const getChangePasswordPayload = (resetId: string, newPassword: string): ChangePasswordPayload => {
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    resetId,
    newPassword
  };
};

const getContactValue = (contactValue: string, channel: string, useCase: string) => {
  if (useCase === TWO_FA_USE_CASES.CREATE_PROFILE) {
    switch (channel) {
      case CHANNEL_TYPES.TEXT:
      case CHANNEL_TYPES.VOICE:
        const values = contactValue.split('');
        const maskValue = '***_***_';

        return maskValue.split('').concat(values.splice(6, 9)).join('');
      case CHANNEL_TYPES.EMAIL:
        return contactValue.replace(/^(.{1})[^@]+/, '$1******');
      default:
        return contactValue;
    }
  }

  return contactValue;
};

const getButtonText = (useCase: string, Strings) => {
  if (useCase === TWO_FA_USE_CASES.FORGOT_USERNAME) {
    return Strings.CONFIRM_YOUR_IDENTITY.EMAIL_USERNAME_BUTTON;
  } else if (useCase === TWO_FA_USE_CASES.FORGOT_PASSWORD) {
    return Strings.SIX_DIGIT_MODAL.CONTINUE_BUTTON;
  } else if (useCase === TWO_FA_USE_CASES.LOGIN) {
    return Strings.CONFIRM_YOUR_IDENTITY.SUBMIT_BUTTON;
  }
};

const getAnalyticsTag = (useCase: string) => {
  switch (useCase) {
    case TWO_FA_USE_CASES.LOGIN:
      return 'submitBtnSecurityQsIos';
    case TWO_FA_USE_CASES.FORGOT_PASSWORD:
      return 'contBtnSecurityQsIos';
    case TWO_FA_USE_CASES.FORGOT_USERNAME:
      return 'emailUsernameBtnSecurityQsIos';
  }
};

const getrandomSecretQuestion = (secretQuestionAnswers: SecretQAndAs): SecretQAndA => {
  if (secretQuestionAnswers.length === 0) {
    return { index: 0, securityQuestion: '', securityAnswer: '' };
  } else {
    const randomIndex = Math.floor(Math.random() * secretQuestionAnswers.length);
    return secretQuestionAnswers[randomIndex];
  }
};

const getLoginwithQAndAPayload = (
  userName: string,
  password: string,
  role: string,
  randomQuestion: SecretQAndA,
  answer: string,
  rememberMyDevice: string,
  pingRiskId: string
): LoginwithQAndAPayload => {
  return {
    password,
    role,
    userName,
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    secretQuestionAnswers: [
      {
        index: randomQuestion.index,
        securityQuestion: randomQuestion.securityQuestion,
        securityAnswer: answer
      }
    ],
    rememberDevice: rememberMyDevice,
    pingRiskId,
    metaIpAddress: '',
    saleSource: {
      mutable: false,
      channel: 'APE-CD',
      cid: useGlobalStore.getState().deepLinkInfo?.refID ? useGlobalStore.getState().deepLinkInfo?.refID : undefined,
      cidPhone: useGlobalStore.getState().deepLinkInfo?.call ? useGlobalStore.getState().deepLinkInfo?.call : undefined,
      quoteSrc: ''
    }
  };
};

const getEmailUsernamewithQAndAPayload = (
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  emailAddress: string,
  role: string,
  randomQuestion: SecretQAndA,
  answer: string,
  pingRiskId: string
): EmailUsernamewithQAndAPayload => {
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    firstName,
    lastName,
    dateOfBirth: formatDateToYYYYMMDD(dateOfBirth),
    brand: useGlobalStore.getState().brand,
    emailAddress,
    role,
    pingRiskId,
    secretQuestionAnswers: [
      {
        index: randomQuestion.index,
        securityQuestion: randomQuestion.securityQuestion,
        securityAnswer: answer
      }
    ]
  };
};

const getForgotPasswordwithQAndAPayload = (pingRiskId: string, resetId: string, randomQuestion: SecretQAndA, answer: string) => {
  return {
    pingRiskId,
    resetId,
    secretQuestionAnswers: [
      {
        index: randomQuestion.index,
        securityQuestion: randomQuestion.securityQuestion,
        securityAnswer: answer
      }
    ],
    metaIpAddress: ''
  };
};

const getLoginQAndAErrorObj = (errorKey: string, strings) => {
  const errorObj = {
    hasError: true,
    responseCode: errorKey,
    message: ''
  };

  switch (errorKey) {
    case VALIDATE_ANSWER_ERROR_CODES.LOGIN_INVALID_ANSWER:
      errorObj.message = strings?.CONFIRM_YOUR_IDENTITY.ANSWER_NOT_MATCH ?? '';
      break;
    case VALIDATE_ANSWER_ERROR_CODES.USER_ACCOUNT_DISABLED:
      errorObj.message = strings?.CONFIRM_YOUR_IDENTITY.ACCOUNT_DISABLED ?? '';
      break;
    default:
      errorObj.message = `<b>${strings.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${strings.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}` ?? '';
      break;
  }

  return errorObj;
};

const getEmailUsernameWithQAndAErrorObj = (errorKey: string, strings) => {
  const errorObj = {
    hasError: true,
    responseCode: errorKey,
    message: ''
  };

  switch (errorKey) {
    case VALIDATE_ANSWER_ERROR_CODES.EMAIL_USERNAME_INVALID_ANSWER:
      errorObj.message = strings?.CONFIRM_YOUR_IDENTITY.ANSWER_NOT_MATCH ?? '';
      break;
    case VALIDATE_ANSWER_ERROR_CODES.EMAIL_USERNAME_ACCOUNT_DISABLED:
      errorObj.message = strings?.CONFIRM_YOUR_IDENTITY.ACCOUNT_DISABLED ?? '';
      break;
    default:
      errorObj.message = `<b>${strings.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${strings.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}` ?? '';
      break;
  }

  return errorObj;
};

const getForgotPwdWithQAndAErrorObj = (errorKey: string, strings) => {
  const errorObj = {
    hasError: true,
    responseCode: errorKey,
    message: ''
  };

  switch (errorKey) {
    case VALIDATE_ANSWER_ERROR_CODES.FORGOT_PASSWORD_INVALID_ANSWER:
      errorObj.message = strings?.CONFIRM_YOUR_IDENTITY.ANSWER_NOT_MATCH ?? '';
      break;
    case VALIDATE_ANSWER_ERROR_CODES.USER_ACCOUNT_DISABLED:
      errorObj.message = strings?.CONFIRM_YOUR_IDENTITY.ACCOUNT_DISABLED ?? '';
      break;
    default:
      errorObj.message = `<b>${strings.TWOFA_OPTIONS.SYSTEM_EXCEPTION_HEADING}</b> <br> ${strings.TWOFA_OPTIONS.SYSTEM_EXCEPTION_MESSAGE}` ?? '';
      break;
  }

  return errorObj;
};

const getUpdateLoginStatusPayload = (otpResponse: ValidateOTPResponse, userName: string, password: string, contacts: Array<Contact>) => {
  return {
    userName: userName,
    password: password,
    userFirstName: otpResponse.loginResponse.firstName,
    userLastName: otpResponse.loginResponse.lastName,
    userEmailAddress: otpResponse.loginResponse.emailAddress,
    userPhoneNumber: otpResponse.shopperProfile.person.phone?.number,
    userPhoneNumberType: otpResponse.shopperProfile.person.phone?.type,
    userSMSConsent: otpResponse.shopperProfile.person.emailConsent,
    role: otpResponse.loginResponse.role,
    contacts: contacts,
    secretQuestionAnswers: otpResponse.loginResponse.secretQuestionAnswers,
    pingRiskId: otpResponse.pingCookie,
    userDateOfBirth: otpResponse.shopperProfile.person.dateOfBirth
  };
};

const getWebAccount = (otpResponse: ValidateOTPResponse) => {
  return {
    webAccountGUID: otpResponse.loginResponse.webAccountGUID,
    shopperId: otpResponse.loginResponse.shopperId,
    webAccessId: otpResponse.loginResponse.webAccessId
  };
};

const getCloseBtnAnalyticsSuffix = (useCase: string) => {
  switch (useCase) {
    case TWO_FA_USE_CASES.CREATE_PROFILE:
      return 'RegIos';
    case TWO_FA_USE_CASES.FORGOT_PASSWORD:
      return 'ForgotPassIos';
    case TWO_FA_USE_CASES.FORGOT_USERNAME:
      return 'ForgotUserIos';
  }
};

const getWebAccountForRegistration = (response: ValidateOTPAndRegisterResponse) => {
  return {
    webAccountGUID: response.registrationResponse.webAccountGUID,
    shopperId: response.registrationResponse.shopperId,
    webAccessId: response.registrationResponse.webAccessId
  };
};

export {
  getAnalyticsTag,
  getButtonText,
  getChangePasswordPayload,
  getChannelIcon,
  getChannelLabel,
  getCloseBtnAnalyticsSuffix,
  getContactValue,
  getEmailUsernameWithQAndAErrorObj,
  getEmailUsernamewithQAndAPayload,
  getForgotPasswordwithQAndAPayload,
  getForgotPwdValidateOTPPayload,
  getForgotPwdWithQAndAErrorObj,
  getForgotUsernameValidateOTPPayload,
  getLoginQAndAErrorObj,
  getLoginwithQAndAPayload,
  getOrderedContacts,
  getPayloadForForgotPwdSendOtp,
  getPayloadForForgotUsernameSendOtp,
  getPayloadForSendOtp,
  getrandomSecretQuestion,
  getUpdateLoginStatusPayload,
  getValidateOTPAndRegPayload,
  getValidateOTPPayload,
  getWebAccount,
  getWebAccountForRegistration
};

