import dayjs from 'dayjs';
import { StateCreator } from 'zustand';
import { COVERAGE_EFFECTIVE_CONSTS, STATE_CODES } from '../shared/globalConstants';
import { getPlanTypes, setCoverageDate } from '../shared/utils/globalUtils';

const zipcodeInitailState = {
  zipCode: '',
  stateCode: STATE_CODES.UNKNOWN,
  longitude: 0,
  latitude: 0,
  countyList: {
    county: []
  },
  timeZone: '',
  gMTOffset: 0,
  dayLightSavingFlag: '',
  currentDateTimeInZone: '',
  coverageEffectiveDate: {
    coverageDt: []
  }
};

const initialState = {
  agentZipCodeRes: zipcodeInitailState,
  agentZipcode: '',
  agentState: '',
  agentBrand: '',
  agentCountyList: [],
  agentCounty: '',
  agentCountyCode: '',
  agentYear: '',
  agentTin: '',
  agentMarketingRefId: '',
  agentPlanTypes: [],
  agentCoverageDate: '',
  agentCoverageDates: [],
  agentCoverageType: '',
  agentApplicantFormDetails: [],
  agentFormSubmitedWithSubsidy: false
};

export const createAgentQuoteStoreSlice: StateCreator<AgentBasicsLandingStore> = (set, get) => ({
  ...initialState,
  agentupdateZipCodeResponse: (response: ZipCodeClass, countyList: County[], year: string) =>
    countyList.length === 1
      ? set({
          agentZipCodeRes: response,
          agentZipcode: response.zipCode,
          agentCounty: response.countyList.county[0].countyName,
          agentState: response.stateCode,
          agentCountyCode: response.countyList.county[0].countyCode,
          agentBrand: response.countyList.county[0].brand,
          agentYear: '',
          agentCountyList: response.countyList.county,
          agentCoverageDates: response.coverageEffectiveDate.coverageDt.filter((type) => {
            return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(year) !== -1;
          })
        })
      : set({
          agentZipCodeRes: response,
          agentZipcode: response.zipCode,
          agentCounty: '',
          agentState: response.stateCode,
          agentCountyCode: '',
          agentBrand: '',
          agentYear: '',
          agentCountyList: response.countyList.county,
          agentCoverageDates: response.coverageEffectiveDate.coverageDt.filter((type) => {
            return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(year) !== -1;
          })
        }),
  agentupdateZipCodeResponseOnEdit: (response: ZipCodeClass, countyList: County[], year: string) =>
    set({
      agentZipCodeRes: response,
      agentZipcode: response.zipCode,
      agentState: response.stateCode,
      agentCountyList: response.countyList.county,
      agentCoverageDates: response.coverageEffectiveDate.coverageDt.filter((type) => {
        return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(year) !== -1;
      })
    }),
  agentUpdateCounty: (county: string, countyCode: string, brand: string) =>
    set({
      agentCounty: county,
      agentCountyCode: countyCode,
      agentBrand: brand
    }),
  agentupdatePlanTypes: (county, year, role) =>
    set({
      agentPlanTypes: getPlanTypes(get().agentZipCodeRes, county, year, role)
    }),
  updateAgentPlanTypesOnReview: (planTypes: string[]) =>
    set({
      agentPlanTypes: planTypes
    }),
  resetAgentPlanTypes: () =>
    set({
      agentPlanTypes: []
    }),
  agentUpdateCoverageType: (coverageType: string) =>
    set({
      agentCoverageType: coverageType,
      agentCoverageDate: dayjs(setCoverageDate(get().agentCoverageDates, coverageType)).format('YYYY/MM/DD')
    }),
  agentUpdateApplicantFormDetails: (data) =>
    set({
      agentApplicantFormDetails: data
    }),
  resetAgentUpdateZipCode: () =>
    set({
      agentZipCodeRes: zipcodeInitailState,
      agentZipcode: '',
      agentState: '',
      agentBrand: '',
      agentCountyList: [],
      agentCounty: '',
      agentCountyCode: '',
      agentYear: ''
    }),
  agentUpdateTinandMarketingRefId: (tin: string, marketingRefId: string) =>
    set({
      agentTin: tin,
      agentMarketingRefId: marketingRefId
    }),
  agentUpdateTin: (tin: string) =>
    set({
      agentTin: tin
    }),
  agentUpdateYear: (year: string) =>
    set({
      agentYear: year
    }),
  agentUpdateCoverageDates(coverageDates: CoverageDt[], year) {
    set({
      agentCoverageDates: coverageDates.filter((type) => {
        return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(year) !== -1;
      }),
      agentCoverageDate: dayjs(
        setCoverageDate(
          coverageDates.filter((type) => {
            return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(year) !== -1;
          }),
          get().agentCoverageType
        )
      ).format('YYYY/MM/DD')
    });
  },
  agentUpdateFormSubmitedWithSubsidy: (status: boolean) =>
    set({
      agentFormSubmitedWithSubsidy: status
    }),
  updateAgentBasicsDemographic: (
    brand: string,
    county: string,
    countyCode: string,
    coverageEffectiveDate: string,
    coverageType: string,
    state: string,
    zipCode: string,
    year: string
  ) =>
    set({
      agentBrand: brand,
      agentCounty: county,
      agentCountyCode: countyCode,
      agentCoverageDate: coverageEffectiveDate,
      agentCoverageType: coverageType,
      agentState: state,
      agentZipcode: zipCode,
      agentYear: year
    }),

  resetAgentBasicLandingStates: () => set(() => ({ ...initialState }))
});
