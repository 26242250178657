import { EMPTY_PLANS_USE_CASES } from '../../../components/common/empty-plans/constants';
import { getApplicableGrade, isMappedContract } from '../../../components/common/plan-card/planCardUtils';
import { APPLICANT_TYPES, PLAN_TYPES_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getAge, getNotMedicaidEligibleDependents } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import {
  APPLICANT_TYPES_EXCLUDED_FOR_PLAN_SUMMARY_API,
  COST_COVERAGE_CODES,
  COVERED_FLAG,
  GRADE_LEVELS,
  HSA_COMPATIBLE_VALUE,
  MEDICAL_DEDUCTIBLE_SORT_OPTION,
  MEDICAL_PLANS_TABS,
  MONTHLY_COST_SORT_OPTION,
  PLAN_BENEFIT_CODES,
  PLAN_TIERS_ALL,
  PLAN_TIERS_NON_CATASTROPHIC,
  QHP_TO_CSR_MAP,
  SWITCH_FILTERS_KEYS
} from './constants';

const getFormattedApplicantDetails = (applicants: Applicant[], retainApplicantType: boolean = true): Applicant[] => {
  const formattedApplicants = applicants.map((applicant, index) => {
    const updatedApplicant = {
      ...applicant,
      dateOfBirth: formatDateToYYYYMMDD(applicant.dateOfBirth),
      isTabaccoUser: applicant?.isTabaccoUser ? applicant?.isTabaccoUser?.toUpperCase() : 'NO',
      applicantId: applicant.applicantId ?? index.toString(),
      applicantType:
        !retainApplicantType && APPLICANT_TYPES_EXCLUDED_FOR_PLAN_SUMMARY_API.includes(applicant.applicantType)
          ? APPLICANT_TYPES.DEPENDENT
          : applicant.applicantType
    };

    if (updatedApplicant.gender === '') {
      delete applicant.gender;
    }

    return updatedApplicant;
  });

  return formattedApplicants;
};

const getFormattedApplicantDetailsForAtksaveQuote = (applicants: Applicant[], retainApplicantType: boolean = true): Applicant[] => {
  const formattedApplicants = applicants.map((applicant, index) => {
    const updatedApplicant = {
      ...applicant,
      dateOfBirth: formatDateToYYYYMMDD(applicant.dateOfBirth),
      isTabaccoUser: applicant?.isTabaccoUser ? applicant?.isTabaccoUser?.toUpperCase() : 'NO',
      applicantId: index.toString(),
      applicantType:
        !retainApplicantType && APPLICANT_TYPES_EXCLUDED_FOR_PLAN_SUMMARY_API.includes(applicant.applicantType)
          ? APPLICANT_TYPES.DEPENDENT
          : applicant.applicantType
    };

    if (updatedApplicant.gender === '') {
      delete applicant.gender;
    }

    return updatedApplicant;
  });

  return formattedApplicants;
};

const setDomesticPartnerAsSpouse = (applicants: Applicant[]): Applicant[] => {
  const updatedApplicants = applicants.map((applicant) => {
    if (applicant.applicantType === APPLICANT_TYPES.DOMESTICPARTNER) {
      applicant.applicantType = APPLICANT_TYPES.SPOUSE;
    }
    return applicant;
  });
  return updatedApplicants;
};

const getPrimaryApplicant = (applicants: Applicant[]) => {
  return applicants.filter((applicant) => applicant.applicantType === APPLICANT_TYPES.PRIMARY);
};

const getSubsidyAmount = (subsidyResponse: EstimateSavingsResponse): string | null => {
  const subsidyAmount = subsidyResponse?.subsidyCalc?.[0]?.subsidyAmount;
  return useGlobalStore.getState().isSubsidydataApplied ? (subsidyAmount ? subsidyAmount.toString() : null) : null;
};

const getCSR = (subsidyResponse: EstimateSavingsResponse): number | null => {
  const qhpCode = subsidyResponse?.subsidyCalc?.[0]?.QHPcode;
  return useGlobalStore.getState().isSubsidydataApplied ? (qhpCode ? (QHP_TO_CSR_MAP[qhpCode] ? QHP_TO_CSR_MAP[qhpCode] : null) : null) : null;
};

const getApplicableMetalTiers = (applicants: Applicant[]): string[] => {
  for (let applicant of applicants) {
    if (getAge(applicant.dateOfBirth) > 30) {
      return PLAN_TIERS_NON_CATASTROPHIC;
    }
  }

  return PLAN_TIERS_ALL;
};

const getMedicalPlansPayload = (globalStore: QuoteStore & EstimateSavingsStore, hmcFilter: HmcFilter, selectedTab?: string): PlanSummaryPayload => {
  const applicantsData = getNotMedicaidEligibleDependents(globalStore.applicantFormDetails);

  let payload: PlanSummaryPayload = {
    brand: globalStore.brand,
    planTypes: [PLAN_TYPES_NAMES.MEDICAL],
    metalTierTypes: getApplicableMetalTiers(getFormattedApplicantDetails(applicantsData)),
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStore.zipcode,
    county: globalStore.county,
    state: globalStore.state,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStore.coverageDate), //Update the format
    planYear: globalStore.year,
    applicants: getFormattedApplicantDetails(applicantsData, false),
    subsidy: {
      //Yet to be made dynamic
      subsidyAmt: getSubsidyAmount(globalStore.subsidyresponse),
      costShareReduction: getCSR(globalStore.subsidyresponse)
    },
    language: 'en', //For now hardcoding to english, Yet to be made dynamic
    countyCode: globalStore.countyCode
  };

  if (hmcFilter.questionaires.length > 0) {
    payload.hmcFilter = hmcFilter;
  }

  return payload;
};

const getPlansForMonthlyCost = (value: string, plans: Contracts): Contracts => {
  const [minValue, maxValue] = value.split('-');
  const actualMinValue = parseFloat(minValue);
  const actalMaxValue = isNaN(parseFloat(maxValue)) ? Infinity : parseFloat(maxValue);

  const filteredPlans = plans.filter(
    (plan: Contract) =>
      plan?.rateData?.rate?.totSubsidizedPrem &&
      plan?.rateData?.rate?.totSubsidizedPrem >= actualMinValue &&
      plan?.rateData?.rate?.totSubsidizedPrem <= actalMaxValue
  );

  return filteredPlans;
};

const getPlansMonthlyDeductible = (value: string, plans: Contracts): Contracts => {
  const [minValue, maxValue] = value.split('-');
  const actualMinValue = parseFloat(minValue);
  const actalMaxValue = isNaN(parseFloat(maxValue)) ? Infinity : parseFloat(maxValue);

  const filteredPlans = plans.filter((planObj: Contract) => {
    const costObj = planObj.plan[0].costCoverages.find((coverage) => coverage.coverageCode === COST_COVERAGE_CODES.DEDUCTIBLE);

    if (costObj && parseFloat(costObj.coverageValue) >= actualMinValue && parseFloat(costObj.coverageValue) <= actalMaxValue) {
      return planObj;
    }
  });

  return filteredPlans;
};

const isFilterApplied = (filters: CheckBoxFilterOption[]) => {
  return filters.filter((filterObj) => filterObj.isChecked === true).length > 0;
};

const getMonthlyCostFilteredPlans = (filters: CheckBoxFilterOption[], plans: Contracts): Contracts => {
  const isMonthlyCostFilterApplied = isFilterApplied(filters);
  let filteredPlans: Contracts = [];

  if (isMonthlyCostFilterApplied) {
    filters.forEach((filterOption) => {
      const { isChecked, value } = filterOption;

      if (isChecked) {
        const plansForCost = getPlansForMonthlyCost(value, plans);

        filteredPlans = filteredPlans.concat(plansForCost);
      }
    });
  } else {
    filteredPlans = plans;
  }

  return filteredPlans;
};

const getMedicalDeductibleFilteredPlans = (filters: CheckBoxFilterOption[], plans: Contracts): Contracts => {
  const isDeductibleFilterApplied = isFilterApplied(filters);
  let filteredPlans: Contracts = [];

  if (isDeductibleFilterApplied) {
    filters.forEach((filterOption) => {
      const { isChecked, value } = filterOption;

      if (isChecked) {
        const plansForCost = getPlansMonthlyDeductible(value, plans);

        filteredPlans = filteredPlans.concat(plansForCost);
      }
    });
  } else {
    filteredPlans = plans;
  }

  return filteredPlans;
};

const getSwitchFilteredPlans = (
  switchFilters: SwitchFilter[],
  plans: Contracts,
  savedProviders: Provider[],
  matchProvidersToPlans: boolean,
  matchMedicationsToPlans: boolean,
  drugList: DrugDetails[],
  coveredMedicationsList: MedicationsPlanSummary[]
): Contracts => {
  let filteredPlans = plans;
  const isSwitchFilterApplied = switchFilters.filter((filter) => filter.enabled).length > 0;

  if (isSwitchFilterApplied) {
    switchFilters.forEach((filter) => {
      if (filter.enabled) {
        filteredPlans = getPlansForSwitchFilter(
          filter.key,
          filteredPlans,
          savedProviders,
          matchProvidersToPlans,
          matchMedicationsToPlans,
          drugList,
          coveredMedicationsList
        );
      }
    });
  }

  return filteredPlans;
};

const getBenefitTierFilteredPlans = (filters: CheckBoxFilterOption[], plans: Contracts): Contracts => {
  const isBenefitsTierFilterApplied = isFilterApplied(filters);
  let filteredPlans: Contracts = [];

  if (isBenefitsTierFilterApplied) {
    filters.forEach((filterOption) => {
      const { isChecked, value } = filterOption;

      if (isChecked) {
        const plansForCost = getPlansForbenefitTier(plans, value);

        filteredPlans = filteredPlans.concat(plansForCost);
      }
    });
  } else {
    filteredPlans = plans;
  }

  return filteredPlans;
};

const getColaradoConnectPlans = (plans: Contracts): Contracts => {
  const colaradoPlans = plans.filter((plan) => plan.poPlan === true);

  return colaradoPlans;
};

const getOffMarketPlans = (plans: Contracts): Contracts => {
  const offMarketPlans = plans.filter((plan) => !plan.onExchange && !plan.poPlan);

  return offMarketPlans;
};

const getMarketPlacePlans = (plans: Contracts): Contracts => {
  const marketPlacePlans = plans.filter((plan) => plan.onExchange && !plan.poPlan);

  return marketPlacePlans;
};

const getPlansForTab = (tab: string, plans: Contracts): Contracts => {
  switch (tab) {
    case MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS:
      return getColaradoConnectPlans(plans);
    case MEDICAL_PLANS_TABS.OFF_MARKET_PLANS:
      return getOffMarketPlans(plans);
    case MEDICAL_PLANS_TABS.MARKET_PLANS:
      return getMarketPlacePlans(plans);
    default:
      return plans;
  }
};

const getFilteredPlans = (
  filters: MedicalPlansFilters,
  plans: Contracts,
  savedProviders: Provider[],
  matchProvidersToPlans: boolean,
  matchMedicationsToPlans: boolean,
  drugList: DrugDetails[],
  coveredMedicationsList: MedicationsPlanSummary[]
) => {
  const plansForTab = getPlansForTab(filters.selectedTab, plans);
  const monthlyCostFilteredPlans = getMonthlyCostFilteredPlans(filters.monthlyCostFilters, plansForTab);
  const medicalDeductibleFilteredPlans = getMedicalDeductibleFilteredPlans(filters.medicalDeductibleFilters, monthlyCostFilteredPlans);
  const switchFilteredPlans = getSwitchFilteredPlans(
    filters.switchFilters,
    medicalDeductibleFilteredPlans,
    savedProviders,
    matchProvidersToPlans,
    matchMedicationsToPlans,
    drugList,
    coveredMedicationsList
  );
  const filteredPlans = getBenefitTierFilteredPlans(filters.benefitsTiers, switchFilteredPlans);

  return filteredPlans;
};

const getMontlyCostFilterOptions = (monthlyCostFilters: CheckBoxFilterOption[], plans: Contracts, labels: any): CheckBoxFilterOption[] => {
  const initialFilterOptions = monthlyCostFilters.map((option) => {
    const updatedOption = { ...option };
    updatedOption.label = labels[option.key];
    updatedOption.disabled = getPlansForMonthlyCost(updatedOption.value, plans).length === 0;
    updatedOption.isChecked = false;

    return updatedOption;
  });

  return initialFilterOptions;
};

const getHSACompatiblePlans = (plans: Contracts): Contracts => {
  return plans.filter((plan: Contract) => {
    const benefitObj = plan.plan[0].planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.HSA_COMPATIBLE);

    return benefitObj && benefitObj.planBenefitValue === HSA_COMPATIBLE_VALUE;
  });
};

const getPlansWithMatchedProviders = (plans: Contracts, savedProviders: Provider[], matchProvidersToPlans: boolean): Contracts => {
  const plansWithMatchedProviders = plans.filter((plan) => {
    const matchedProviders = getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, plan.contractCode);
    return matchedProviders.length > 0;
  });

  return plansWithMatchedProviders;
};

const getPlansWithMatchedMedications = (
  plans: Contracts,
  drugList: DrugDetails[],
  coveredMedicationsList: MedicationsPlanSummary[],
  matchMedicationsToPlans: boolean
): Contracts => {
  const plansWithMatchedMedications = plans.filter((plan) => {
    const matchedMedications = getMatchedMedicationsForPlan(matchMedicationsToPlans, drugList, plan.contractCode, coveredMedicationsList);
    return matchedMedications.length > 0;
  });

  return plansWithMatchedMedications;
};

const getPlansForSwitchFilter = (
  key: string,
  plans: Contracts,
  savedProviders: Provider[],
  matchProvidersToPlans: boolean,
  matchMedicationsToPlans: boolean,
  drugList: DrugDetails[],
  coveredMedicationsList: MedicationsPlanSummary[]
): Contracts => {
  switch (key) {
    case SWITCH_FILTERS_KEYS.RECOMMENDED:
      return plans.filter((plan) => getApplicableGrade(plan.gradeLevels) === GRADE_LEVELS.RECOMMENDED);
    case SWITCH_FILTERS_KEYS.MOST_POPULAR:
      return plans.filter((plan) => getApplicableGrade(plan.gradeLevels) === GRADE_LEVELS.POPULAR);
    case SWITCH_FILTERS_KEYS.HSA_COMPATIBLE:
      return getHSACompatiblePlans(plans);
    case SWITCH_FILTERS_KEYS.SHOW_MY_DOCTOR:
      return getPlansWithMatchedProviders(plans, savedProviders, matchProvidersToPlans);
    case SWITCH_FILTERS_KEYS.SHOW_MY_MEDICATION:
      return getPlansWithMatchedMedications(plans, drugList, coveredMedicationsList, matchMedicationsToPlans);
    default:
      return plans;
  }
};

const getInitialSwitchFilters = (
  switchFilters: SwitchFilter[],
  plans: Contracts,
  labels: any,
  savedProviders: Provider[],
  matchProvidersToPlans: boolean,
  matchMedicationsToPlans: boolean,
  drugList: DrugDetails[],
  coveredMedicationsList: MedicationsPlanSummary[],
  isTabChange: boolean = true
): SwitchFilter[] => {
  const initialSwitchFilters = switchFilters.map((option) => {
    const updatedOption = { ...option };
    updatedOption.label = labels[option.key];
    updatedOption.disabled =
      getPlansForSwitchFilter(option.key, plans, savedProviders, matchProvidersToPlans, matchMedicationsToPlans, drugList, coveredMedicationsList).length === 0;

    if (isTabChange) {
      updatedOption.enabled = false;
    }

    return updatedOption;
  });

  return initialSwitchFilters;
};

const getPlansForbenefitTier = (plans: Contracts, tier: string): Contracts => {
  return plans.filter((plan: Contract) => plan.tiersType === tier);
};

const getInitialBenefitTierFilters = (benefitsTiers: CheckBoxFilterOption[], plans: Contracts, labels: any): CheckBoxFilterOption[] => {
  const initialFilterOptions = benefitsTiers.map((option) => {
    const updatedOption = { ...option };
    updatedOption.label = labels[option.key];
    updatedOption.disabled = getPlansForbenefitTier(plans, option.value).length === 0;
    updatedOption.isChecked = false;

    return updatedOption;
  });

  return initialFilterOptions;
};

const getMedicalDeductibleFilters = (medicalDeductibleFilters: CheckBoxFilterOption[], plans: Contracts, labels: any): CheckBoxFilterOption[] => {
  const initialFilterOptions = medicalDeductibleFilters.map((option) => {
    const updatedOption = { ...option };
    updatedOption.label = labels[option.key];
    updatedOption.disabled = getPlansMonthlyDeductible(updatedOption.value, plans).length === 0;
    updatedOption.isChecked = false;

    return updatedOption;
  });

  return initialFilterOptions;
};

const getSortOptions = (sortOptions: SortOption[], labels: any) => {
  const initialSortOptions = sortOptions.map((option) => {
    const updatedOption = { ...option };
    updatedOption.label = labels[option.key];

    return updatedOption;
  });

  return initialSortOptions;
};

const getActiveSortValue = (sortOptions: SortOption[]) => {
  return sortOptions.find((option) => option.isSelected) ?? MONTHLY_COST_SORT_OPTION;
};

const getUpdatedSortOptions = (value: string, sortOptions: SortOption[]) => {
  const updatedSortOptions = sortOptions.map((option) => {
    option.isSelected = option.value === value;
    return option;
  });
  return updatedSortOptions;
};

const sortPlansByMonthlyCost = (plans: Contract[]) => {
  const validMonthlyCostPlans: Contract[] = [];
  const invalidMonthlyCostPlans: Contract[] = [];

  plans.filter((plan) => {
    if (plan.rateData?.rate?.totSubsidizedPrem) {
      validMonthlyCostPlans.push(plan);
    } else {
      invalidMonthlyCostPlans.push(plan);
    }
  });

  const sortedPlans = validMonthlyCostPlans.sort(function (x, y) {
    return (x?.rateData?.rate?.totSubsidizedPrem ?? 0) - (y?.rateData?.rate?.totSubsidizedPrem ?? 0);
  });

  return [...sortedPlans, ...invalidMonthlyCostPlans];
};

const sortPlansByMedicalDeductible = (plans: Contract[]) => {
  const sortedPlans = plans.sort(function (x, y) {
    const coverageValueX: string = x.plan[0].costCoverages.find((coverage) => coverage.coverageCode === COST_COVERAGE_CODES.DEDUCTIBLE)?.coverageValue ?? '0';
    const coverageValueY: string = y.plan[0].costCoverages.find((coverage) => coverage.coverageCode === COST_COVERAGE_CODES.DEDUCTIBLE)?.coverageValue ?? '0';

    return parseFloat(coverageValueX) - parseFloat(coverageValueY);
  });

  return sortedPlans;
};

const sortPlans = (plans: Contract[], sortOption: SortOption) => {
  switch (sortOption.key) {
    case MONTHLY_COST_SORT_OPTION.key:
      return sortPlansByMonthlyCost(plans);
    case MEDICAL_DEDUCTIBLE_SORT_OPTION.key:
      return sortPlansByMedicalDeductible(plans);
    default:
      return sortPlansByMonthlyCost(plans);
  }
};

const getSortedPlans = (plans: Contract[], sortOption: SortOption): Contract[] => {
  let sortedPlans: Contract[] = [];
  const recommendedPlans: Contract[] = [];
  const otherPlans: Contract[] = [];
  const transitionPlan: Contract[] = [];

  plans.forEach((plan) => {
    if (isMappedContract(plan)) {
      return transitionPlan.push(plan);
    } else {
      const gradeLevel = getApplicableGrade(plan.gradeLevels);
      if (gradeLevel === GRADE_LEVELS.RECOMMENDED) {
        return recommendedPlans.push(plan);
      } else {
        return otherPlans.push(plan);
      }
    }
  });

  const sortedRecommendedPlans = sortPlans(recommendedPlans, sortOption);
  const sortedOtherPlans = sortPlans(otherPlans, sortOption);

  sortedPlans = [...transitionPlan, ...sortedRecommendedPlans, ...sortedOtherPlans];

  return sortedPlans;
};

const getTabLabel = (label: string, tab: string, plans: Contracts, showFavourites: boolean, favouritePlans: string[]): string => {
  const applicablePlans = !showFavourites ? plans : getFavouritePlans(plans, favouritePlans);
  const numberOfPlans = getPlansForTab(tab, applicablePlans).length;

  if (numberOfPlans > 0) {
    return label + ' (' + numberOfPlans + ')';
  }

  return label;
};

const getContractCodes = (plans: Contracts): string[] => {
  return plans.map((plan) => plan.contractCode);
};

const getPlanCardLabels = (labels: any): PlanCardLabels => {
  return {
    mostPopular: labels?.MOST_POPULAR,
    recommended: labels?.RECOMMENDED,
    currentCoverage: labels?.CURRENT_COVERAGE,
    monthlyPremium: labels?.MONTHLY_PREMIUM,
    drugList: labels?.DRUG_LIST,
    benefitLabelOne: labels?.DEDUCTIBLE,
    benefitLabelTwo: labels?.PRIMARY_CARE_VISIT,
    benefitLabelThree: labels?.PREVENTIVE_CARE,
    referToSbc: labels?.REFER_TO_SBC,
    youPay: labels?.YOU_PAY,
    select: labels?.SELECT,
    deselect: labels?.DESELECT,
    showMore: labels?.SHOW_MORE,
    showLess: labels?.SHOW_LESS,
    addToFavourites: labels?.ADD_TO_FAVOURITES,
    removeFromFavourites: labels?.REMOVE_FROM_FAVOURITES,
    compareEmailPrint: labels?.COMPARE_EMAIL_PRINT,
    monthlyCostBreakdown: labels?.MONTHLY_COST_BREAKDOWN,
    totalPlanCost: labels?.TOTAL_PLAN_COST,
    subsidyApplied: labels?.SUBSIDY_APPLIED,
    totalMemberResponsibility: labels?.TOTAL_MEMBER_RESPONSIBILITY,
    rateNotAvailable: labels?.RATE_NOT_AVAILABLE,
    doctorsListLabel: labels?.DOCTORS_LIST_LABEL,
    medicationsListLabel: labels?.MEDICATIONS_LIST_LABEL,
    doctors: labels?.DOCTORS,
    medications: labels?.MEDICATIONS,
    matchedDoctorsTooltipText: labels?.MATCHED_DOCTORS_TOOLTIP_TEXT,
    importantDrugsNotice: labels?.IMPORTANT_DRUGS_NOTICE,
    transitionCoverage: labels?.TRANSITION_COVERAGE,
    transitionCoverageTooltip: labels?.TRANSITION_PLAN_TOOLTIP_CONTENT
  };
};

const getMatchedDoctorsForPlan = (matchProviders: boolean, savedProviders: Provider[], contractCode: string): Provider[] => {
  const matchedProviders: Provider[] = [];

  if (matchProviders) {
    savedProviders.forEach((provider) => {
      if (provider.contractCodes.includes(contractCode)) {
        matchedProviders.push(provider);
      }
    });
  }

  return matchedProviders;
};

const getMatchedMedicationsForPlan = (
  matchMedications: boolean,
  updatedDrugsList: DrugDetails[],
  contractCode: string,
  medicationsPlanSummary: MedicationsPlanSummary[]
): DrugDetails[] => {
  const matchedMedications: DrugDetails[] = [];

  if (matchMedications) {
    updatedDrugsList.forEach((drug) => {
      medicationsPlanSummary.map((item, idx) => {
        if (item.plan.planId.includes(contractCode)) {
          item.planDrugInfoList.planDrugInfo.map((drugs) => {
            if (drugs.coverageIndicator === COVERED_FLAG && drugs.drug.drugLabel === drug.drugLabel) matchedMedications.push(drugs.drug);
          });
        }
      });
    });
  }

  return matchedMedications;
};

const getEmptyPlansUseCase = (serverError: boolean, medicalPlans: Contract[]): string => {
  if (serverError) {
    return EMPTY_PLANS_USE_CASES.SERVER_ERROR;
  } else if (medicalPlans.length === 0) {
    return EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE;
  }

  return EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE_WITH_FILTERS;
};

const getUpdatedFavouritePlans = (favouritePlanContractCode: string, favouritePlans: string[]): string[] => {
  const updatedFavouritePlans = favouritePlans;

  if (favouritePlans.indexOf(favouritePlanContractCode) === -1) {
    updatedFavouritePlans.push(favouritePlanContractCode);
  } else {
    updatedFavouritePlans.splice(updatedFavouritePlans.indexOf(favouritePlanContractCode), 1);
  }

  return updatedFavouritePlans;
};

const getFavouritePlans = (plans: Contracts, favouritePlansContractCodes: string[]) => {
  const favouritePlans = plans.filter(({ contractCode }) => favouritePlansContractCodes.includes(contractCode));
  return favouritePlans;
};

const getCurrentCoverageMedicalPlan = (currentCoverages: CurrentCoverages) => {
  return currentCoverages.find((coverage: CurrentCoverage) => coverage.planType === PLAN_TYPES_NAMES.MEDICAL);
};

const isAnyFilterApplied = (): boolean => {
  const { medicalDeductibleFilters, monthlyCostFilters, switchFilters, benefitsTiers } = useGlobalStore.getState();

  return (
    medicalDeductibleFilters.some((filter) => filter.isChecked) ||
    monthlyCostFilters.some((filter) => filter.isChecked) ||
    benefitsTiers.some((filter) => filter.isChecked) ||
    switchFilters.some((filter) => filter.enabled)
  );
};

export {
  getActiveSortValue,
  getApplicableMetalTiers,
  getBenefitTierFilteredPlans,
  getCSR,
  getContractCodes,
  getCurrentCoverageMedicalPlan,
  getEmptyPlansUseCase,
  getFavouritePlans,
  getFilteredPlans,
  getFormattedApplicantDetails,
  getFormattedApplicantDetailsForAtksaveQuote,
  getInitialBenefitTierFilters,
  getInitialSwitchFilters,
  getMatchedDoctorsForPlan,
  getMatchedMedicationsForPlan,
  getMedicalDeductibleFilters,
  getMedicalPlansPayload,
  getMontlyCostFilterOptions,
  getPlanCardLabels,
  getPlansForSwitchFilter,
  getPlansForTab,
  getPrimaryApplicant,
  getSortOptions,
  getSortedPlans,
  getSubsidyAmount,
  getSwitchFilteredPlans,
  getTabLabel,
  getUpdatedFavouritePlans,
  getUpdatedSortOptions,
  isAnyFilterApplied,
  isFilterApplied,
  setDomesticPartnerAsSpouse,
  sortPlans
};
