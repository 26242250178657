import dayjs from 'dayjs';
import { StateCreator } from 'zustand';
import { COVERAGE_EFFECTIVE_CONSTS, ROLE_ALL, STATE_CODES } from '../shared/globalConstants';
import { getMemberPlanTypes, getPlanTypes, setCoverageDate } from '../shared/utils/globalUtils';

const zipcodeInitailState = {
  zipCode: '',
  stateCode: STATE_CODES.UNKNOWN,
  longitude: 0,
  latitude: 0,
  countyList: {
    county: []
  },
  timeZone: '',
  gMTOffset: 0,
  dayLightSavingFlag: '',
  currentDateTimeInZone: '',
  coverageEffectiveDate: {
    coverageDt: []
  }
};

const formDetails = {
  zipcode: '',
  state: '',
  brand: '',
  county: '',
  countyCode: '',
  year: '',
  coverageDates: []
};

const initialState = {
  zipCodeRes: zipcodeInitailState,
  zipcode: '',
  validState: '',
  state: '',
  brand: '',
  countyList: [],
  county: '',
  countyCode: '',
  year: '',
  planTypes: [],
  memberPlanTypes: [],
  coverageDate: '',
  coverageDates: [],
  coverageType: '',
  applicantFormDetails: [],
  basicPlanType: '',
  isEditCoverage: false,
  loginType: '',
  dataChosen: '',
  editZipCodeRes: zipcodeInitailState,
  editZipCodeFormDetails: formDetails,
  editCoverageType: '',
  editplanTypes: [],
  editApplicantFormDetails: [],
  openDepModal: {
    planType: '',
    show: false,
    isMacFlow: false,
    popUpType: ''
  },
  closeDepModal: {
    planType: '',
    cancel: false,
    continue: false
  },
  saveandResumeLaterFlag: false,
  dashBoardBrand: '',
  visitedPages: [],
  selectedQuoteConflict: '',
  selectedApplicationConflict: '',
  changeDemographicFlag: false,
  showRecommendationModal: false,
  isBasicsPageUpdated: false,
  showCountyError: false,
  macConflictSenario: {
    isConflict: false,
    userSelection: 'policy',
    agentSelection: 'agentofrecord'
  },
  isSubsidydataApplied: false,
  selectedYear: ''
};

export const createQuoteStoreSlice: StateCreator<QuoteStore> = (set, get) => ({
  ...initialState,
  updateZipCodeResponse: (response: ZipCodeClass) =>
    set({
      zipCodeRes: response,
      countyList: response.countyList.county
    }),
  updateCountyList: () =>
    set({
      countyList: get().zipCodeRes.countyList.county
    }),
  updateEditZipCodeRes: (response: ZipCodeClass) =>
    set({
      editZipCodeRes: response,
      countyList: response.countyList.county
    }),
  updateZipCodeFormDetails: (zipcode: string, countyVal: string, yearVal: string) => {
    set({
      zipcode: zipcode,
      county: countyVal,
      state: get().zipCodeRes.stateCode,
      countyCode: get().zipCodeRes.countyList.county.find((val) => {
        return countyVal === val.countyName;
      })?.countyCode,
      brand: get().zipCodeRes.countyList.county.find((val) => {
        return countyVal === val.countyName;
      })?.brand,
      year: yearVal,
      coverageDates: get().zipCodeRes.coverageEffectiveDate.coverageDt.filter((type) => {
        return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(yearVal) !== -1;
      })
    });
  },
  updateEditZipCodeFormDetails: (zipcode: string, countyVal: string, yearVal: string) => {
    set({
      editZipCodeFormDetails: {
        zipcode: zipcode,
        county: countyVal,
        state: get().editZipCodeRes.stateCode,
        countyCode: get().editZipCodeRes.countyList.county.find((val) => {
          return countyVal === val.countyName;
        })?.countyCode,
        brand: get().editZipCodeRes.countyList.county.find((val) => {
          return countyVal === val.countyName;
        })?.brand,
        year: yearVal,
        coverageDates: get().editZipCodeRes.coverageEffectiveDate.coverageDt.filter((type) => {
          return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(yearVal) !== -1;
        })
      }
    });
  },
  updateSelectedCounty: (value: string) =>
    set({
      county: value,
      countyCode: get().zipCodeRes.countyList.county.find((val) => {
        return value === val.countyName;
      })?.countyCode,
      brand: get().zipCodeRes.countyList.county.find((val) => {
        return value === val.countyName;
      })?.brand
    }),
  updatePlanTypes: (county, year) =>
    set({
      planTypes: getPlanTypes(get().zipCodeRes, county, year, ROLE_ALL)
    }),
  updatePlanTypesFromProposal: (planTypes: string[]) =>
    set({
      planTypes: planTypes
    }),
  updateEditPlanTypes: (county, year) =>
    set({
      editplanTypes: getPlanTypes(get().editZipCodeRes, county, year, ROLE_ALL)
    }),
  updateMemberPlanTypes: (currentCoverages: CurrentCoverages) =>
    set({
      memberPlanTypes: getMemberPlanTypes(currentCoverages)
    }),
  updateCoverageDate: (value: string) =>
    set({
      coverageDate: dayjs(value).format('YYYY/MM/DD')
    }),
  updateCoverageType: (value: string) =>
    set({
      coverageType: value,
      coverageDate: dayjs(setCoverageDate(get().coverageDates, value)).format('YYYY/MM/DD')
    }),
  updateZipCode: (data: string) =>
    set({
      zipcode: data
    }),
  updateYear: (data: string) =>
    set({
      year: data
    }),
  updateEditCoverageType: (value: string) =>
    set({
      editCoverageType: value,
      coverageDate: dayjs(setCoverageDate(get().coverageDates, value)).format('YYYY/MM/DD')
    }),
  updateApplicantFormDetails: (value: Applicant[]) =>
    set({
      applicantFormDetails: value
    }),
  updateEditApplicantFormDetails: (value: Applicant[]) =>
    set({
      editApplicantFormDetails: value
    }),
  resetEditZipCodeRes: () =>
    set({
      editZipCodeRes: zipcodeInitailState
    }),
  resetEditZipCodeFormDetails: () =>
    set({
      editZipCodeFormDetails: formDetails,
      editCoverageType: '',
      editplanTypes: []
    }),
  updateBasicPlanType: (value: string) =>
    set({
      basicPlanType: value
    }),
  updateIsEditCoverage: (value: boolean) =>
    set({
      isEditCoverage: value
    }),
  updateCloseDepModal: (lplanType: string, lcancel: boolean, lcontinue: boolean) => {
    set({
      closeDepModal: {
        planType: lplanType,
        cancel: lcancel,
        continue: lcontinue
      }
    });
  },
  updateOpenDepModal: (planType: string, show: boolean, isMacFlow: boolean, popUpType: string) => {
    set({
      openDepModal: {
        planType: planType,
        show: show,
        isMacFlow: isMacFlow,
        popUpType: popUpType
      }
    });
  },
  updateSaveAndResumeLaterFlag: (value: boolean) => {
    set({
      saveandResumeLaterFlag: value
    });
  },
  updateSelectedBrand: (value: string) => {
    set({
      brand: get().zipCodeRes.countyList.county.find((val) => {
        return value === val.countyName;
      })?.brand
    });
  },
  updateVisitedPages(pages: Pages[]) {
    set({
      visitedPages: pages
    });
  },
  updateSelectedQuoteConflict: (value: string) => {
    set({
      selectedQuoteConflict: value
    });
  },
  updateSelectedApplicationConflict: (value: string) => {
    set({
      selectedApplicationConflict: value
    });
  },
  updateChangeDemographicFlag: (value: boolean) => {
    set({
      changeDemographicFlag: value
    });
  },
  updateShowRecommendationModal: (value: boolean) => {
    set({
      showRecommendationModal: value
    });
  },
  updateIsBasicsPageUpdated: (value: boolean) => {
    set({
      isBasicsPageUpdated: value
    });
  },
  updatePrposedQuoteDetails: (proposedQuoteDetails: ProposedQuoteDetails) => {
    set({
      county: proposedQuoteDetails.county,
      countyCode: proposedQuoteDetails.countyCode,
      zipcode: proposedQuoteDetails.zipcode,
      state: proposedQuoteDetails.state,
      brand: proposedQuoteDetails.brand,
      coverageType: proposedQuoteDetails.coverageType
    });
  },
  updateBrand: (value: string) => {
    set({
      brand: value
    });
  },
  updateShowCountyError: (value: boolean) => {
    set({
      showCountyError: value
    });
  },
  resetQuoteStore: () => set(() => ({ ...initialState })),
  updateLoginType: (value: string) => {
    set({
      loginType: value
    });
  },
  updateMacConflictSenario: (isConflict: boolean, userSelection: string, agentSelection: string) => {
    set({
      macConflictSenario: {
        isConflict: isConflict,
        userSelection: userSelection,
        agentSelection: agentSelection
      }
    });
  },
  updateDataChosen: (value: string) => {
    set({
      dataChosen: value
    });
  },
  updateIsSubsidydataApplied: (value: boolean) => {
    set({
      isSubsidydataApplied: value
    });
  },
  updateSelectedYear: (value: string) => {
    set({
      selectedYear: value
    });
  },
  updateValidState: (validState: string) => {
    set({
      validState: validState
    });
  }
});
