import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import moment from 'moment';
import { KY_EXCHANGE_BASE_URLS, OFF_EXCHANGE_BASE_URLS, WELLPOINT_OFF_EXCHANGE_BASE_URLS } from '../../api/api-name-list';
import { MEDICAL_PLANS_TABS } from '../../pages/public/medical-plans/constants';
import { useGlobalStore } from '../../store/globalStore';
import {
  ALPHA_NUMERIC_REGX,
  APPLICANT_TYPES,
  ATK_ROLES_FOR_ROLE,
  BOOLEAN_VALUES,
  COVERAGE_PERIOD,
  DATE_BEFORE_1900,
  DATE_FORMATS,
  DEFAULT_YEAR,
  GENDER,
  INPUT_TYPES,
  LQE_NY_STATE,
  MARKET_TAGS,
  MQUOTINGFLAG,
  NUMBER_REGX,
  PARENT_VALUES,
  PLAN_TYPES,
  PLAN_TYPES_ARRAY,
  PLAN_TYPES_CONTENT,
  PLAN_TYPES_NAMES,
  PLAN_TYPES_ORDER,
  ROLE_ALL,
  SET_APPLICATION_CONSTANTS,
  STATE_CODES,
  TOBACCO_STATE_CODES,
  USER_DOMAINS
} from '../globalConstants';
dayjs.extend(isBetween);

interface planType {
  value: string;
  planYear: string;
}
const validateUserName = (userName: string) => ALPHA_NUMERIC_REGX.test(userName);
const validatePassword = (password: string) => ALPHA_NUMERIC_REGX.test(password);
const validateNumber = (number: string) => NUMBER_REGX.test(number);

const getErrorMessage = (inputType = INPUT_TYPES.USERNAME, value = '') => {
  switch (inputType) {
    case INPUT_TYPES.USERNAME:
      return 'Invalid username';
    case INPUT_TYPES.PASSWORD:
      return 'Invalid password';
    default:
      return '';
  }
};

const formatDateToMMDDYYY = (date: string) => {
  return date ? dayjs(date).format(DATE_FORMATS.MMDDYYYY) : '';
};

const formatDateToYYYYMMDD = (date: string) => {
  return date ? dayjs(date).format(DATE_FORMATS.YYYYMMDD) : '';
};

const getAgeByDob = (dob: string) => {
  const today = dayjs();
  const birthDate = dayjs(dob);
  let age = today.year() - birthDate.year();
  const m = today.month() - birthDate.month();

  if (m < 0 || (m === 0 && today.date() < birthDate.date())) {
    age--;
  }
  return age;
};

const isDateBefore1850 = (date: string): boolean => {
  const dateObj = moment(date).format(DATE_FORMATS.YYYYMMDD);
  return dateObj < moment(DATE_BEFORE_1900).format(DATE_FORMATS.YYYYMMDD);
};

const getTodayDateForATK = () => {
  const { agentZipCodeRes } = useGlobalStore.getState();
  if (agentZipCodeRes?.currentDateTimeInZone) {
    return dayjs(agentZipCodeRes.currentDateTimeInZone).toISOString();
  } else {
    return dayjs().toISOString();
  }
};

const openEnrollmentStarted = (): boolean => {
  const { coverageRule } = useGlobalStore.getState();
  let today = getTodayDateForATK();
  const coverageRuleObj = coverageRule.find((period) => period.period === COVERAGE_PERIOD.OE);

  return coverageRuleObj ? today >= dayjs(coverageRuleObj?.startDate).toISOString() : false;
};

const isFutureDate = (date: string) => {
  const diff = dayjs().diff(dayjs(date));
  return diff < 0;
};

const isBeforeDate = (date: string) => {
  return dayjs(date).isBefore(dayjs());
};

const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getPlanTypes = (response, county, year, role) => {
  const modifiedRole = ATK_ROLES_FOR_ROLE.includes(role) ? role : ROLE_ALL;
  const planTypes: string[] = [];
  const selectedCountyObj = response.countyList.county.find((option) => option.countyName === county);
  const MquotingOFF = response.coverageEffectiveDate.coverageDt.find((type) => {
    return type.type === MQUOTINGFLAG && type.role === modifiedRole && type.planYear.indexOf(year) !== -1;
  });
  if (selectedCountyObj?.inService?.planYear?.indexOf(year) !== -1 && selectedCountyObj?.inService?.value === 'Y') {
    selectedCountyObj.planList.planType.forEach((element: planType) => {
      if (PLAN_TYPES_ARRAY.includes(element.value) && element.planYear.indexOf(year) !== -1) {
        planTypes.push(element.value);
      }
    });
  }
  const sortedPlanTypes = planTypes.sort((a, b) => PLAN_TYPES_ORDER[a] - PLAN_TYPES_ORDER[b]);
  if (MquotingOFF && MquotingOFF.value === BOOLEAN_VALUES.FALSE.toUpperCase() && MquotingOFF.ind === ROLE_ALL) {
    const index = sortedPlanTypes.indexOf(PLAN_TYPES_CONTENT.M);
    if (index !== -1) {
      sortedPlanTypes.splice(index, 1);
    }
  }
  return sortedPlanTypes;
};

const getMemberPlanTypes = (coverages: CurrentCoverages) => {
  const uniqueTypes: string[] = [...new Set(coverages.map((item) => item.planType))];
  if (uniqueTypes.includes('LIFE')) {
    const index = uniqueTypes.indexOf('LIFE');
    uniqueTypes.splice(index, 1);
  }
  const sortedPlanTypes = uniqueTypes.sort((a, b) => PLAN_TYPES_ORDER[a] - PLAN_TYPES_ORDER[b]);
  return sortedPlanTypes;
};

const compareDemographicsData = (loginDemographicData: DemographicInfo, memberDemographicData: DemographicInfo): boolean => {
  if (loginDemographicData.zipCode === memberDemographicData.zipCode && loginDemographicData.state === memberDemographicData.state) {
    const loginApplicants = loginDemographicData?.applicant ?? [];
    const memberDemographicApplicants = memberDemographicData?.applicant ?? [];

    return compare_arrays_of_objects(loginApplicants, memberDemographicApplicants, ['firstName', 'dateOfBirth']);
  } else {
    return false;
  }
};

const compare_arrays_of_objects = (loginDemographicApplicantData: Applicant[], memberDemographicApplicantData: Applicant[], keys: string[]): boolean => {
  // Check if the lengths of the arrays are equal
  if (loginDemographicApplicantData.length !== memberDemographicApplicantData.length) {
    return false;
  }

  // Create a set to store the unique representations of objects in arr1
  const arr1Set = new Set<string>();

  // Iterate over each object in arr1
  for (const obj of loginDemographicApplicantData) {
    // Extract the specified keys from the object
    const extractedObj: any = {};
    for (const key of keys) {
      extractedObj[key] = obj[key];
    }
    // Convert the extracted object to a string representation
    const objString = JSON.stringify(extractedObj);
    arr1Set.add(objString);
  }

  // Iterate over each object in arr2
  for (const obj of memberDemographicApplicantData) {
    // Extract the specified keys from the object
    const extractedObj: any = {};
    for (const key of keys) {
      extractedObj[key] = obj[key];
    }
    // Convert the extracted object to a string representation
    const objString = JSON.stringify(extractedObj);
    // Check if the object representation is in arr1Set
    if (!arr1Set.has(objString)) {
      return false;
    }
  }

  // If all objects are equal, return true
  return true;
};

const setCoverageDate = (dates, type) => {
  let date;
  if (type === 'M' || type === 'MDV') {
    date = dates?.find((date) => date.type === 'OffExchange');
  } else if (type === 'D' || type === 'V') {
    date = dates?.find((date) => date.type === 'OffExDental');
  }
  return date ? date.value : '';
};

const getFormattedCurrency = (amount: number | undefined): string => {
  return amount ? '$' + amount.toFixed(2).toString() : '$0.00';
};
const setWellpointStyles = () => {
  document.documentElement.style.setProperty('--color-rgb-primary', '26, 54, 115');
  document.documentElement.style.setProperty('--color-rgb-btn-primary', '218, 24, 82, 1');
  document.documentElement.style.setProperty('--color-rgb-callout', '26, 54, 115');
  document.documentElement.style.setProperty('--color-callout-text', '#FFFFFF');
  document.documentElement.style.setProperty('--color-icon-highlight', '218, 24, 82, 1');
  document.documentElement.style.setProperty('--color-rgb-btn-radio', '93, 15, 56, 1');
};

const compareTwoObjects = (array1: any[], array2: any[], keysToExclude: string[]): boolean => {
  // Check if the lengths of the arrays are equal
  if (array1.length !== array2.length) {
    return false;
  }

  // Iterate over each object in array1
  for (const obj1 of array1) {
    // Find the corresponding object in array2
    const obj2 = array2.find((o) => {
      // Compare the keys that are not excluded
      for (const key in obj1) {
        if (!keysToExclude.includes(key) && obj1[key] !== o[key]) {
          return false;
        }
      }
      return true;
    });

    // If no corresponding object is found, return false
    if (!obj2) {
      return false;
    }
  }

  // All objects match
  return true;
};

const compareLocationInfo = (obj1: ZipCodeClass, obj2: ZipCodeClass): boolean => {
  // Compare properties one by one
  if (obj1.zipCode !== obj2.zipCode) return false;
  if (obj1.stateCode !== obj2.stateCode) return false;
  if (obj1.longitude !== obj2.longitude) return false;
  if (obj1.latitude !== obj2.latitude) return false;
  if (obj1.timeZone !== obj2.timeZone) return false;
  if (obj1.gMTOffset !== obj2.gMTOffset) return false;
  if (obj1.dayLightSavingFlag !== obj2.dayLightSavingFlag) return false;
  if (obj1.currentDateTimeInZone !== obj2.currentDateTimeInZone) return false;

  // Compare county lists
  if (obj1.countyList.county.length !== obj2.countyList.county.length) return false;
  for (let i = 0; i < obj1.countyList.county.length; i++) {
    if (obj1.countyList.county[i] !== obj2.countyList.county[i]) return false;
  }

  // Compare coverage effective dates
  if (obj1.coverageEffectiveDate.coverageDt.length !== obj2.coverageEffectiveDate.coverageDt.length) return false;
  for (let i = 0; i < obj1.coverageEffectiveDate.coverageDt.length; i++) {
    if (obj1.coverageEffectiveDate.coverageDt[i] !== obj2.coverageEffectiveDate.coverageDt[i]) return false;
  }

  // All properties match
  return true;
};

const replaceKeysInArrayOfObjects = (arr: any[], keysToReplace: string[], replacementKeys: string[]): any[] => {
  return arr?.map((obj) => {
    const newObj = { ...obj };
    for (let i = 0; i < keysToReplace.length; i++) {
      const key = keysToReplace[i];
      const replacementKey = replacementKeys[i];
      if (key in newObj) {
        newObj[replacementKey] = newObj[key];
        delete newObj[key];
      }
    }
    if (replacementKeys.includes('isTabaccoUser')) {
      const index = replacementKeys.indexOf('isTabaccoUser');
      const value = newObj[replacementKeys[index]];
      if (value === 'N') {
        newObj[replacementKeys[index]] = 'NO';
      } else {
        newObj[replacementKeys[index]] = 'YES';
      }
    }
    return newObj;
  });
};

const convertApiDatatoFormDataFormat = (data, state?, year?) => {
  let formData: formDataInterface = {
    applicant: {
      applicantType: 'PRIMARY',
      firstName: '',
      dateOfBirth: '',
      email: '',
      phoneNumber: '',
      phoneType: '',
      smsPermission: false,
      isTabaccoUser:
        state && state === SET_APPLICATION_CONSTANTS.KY && year !== DEFAULT_YEAR
          ? SET_APPLICATION_CONSTANTS.NO
          : !(state && TOBACCO_STATE_CODES.includes(state))
            ? SET_APPLICATION_CONSTANTS.NO
            : '',
      applicantId: '0',
      status: 'A'
    },
    spouse: [],
    dependents: [],
    coverageType: ''
  };
  data.forEach((element) => {
    if (element.applicantType === APPLICANT_TYPES.PRIMARY) {
      formData.applicant = { ...element };
    } else if (element.applicantType === APPLICANT_TYPES.SPOUSE || element.applicantType === APPLICANT_TYPES.DOMESTICPARTNER) {
      formData.spouse.push(element);
    } else if (
      element.applicantType !== APPLICANT_TYPES.PRIMARY ||
      element.applicantType !== APPLICANT_TYPES.SPOUSE ||
      element.applicantType !== APPLICANT_TYPES.DOMESTICPARTNER
    ) {
      formData.dependents.push(element);
    }
  });
  return formData;
};

const isEmpty = (obj): boolean => {
  return Object.values(obj).every((value) => {
    if (Array.isArray(value)) {
      return value.length === 0; // Check if array is empty
    }
    return value === null || value === '' || value === undefined || value === 0; // Check for null or empty string
  });
};

const isNullOrUndefined = (value: any): boolean => {
  return value === null || value === undefined;
};

const isObjectEmpty = (value: any): value is EmptyValue['empty'] => {
  if (typeof value === 'string' || typeof value === 'number') {
    return value === '' || value === 0;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  } else if (typeof value === 'object' && value !== null) {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (!isObjectEmpty(value[key])) {
          return false; // Nested object is not empty
        }
      }
    }
    return true; // All properties are empty
  }
  return false;
};

const isApplicantDateInRange = (dateOne: string, dateTwo: string): boolean => {
  const comparisonDate = dayjs(dateOne);
  const birthDate = dayjs(dateTwo);

  // Calculate the year, month, and day for the maximum allowable date from birthDate
  let year = birthDate.year() + 118;
  let month = birthDate.month() + 11;
  let day = birthDate.date() + 30;

  // Correct the month and year if month exceeds 11 (JavaScript months are 0-indexed)
  if (month > 11) {
    year += Math.floor(month / 12);
    month %= 12;
  }

  // Create the maxDate with corrected year and month
  let maxDate = dayjs().year(year).month(month).date(birthDate.date()).add(30, 'day');

  // If adding 30 days to the birthDate's day exceeds the number of days in the maxDate's month, adjust maxDate to the last day of the month
  if (
    day - birthDate.date() >
    dayjs()
      .year(year)
      .month(month + 1)
      .date(0)
      .date()
  ) {
    maxDate = dayjs()
      .year(year)
      .month(month + 1)
      .date(0);
  }

  // Check if the comparisonDate is before or on the maxDate
  return comparisonDate <= maxDate;
};

const convertAgentApiDatatoFormDataFormat = (data, state?) => {
  let formData: agentFormDataInterface = {
    applicant: {
      applicantType: 'PRIMARY',
      firstName: '',
      lastName: '',
      midInitial: '',
      gender: '',
      SSN: '',
      dateOfBirth: '',
      email: '',
      isTabaccoUser: !(state && TOBACCO_STATE_CODES.includes(state)) ? SET_APPLICATION_CONSTANTS.NO : '',
      applicantId: '0'
    },
    spouse: [],
    dependents: []
  };
  data?.forEach((element, index) => {
    if (element.applicantType === APPLICANT_TYPES.PRIMARY || element.applicantType === APPLICANT_TYPES.SELF) {
      element.applicantId = element.applicantId || index.toString();
      formData.applicant = { ...element };
      formData.applicant.applicantType = 'PRIMARY';
    } else if (element.applicantType === APPLICANT_TYPES.SPOUSE || element.applicantType === APPLICANT_TYPES.DOMESTICPARTNER) {
      element.applicantId = element.applicantId || index.toString();
      formData.spouse.push(element);
    } else if (
      element.applicantType !== APPLICANT_TYPES.PRIMARY ||
      element.applicantType !== APPLICANT_TYPES.SPOUSE ||
      element.applicantType !== APPLICANT_TYPES.DOMESTICPARTNER
    ) {
      element.applicantId = element.applicantId || index.toString();
      formData.dependents.push(element);
    }
  });
  return formData;
};

const checkDateRangesInEnrollmentPeriods = (currentDate: string | Date, periods: string[], globalEnrollmentPeriods: coverageRule[], state: string) => {
  let date = dayjs(currentDate).toISOString();
  let result = false;
  for (const period of periods) {
    const filteredPeriods = globalEnrollmentPeriods.filter((enrollmentPeriod) => enrollmentPeriod.period === period && enrollmentPeriod.state.includes(state));
    for (const enrollmentPeriod of filteredPeriods) {
      if (dayjs(date).isBetween(dayjs(enrollmentPeriod.startDate).toISOString(), dayjs(enrollmentPeriod.endDate).toISOString(), 'day', '[]')) {
        result = true;
        break;
      }
    }
    if (result) {
      break;
    }
  }
  return result;
};

const isApplicantSpouseInRange = (dateOne: string, dateTwo: string): boolean => {
  const firstDate = dayjs(dateOne);
  const secondDate = dayjs(dateTwo);

  let ageYears = firstDate.year() - secondDate.year();
  let ageMonths = firstDate.month() - secondDate.month();
  let ageDays = firstDate.date() - secondDate.date();

  if (ageDays < 0) {
    ageMonths -= 1;
    ageDays += secondDate.add(1, 'month').date(0).date(); // Adjust days based on the month's total days
  }

  if (ageMonths < 0) {
    ageYears -= 1;
    ageMonths += 12; // Adjust months
  }

  // Check if the age is within the allowed range
  const isMinAge = ageYears > 15 || (ageYears === 15 && ageMonths >= 0 && ageDays >= 0);
  const isMaxAge = ageYears < 118 || (ageYears === 118 && (ageMonths < 11 || (ageMonths === 11 && ageDays <= 30)));

  return isMinAge && isMaxAge;
};

const isDependentChildDateInRange = (dateOne: string, dateTwo: string): boolean => {
  const comparisonDate = dayjs(dateOne);
  const birthDate = dayjs(dateTwo);

  let ageYears = comparisonDate.year() - birthDate.year();
  let ageMonths = comparisonDate.month() - birthDate.month();
  let ageDays = comparisonDate.date() - birthDate.date();

  // Adjust age if the current date is before the birth date in the current year
  if (ageMonths < 0 || (ageMonths === 0 && ageDays < 0)) {
    ageYears--;
  }

  // Check if the age is within the specified range
  const isMinAge = ageYears >= 0;
  const isMaxAge = ageYears < 26 || (ageYears === 26 && (ageMonths < 0 || (ageMonths === 0 && ageDays <= 0)));
  return isMinAge && isMaxAge;
};

const isDateinFuture = (current, value) => {
  const givenDate = dayjs(value).startOf('day');
  const currentDate = dayjs(current).startOf('day');
  if (givenDate.isAfter(currentDate)) {
    return true; // The given date is in the future
  } else {
    return false; // The given date is not in the future
  }
};

const isInValidDate = (value) => {
  return !dayjs(value).isValid();
};

const filterDependentsOnRelationType = (applicantFormDetails: Applicant[]): Applicant[] => {
  return applicantFormDetails.filter((element) => {
    if (element.applicantType) {
      return !PARENT_VALUES.includes(element.applicantType);
    } else {
      return element;
    }
  });
};

const filterDependentsOnParentRelationType = (applicantFormDetails: Applicant[]): Applicant[] => {
  return applicantFormDetails.filter((element) => {
    if (element.applicantType) {
      return PARENT_VALUES.includes(element.applicantType);
    }
  });
};

const setAdobeAnalytics = (data: AnalyticsData) => {
  window.digitalData = data;
};

const getAddressLine = (address: string | undefined, islastaddressline: boolean): string => {
  return address ? `${address}${islastaddressline ? '' : ','}` : '';
};

const getFormattedCurrentCoveragePlan = (samplePlan: Contract, currentCoverageMedicalPlan: CurrentCoverage) => {
  const { contractCode, onExchange, planName, planType, totalPremium, contractEffectiveDate, contractExpiryDate } = currentCoverageMedicalPlan;
  const formattedCurrentCoveragePlan = { ...samplePlan };

  formattedCurrentCoveragePlan.contractCode = contractCode;
  formattedCurrentCoveragePlan.planMarketingName = planName;
  if (formattedCurrentCoveragePlan?.rateData?.rate) {
    formattedCurrentCoveragePlan.rateData.rate.totSubsidizedPrem = parseFloat(totalPremium);
    formattedCurrentCoveragePlan.rateData.rate.totalPremium = parseFloat(totalPremium);
  }
  formattedCurrentCoveragePlan.contractEffectiveDate = contractEffectiveDate;
  formattedCurrentCoveragePlan.contractExpiryDate = contractExpiryDate;
  formattedCurrentCoveragePlan.onExchange = onExchange;
  formattedCurrentCoveragePlan.plan[0].planDisplayName = planName;
  formattedCurrentCoveragePlan.plan[0].planType = planType;

  return formattedCurrentCoveragePlan;
};

const getRelationshipType = (applicantType: string) => {
  if (applicantType === SET_APPLICATION_CONSTANTS.PRIMARY) {
    return SET_APPLICATION_CONSTANTS.APPLICANT;
  } else if (applicantType === APPLICANT_TYPES.DEPENDENT) {
    return APPLICANT_TYPES.CHILD;
  } else {
    return applicantType;
  }
};

const getAge = (dateString: string): number => {
  const birthDate = moment(dateString);
  const age = moment().diff(birthDate, 'years');

  return age;
};

const arraysEqual = (a: string[], b: string[]): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // You might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const getAgentFirstName = (agentInfo: AgentOfRecord, agentConnect: AgentConnect, isPublicAgentConnect: boolean) => {
  return isPublicAgentConnect ? agentConnect?.firstName : agentInfo?.firstName || agentInfo?.agentFirstName || '';
};

const getAgentLastName = (agentInfo: AgentOfRecord, agentConnect: AgentConnect, isPublicAgentConnect: boolean) => {
  return isPublicAgentConnect ? agentConnect?.lastName : agentInfo?.lastName || agentInfo?.agentLastName || '';
};

const getAgentName = (agentInfo: AgentOfRecord, agentConnetct: AgentConnect, isPublicAgentConnect: boolean) => {
  const agentFirstName = getAgentFirstName(agentInfo, agentConnetct, isPublicAgentConnect);
  const agentLastName = getAgentLastName(agentInfo, agentConnetct, isPublicAgentConnect);

  return toTitleCase(`${agentFirstName} ${agentLastName}`);
};

const validateEvendorResponse = (evendorResponse: EvenderInfoResponse) => {
  if (!evendorResponse?.eVendorInfo?.appZipcode) {
    return false;
  }
  if (evendorResponse?.eVendorInfo.appZipcode && !evendorResponse?.eVendorInfo.appZipcode.match(/^\d{5}$/)) {
    return false;
  }
  if (!evendorResponse?.eVendorInfo?.appDOB) {
    return false;
  }
  if (
    evendorResponse?.eVendorInfo?.appGender &&
    evendorResponse?.eVendorInfo?.appState !== LQE_NY_STATE &&
    evendorResponse?.eVendorInfo?.appGender !== GENDER.MALE &&
    evendorResponse?.eVendorInfo?.appGender !== GENDER.FEMALE
  ) {
    return false;
  }
  if (
    evendorResponse?.eVendorInfo?.appGender &&
    evendorResponse?.eVendorInfo?.appState === LQE_NY_STATE &&
    evendorResponse?.eVendorInfo?.appGender !== GENDER.MALE &&
    evendorResponse?.eVendorInfo?.appGender !== GENDER.FEMALE &&
    evendorResponse?.eVendorInfo?.appGender !== GENDER.OTHER
  ) {
    return false;
  }
  if (evendorResponse?.eVendorInfo?.tobaccoUser && evendorResponse?.eVendorInfo?.tobaccoUser !== 'Y' && evendorResponse?.eVendorInfo?.tobaccoUser !== 'N') {
    return false;
  }
  if (!evendorResponse?.eVendorInfo?.reqEffDt) {
    return false;
  }
  if (evendorResponse?.eVendorInfo?.reqEffDt && !isFutureDate(evendorResponse?.eVendorInfo?.reqEffDt)) {
    return false;
  }
  if (!evendorResponse?.eVendorInfo?.medicalProductId && !evendorResponse?.eVendorInfo?.dentalProductId && !evendorResponse?.eVendorInfo?.visionProductId) {
    return false;
  }

  if (evendorResponse?.eVendorInfo?.medicalProductId && !evendorResponse?.eVendorInfo?.medicalProductId.match(/^[a-zA-Z0-9]{4}/)) {
    return false;
  }
  if (evendorResponse?.eVendorInfo?.dentalProductId && !evendorResponse?.eVendorInfo?.dentalProductId.match(/^[a-zA-Z0-9]{4}/)) {
    return false;
  }
  if (evendorResponse?.eVendorInfo?.visionProductId && !evendorResponse?.eVendorInfo?.visionProductId.match(/^[a-zA-Z0-9]{4}/)) {
    return false;
  }
  if (
    evendorResponse?.eVendorInfo?.exchangeType &&
    !(evendorResponse?.eVendorInfo?.exchangeType === 'ON' || evendorResponse?.eVendorInfo?.exchangeType !== 'OFF')
  ) {
    return false;
  }
  if (!evendorResponse?.eVendorInfo?.quoteSrc) {
    return false;
  }
  if (evendorResponse?.eVendorInfo?.parentTin && !evendorResponse?.eVendorInfo?.parentTin.match(/^[a-zA-Z0-9]{10}/)) {
    return false;
  }
  if (evendorResponse?.eVendorInfo?.id && !evendorResponse?.eVendorInfo?.id.match(/^[a-zA-Z0-9]{10}/)) {
    return false;
  }
  return true;
};
const getBrandForAEMContent = (): string => {
  const brand = useGlobalStore.getState().brand;
  const deepLinkInfo = useGlobalStore.getState().deepLinkInfo;
  const isWellpointflag = window.location.href.includes('wellpoint.com/') || window.location.href.includes('/content/ios/wellpoint/');
  let defaultBrand = USER_DOMAINS.BRAND;

  if (isWellpointflag) {
    defaultBrand = USER_DOMAINS.WELLPOINT_BRAND;
  } else if (deepLinkInfo.brand) {
    defaultBrand = deepLinkInfo.brand;
  }

  return brand ? brand : defaultBrand;
};

const getStateCodeForAEMContent = (): string => {
  const stateCode = useGlobalStore.getState().validState;
  return stateCode ? stateCode : STATE_CODES.UNKNOWN;
};

const getPlanYear = (coverageDates: any, coverageValue: string) => {
  const coverageDate = dayjs(setCoverageDate(coverageDates, coverageValue)).format(DATE_FORMATS.YYYYMMDD_WITH_SLASH);
  return dayjs(coverageDate).isValid() ? dayjs(coverageDate).year().toString() : dayjs().year().toString();
};

const getPlanYearForLogin = (coverageEffectiveDate: string): string => {
  const coverageDate = dayjs(coverageEffectiveDate).format(DATE_FORMATS.YYYYMMDD_WITH_SLASH);
  return dayjs(coverageDate).isValid() ? dayjs(coverageDate).year().toString() : dayjs().year().toString();
};

const getcurrentAnchorPlan = (currentCoverages: CurrentCoverages) => {
  const uniqueTypes: string[] = [...new Set(currentCoverages.map((item) => item.planType))];
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.LIFE)) {
    const index = uniqueTypes.indexOf(PLAN_TYPES_NAMES.LIFE);
    uniqueTypes.splice(index, 1);
  }

  if (uniqueTypes.includes(PLAN_TYPES_NAMES.MEDICAL)) {
    return PLAN_TYPES.MEDICAL;
  } else if (uniqueTypes.includes(PLAN_TYPES_NAMES.DENTAL)) {
    return PLAN_TYPES.DENTAL;
  } else if (uniqueTypes.includes(PLAN_TYPES_NAMES.VISION)) {
    return PLAN_TYPES.VISION;
  }
  return null;
};

const handleSetApplicationSuccessRedirection = (isOffExchange: boolean, acn: string, env: any, isWellpoint: boolean) => {
  if (isOffExchange) {
    window.open(
      `${isWellpoint ? WELLPOINT_OFF_EXCHANGE_BASE_URLS[env ?? ''] : OFF_EXCHANGE_BASE_URLS[env ?? '']}/sales/eox/secure/apply?acn=${acn}&source=${useGlobalStore.getState().agentSSOInfo.isAtk ? 'acaRefreshPTB' : 'acaRefresh'}`,
      '_self'
    );
  } else {
    window.open(`${KY_EXCHANGE_BASE_URLS[env ?? '']}/individuals/secure/api/ede/mvc/redirectToKyNect?acn=${acn}`, '_self');
  }
};

const marketTagClass = (market: boolean, colorado: boolean) => {
  if (colorado) {
    return MARKET_TAGS.COLORADO;
  } else if (market) {
    return MARKET_TAGS.MARKET;
  } else {
    return MARKET_TAGS.OFF_MARKET;
  }
};

const recordActionSatelliteTrack = (actionName: string) => {
  if (window?._satellite) {
    window._satellite?.track(actionName);
  } else {
    console.error('window._satellite is not defined');
  }
};

const getNotMedicaidEligibleDependents = (applicants: Applicant[]): Applicant[] => {
  if (useGlobalStore.getState().selectedOptionInEstimateSavings === MEDICAL_PLANS_TABS.MARKET_PLANS) {
    const notMedicaidEligibleDependents = applicants ? applicants.filter((applicant) => !applicant.isMedicAidEligible) : applicants;
    return notMedicaidEligibleDependents;
  } else return applicants;
};

export {
  arraysEqual,
  checkDateRangesInEnrollmentPeriods,
  compareDemographicsData,
  compareLocationInfo,
  compareTwoObjects,
  compare_arrays_of_objects,
  convertAgentApiDatatoFormDataFormat,
  convertApiDatatoFormDataFormat,
  filterDependentsOnParentRelationType,
  filterDependentsOnRelationType,
  formatDateToMMDDYYY,
  formatDateToYYYYMMDD,
  getAddressLine,
  getAge,
  getAgeByDob,
  getAgentFirstName,
  getAgentLastName,
  getAgentName,
  getBrandForAEMContent,
  getErrorMessage,
  getFormattedCurrency,
  getFormattedCurrentCoveragePlan,
  getMemberPlanTypes,
  getNotMedicaidEligibleDependents,
  getPlanTypes,
  getPlanYear,
  getPlanYearForLogin,
  getRelationshipType,
  getStateCodeForAEMContent,
  getcurrentAnchorPlan,
  handleSetApplicationSuccessRedirection,
  isApplicantDateInRange,
  isApplicantSpouseInRange,
  isBeforeDate,
  isDateBefore1850,
  isDateinFuture,
  isDependentChildDateInRange,
  isEmpty,
  isFutureDate,
  isInValidDate,
  isNullOrUndefined,
  isObjectEmpty,
  marketTagClass,
  openEnrollmentStarted,
  recordActionSatelliteTrack,
  replaceKeysInArrayOfObjects,
  setAdobeAnalytics,
  setCoverageDate,
  setWellpointStyles,
  toTitleCase,
  validateEvendorResponse,
  validateNumber,
  validatePassword,
  validateUserName
};
