import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { STATUSES } from '../../../api/constants';
import { converBase64ToPdf, getAtkPrintPlanBenefits } from '../../../components/common/email-print/emailPrintUtils';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { ATK_PUBLIC_TEMPLATENAME, LANGUAGE_CODES, PLAN_TYPES_NAMES, SCREEN_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { PLAN_BENEFIT_CODES, PLAN_TIERS_NON_CATASTROPHIC } from '../medical-plans/constants';
import { fetchPlanList } from '../medical-plans/medicalPlansServices';
import { getFormattedApplicantDetails, setDomesticPartnerAsSpouse } from '../medical-plans/medicalPlansUtils';
import { default as Content } from './ProposalPlanDetails.json';
import './ProposalPlanDetails.scss';
import { getProposalPlanInfo, printProposalPlanInfo } from './proposalPlanDetailsService';

const ProposalPlanDetails: React.FC = () => {
    let proposalID = '';
    let coverageType = '';

    let PlanId = '';
    let contractCode = '';
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });

    const [printSentError, setPrintSentError] = useState(false);
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [contract, setContract] = useState<Contract>({} as Contract);
    const [planType, setPlanType] = useState<string>('');
    const [isWellpoint, setIsWellpoint] = useState(false);
    const location = useLocation();
    const initiate = async () => {
        await getContent();
        setLoading(true);
        const isWellpointflag = window.location.href.includes('wellpoint.com/') || window.location.href.includes('/content/ios/wellpoint/')
        setIsWellpoint(isWellpointflag);
        const urlParams = new URLSearchParams(location.search);
        proposalID = urlParams.get('proposalID') ?? '';
        coverageType = urlParams.get('coverageType') ?? '';
        setPlanType(urlParams.get('planType') ?? '');
        PlanId = urlParams.get('PlanId') ?? '';
        contractCode = urlParams.get('contractCode') ?? '';
        // proposalID=21080&typeofCoverage=medicalDentalVision&popup=medical&PlanId=172397&contractCode=9PQW
        getProposalPlanInfo(proposalID).then((response) => {
            const proposalPlanInfoResponse: ProposalPlanInfoResponse = response.data as ProposalPlanInfoResponse;
            const payload: PlanSummaryPayload = {
                zipCode: proposalPlanInfoResponse.zipCode ?? '',
                county: proposalPlanInfoResponse.countyName ?? '',
                countyCode: proposalPlanInfoResponse.countyCode ?? '',
                state: proposalPlanInfoResponse.state ?? '',
                coverageEffectiveDate: proposalPlanInfoResponse.coverageEffectiveDate,
                marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
                planYear: proposalPlanInfoResponse?.coverageEffectiveDate?.split('-')[0] ?? '',
                contractList: {
                    contractBundle: [{ contractCode: contractCode }]
                },
                planTypes: [urlParams.get('planType') ?? ''],
                brand: proposalPlanInfoResponse?.brand ?? '',
                applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(proposalPlanInfoResponse?.applicants ?? [], false)),
                subsidy: {
                    subsidyAmt: urlParams.get('planType') ?? '' === PLAN_TYPES_NAMES.MEDICAL ? (proposalPlanInfoResponse?.subsidy?.subsidyAmount?.toString() ?? '0') : null,
                    costShareReduction: urlParams.get('planType') ?? '' === PLAN_TYPES_NAMES.MEDICAL ? (Number(proposalPlanInfoResponse?.subsidy?.costShareReductionValue) ?? 0) : null
                },
                language: LANGUAGE_CODES.EN,
                metalTierTypes: PLAN_TIERS_NON_CATASTROPHIC,
            };
            fetchPlanList(payload).then((response) => {
                const data: PlanSummaryResponse = response.data;
                setContract(data.planSummaryResponse.plans.contract[0] ?? null);
                setLoading(false);
            }).catch(error => {
                console.error('error', error);
                setLoading(false);
                setLoginError({
                    hasError: true,
                    responseCode: '500',
                    message: pageContent?.SYSTEM_EXCEPTION_MESSAGE
                });
            });


        }).catch(error => {
            console.error('error', error);
            setLoading(false);
            setLoginError({
                hasError: true,
                responseCode: '500',
                message: pageContent?.SYSTEM_EXCEPTION_MESSAGE
            });
        });


    };

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PROPOSAL_PLAN_DETAILS);
            content = response.data.data.iospagecontentList.items[0].pageContent.ProposalPlanDetails;
        } catch (error) {
            content = Content.data.iospagecontentList.items[0].pageContent.ProposalPlanDetails;
            return false;
        } finally {
            setPageContent(content);
            setContentLoaded(true);
        }
        return true;
    }, []);

    useEffect(() => {
        initiate();
    }, []);

    const printPlan = () => {
        const payload: AtkPublicPrintPayload = getPrintPayload();
        setPrintSentError(false);
        printProposalPlanInfo(payload).then((response) => {
            setLoading(false);
            if (response.data.status === STATUSES.SUCCESS) {
                converBase64ToPdf(response.data.pdfString);
            } else {
                setPrintSentError(true);
            }
        }).catch((error) => {
            setLoading(false);
            setPrintSentError(true);

        });
    }


    const getPrintPayload = (): AtkPublicPrintPayload => {
        const planBenefits: AtkPrintPlanBenefit[] = getAtkPrintPlanBenefits(contract.plan[0].planBenefits);
        const printPayload: AtkPublicPrintPayload = {
            contractList: [{
                contract: {
                    contractCode: contract.contractCode,
                    plan: [{
                        displayName: contract.planMarketingName, planName: contract.contractCode, benefits: planBenefits, type: contract.plan[0].planType
                    }],
                    planMarketingName: contract.planMarketingName,
                    tier: contract?.tiersType
                },
                rate: {
                    subsidizedPremium: contract?.rateData?.rate?.subsidyAmtApplied ? contract?.rateData?.rate?.subsidyAmtApplied.toString() : '0.00',
                    totalMonthlyPremium: contract?.rateData?.rate?.totalPremium?.toString() as string,
                    totalMemberResponsibility: contract?.rateData?.rate?.totSubsidizedPrem?.toString() as string
                }
            }],
            additionalParams: {
                templateName: ATK_PUBLIC_TEMPLATENAME
            }
        };
        return printPayload;
    }

    return (!contentLoaded ?
        <FullPageLoader /> :
        <div>{(loading && !loginError.hasError) && <FullPageLoader />}
            {(!loading && loginError.hasError) && <div>{loginError.message}</div>}
            {(!loading && !loginError.hasError && contract && planType) && <div className='proposal-plan-details-container'>
                <div className='plan-type-title'>{pageContent[planType + '_TITLE']}</div>
                <div className='plan-name-wrapper'>
                    <div className='icon-wrapper'>
                        {planType === PLAN_TYPES_NAMES.MEDICAL && <span className={'sae-icon sae-icon-heart-health'}></span>}
                        {planType === PLAN_TYPES_NAMES.DENTAL && <span className={'sae-icon sae-icon-tooth'}></span>}
                        {planType === PLAN_TYPES_NAMES.VISION && <span className={'sae-icon sae-icon-eye'}></span>}
                    </div>
                    <div className='plan-name'>{contract.planMarketingName + ' ' + contract.contractCode}</div>
                    <div>
                        <button id="btPrint" className="fwc-btn fwc-btn-primary" onClick={printPlan} >{pageContent?.PRINT}</button>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>{pageContent?.BENEFIT} </th>
                            <th>{pageContent?.IN_NETWORK_BENEFIT_DETAILS}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(contract?.rateData?.rate && Object.keys(contract?.rateData?.rate).length) && planType === PLAN_TYPES_NAMES.MEDICAL ?
                            <tr>
                                <td> <h6>{pageContent?.MONTHLY_COST}</h6>  </td>
                                <td>
                                    <div className='fwc-row'>
                                        <div className='fwc-col-3 sm-12 md-4'>
                                            <h5 className="plan-label-text" >${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                            <p className="plan-label-text">{pageContent?.MONTHLY_PREMIUM}</p>
                                        </div>
                                        <div className='fwc-col-9 sm-12 md-8'>
                                            <h6 className="plan-label-text">{pageContent?.MONTHLY_COST_BREAK}</h6>
                                            <div>
                                                <div className='fwc-row gap-breakdown'>
                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                        {pageContent?.TOTAL_PLAN_COST}
                                                    </div>
                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                                        ${contract?.rateData.rate.totalPremium}
                                                    </div>
                                                </div>
                                                <div className='fwc-row gap-breakdown'>
                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                        {pageContent?.SUBSIDY_APPLIED}
                                                    </div>
                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                                        ${contract?.rateData.rate.subsidyAmtApplied?.toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className='fwc-row gap-total-mem'>
                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                        <h6 className='total-mem-wrap'>{pageContent?.TOTAL_MEMBER_RESP}</h6>
                                                    </div>
                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text-result plan-result'>
                                                        <h5>${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr> : null
                        }
                        {
                            contract?.plan[0]?.planBenefits?.map((item, index) => {
                                if (!isWellpoint || item.planBenefitCode !== PLAN_BENEFIT_CODES.BLUE_CARD) {
                                    return (
                                        <tr key={index}>
                                            <td><h6>{pageContent?.MEDICAL_PLAN_BENEFIT_NAMES[item.planBenefitCode]}  </h6> </td>
                                            <td><span dangerouslySetInnerHTML={{ __html: item.planBenefitValue }}></span></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                        {
                            planType === PLAN_TYPES_NAMES.DENTAL && contract?.plan[0]?.planBenefits?.map((item, index) => {
                                if (!isWellpoint || item.planBenefitCode !== PLAN_BENEFIT_CODES.BLUE_CARD) {
                                    return (
                                        <tr key={index}>
                                            <td><h6>{pageContent?.DENTAL_PLAN_BENEFIT_NAMES[item.planBenefitCode]}  </h6> </td>
                                            <td><span dangerouslySetInnerHTML={{ __html: item.planBenefitValue }}></span></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                        {
                            planType === PLAN_TYPES_NAMES.VISION && contract?.plan[0]?.planBenefits?.map((item, index) => {
                                if (!isWellpoint || item.planBenefitCode !== PLAN_BENEFIT_CODES.BLUE_CARD) {
                                    return (
                                        <tr key={index}>
                                            <td><h6>{pageContent?.VISION_PLAN_BENEFIT_NAMES[item.planBenefitCode]}  </h6> </td>
                                            <td><span dangerouslySetInnerHTML={{ __html: item.planBenefitValue }}></span></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>}
        </div>)
}

export default ProposalPlanDetails;

