import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { Button, Modal, PageHeader, Row, Title } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../api/constants';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import PublicNavBar from '../../../components/common/public-nav-bar/PublicNavBar';
import { useShopperContext } from '../../../context/shopper-context/ShopperContext';
import { AEM_PATHS, ERROR_ALERT_CONFIG, LANGUAGE_CODES, NAV_PATHS, SCREEN_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { AGENT_BROKER_LOGIN_USE_CASES } from '../login/agent-broker-login/constants';
import { CREATE_PROFILE_REDIRECTION_USE_CASES } from '../login/standalone-login/constants';
import TwoFAModalBody from '../two-fa-modal/TwoFAModalBody';
import { TWO_FA_USE_CASES } from '../two-fa-modal/constants';
import './Registration.scss';
import SecurityQuestions from './SecurityQuestions/SecurityQuestions';
import { INFO_ALERT, REGISTER_SHOPPER_RESPONSE_CODES } from './constants';
import PasswordCreation from './password-creation/PasswordCreation';
import PersonalInfo from './personal-info/PersonalInfo';
import { default as Content } from './registration.json';
import { registerShopper } from './registrationServices';
import { getRegisterShopperPayload, getRegisterShopperSuccessPayload } from './registrationServicesUtils';

function Registration() {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [profileSteps, setProfileSteps] = useState(0);
  const [continueClickToggle, setContinueClickToggle] = useState(false);
  const [nextClickToggle, setNextClickToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [registerShopperError, setRegisterShopperError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const [personalInfoUpdated, setPersonalInfoUpdated] = useState(false);
  const [credentialsUpdated, setCredentialsUpdated] = useState(false);
  const navigate = useNavigate();
  const { userPassword, setUserPassword } = useShopperContext()
  const updateLoginResponse = useUserStore((state) => state.updateLoginResponse);
  const { username, validState, person, zipCodeRes, deepLinkInfo, resetRegistrationStore, updateTriggerLoginModal } = useGlobalStore((state) => state);

  const { isWellpoint } = useUserStore((state) => state);

  /*-----  Getting AEM static content ----- */
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.REGISTRATION);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent.REGISTRATION;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.REGISTRATION;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);

  useEffect(() => {
    if (personalInfoUpdated && credentialsUpdated) {
      setProfileSteps(1);
      setCredentialsUpdated(false);
      setPersonalInfoUpdated(false);
    }
  }, [credentialsUpdated, personalInfoUpdated]);

  const handleModalClose = (e) => {
    e.preventDefault();
    setShowModal(false);
    resetRegistrationStore();
    setUserPassword('');
    if (CREATE_PROFILE_REDIRECTION_USE_CASES.includes(deepLinkInfo.standaloneLoginUseCase)) {
      navigate(NAV_PATHS.STANDALONE_LOGIN);
    } else if (deepLinkInfo.agentBrokerLoginUseCase === AGENT_BROKER_LOGIN_USE_CASES.APPLICATION_TRANSFER) {
      navigate(NAV_PATHS.GUEST_LOGIN);
    } else {
      updateTriggerLoginModal(true);
      modalHeading === '' && navigate(-1);
    }
  };

  const handleInfoBarClose = (e) => {
    setRegisterShopperError({
      hasError: false,
      responseCode: '',
      message: ''
    });
  };

  const onClickOfNextOption = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setNextClickToggle(true);
  };

  const onClickOfCancelOption = (e) => {
    setProfileSteps(0);
    navigate(-1);
  };

  const onClickOfPreviousOption = (e) => {
    setProfileSteps(0);
  };
  const onClickOfContinueOption = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setContinueClickToggle(true);
  };
  const initiateRegistration = (securityQAndA: SecurityQAndA[]) => {
    setLoading(true);
    setRegisterShopperError({
      hasError: false,
      responseCode: '',
      message: ''
    });
    const payload: RegisterShopperPayload = getRegisterShopperPayload(validState, username, userPassword, USER_DOMAINS.ROLE, securityQAndA, '', person);

    registerShopper(payload)
      .then((response) => {
        const data: RegisterShopperResponse = response.data;

        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === REGISTER_SHOPPER_RESPONSE_CODES.TWO_FA_NEEDED) {
          setLoading(false);
          //update User Store
          const registrationSuccessPayload = getRegisterShopperSuccessPayload(data, username, person.phone.number, USER_DOMAINS.ROLE);
          updateLoginResponse(registrationSuccessPayload);
          // initiate 2fa flow
          setModalHeading(pageContent.MODAL_HEADING);
          setShowModal(true);
        } else {
          setRegisterShopperError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });

          setLoading(false);
        }
      })
      .catch((error: AxiosError<RegisterShopperErrorResponse>) => {
        console.warn('Register Shopper Error response :', error);
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setRegisterShopperError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message: `${pageContent.SYSTEM_EXCEPTION_PROFILE_CREATION_HEADING} <br> ${pageContent.SYSTEM_EXCEPTION_PROFILE_CREATION_SUBHEADING} <br> ${pageContent.SYSTEM_EXCEPTION_PROFILE_CREATION_MESSAGE}`
          });
        } else {
          setRegisterShopperError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: pageContent.SOMETHING_WENT_WRONG
          });
        }
        setLoading(false);
      });
  };

  const AEM_PAGE_PATH_REGISTERATION = isWellpoint ? AEM_PATHS.WELLPOINT_REGISTERATION : AEM_PATHS.ANTHEM_REGISTERATION;

  const getMessage = () => (
    <>
      {pageContent.AFTER_CREATE_PROFILE} {' '}
      <span data-analytics="backLinkSecurityRegIos" className="link-text" onClick={() => navigate(-1)}>
        {pageContent.BACK_TO_LAST_PAGE}
      </span>
    </>
  );

  return (!contentLoaded ? <FullPageLoader /> :
    <div>
      {loading && <FullPageLoader />}
      <GlobalHeader />
      <div className={'header-container'}>
        <PageHeader>
          <Row alignItems="center">
            <div>
              <div className="fwc-row">
                <div className="fwc-col-6">
                  <Title>{pageContent.CREATE_PROFILE}</Title>
                </div>
              </div>
              <p className="fwc-header-sub top-xs">{pageContent.YOU_CAN_SAVE_QUOTE}</p>
            </div>
          </Row>
        </PageHeader>
        <ResponsiveGrid
          // @ts-ignore
          pagePath={AEM_PAGE_PATH_REGISTERATION}
          itemPath="root/responsivegrid"
        />
      </div>
      <div>
        <div>
          <PublicNavBar hideSaveAndResumeLater={false} />
          <InfoBar area={INFO_ALERT.area} labelAriaCloseBtn={INFO_ALERT.type} type={INFO_ALERT.type} onClose={handleInfoBarClose}>
            <p className="reg-info-message">{getMessage()}</p>
          </InfoBar>
          {profileSteps === 0 && (
            <div>
              <PersonalInfo
                nextClickToggle={nextClickToggle}
                setNextClickToggle={() => setNextClickToggle(false)}
                updatePersonalInfoStatus={() => setPersonalInfoUpdated(true)}
              />
              <PasswordCreation
                nextClickToggle={nextClickToggle}
                setNextClickToggle={() => setNextClickToggle(false)}
                updateCredentialsStatus={() => setCredentialsUpdated(true)}
              />
            </div>
          )}
          {profileSteps === 1 && (
            <div className="fwc-row">
              <SecurityQuestions
                continueClickToggle={continueClickToggle}
                setContinueClickToggle={() => setContinueClickToggle(false)}
                initiateRegistration={(SecurityQAndA: SecurityQAndA[]) => initiateRegistration(SecurityQAndA)}
              />
            </div>
          )}

          {registerShopperError.hasError && !loading && (
            <div className="fwc-row registerErrorMessage">
              <InfoBar
                backgroundColor={'error'}
                area={ERROR_ALERT_CONFIG.AREA}
                labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                type={ERROR_ALERT_CONFIG.ERROR}
                handleClose={() => {
                  setRegisterShopperError({
                    hasError: false,
                    responseCode: '',
                    message: ''
                  });
                }}
              >
                <p className="message" dangerouslySetInnerHTML={{ __html: registerShopperError.message }} />
              </InfoBar>
            </div>
          )}
          <div className="fwc-row buttons-wrapper">
            {profileSteps === 0 && <Button data-analytics="cancelButtonCreateProfileRegIos" btnType="secondary" id="sq-cancel" onClick={(e) => onClickOfCancelOption(e)}>
              {pageContent.CANCEL}
            </Button>}
            {profileSteps === 1 && <Button data-analytics="cancelBtnSecurityRegIos" btnType="secondary" id="cp-cancel" onClick={(e) => onClickOfCancelOption(e)}>
              {pageContent.CANCEL}
            </Button>}
            <div>
              {profileSteps === 0 && (
                <Button data-analytics="nextBtnCreateProfileRegIos" id="sq-next" onClick={(e: { preventDefault: () => void }) => onClickOfNextOption(e)}>
                  {pageContent.NEXT}
                </Button>
              )}

              {profileSteps === 1 && (
                <div>
                  <Button data-analytics="previousBtnSecurityRegIos" btnType="secondary" id="sq-previous" onClick={(e) => onClickOfPreviousOption(e)}>
                    {pageContent.PREVIOUS}
                  </Button>
                  <Button data-analytics="nextBtnSecurityRegIos"
                    id="sq-continue" onClick={(e: { preventDefault: () => void }) => onClickOfContinueOption(e)}>
                    {pageContent.CONTINUE}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <GlobalFooter />
      </div>
      <Modal open={showModal} onClose={handleModalClose} title={modalHeading}>
        <Modal.Body>
          <TwoFAModalBody
            useCase={TWO_FA_USE_CASES.CREATE_PROFILE}
            showModal={showModal}
            showFooter={false}
            closeModal={() => setShowModal(false)}
            hideModalHeading={() => setModalHeading('')}
            updateModalHeading={(heading) => setModalHeading(heading)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Registration;
