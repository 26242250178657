import { Col, Modal, PageHeader, Row, Title } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';


import { getCMSStaticContent, getCMSdynamicContent } from "../../../api/cms/cmsUtil";
import GlobalHeader from "../../../components/common/global-header/GlobalHeader";
import MatchingListCard from "../../../components/common/matching-list-card/MatchingListCard";
import { getApplicableGrade, getGradeLabel, getMedicationNames } from "../../../components/common/plan-card/planCardUtils";
import { AGENT_ROLES, LANGUAGE_CODES, MARKET_TAGS, NAV_PATHS, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES_NAMES, SCREEN_NAMES, STATE_CODES } from "../../../shared/globalConstants";
import { getFormattedCurrency, openEnrollmentStarted } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from "../../../store/globalStore";

import { useNavigate } from "react-router-dom";
import EmailPrint from "../../../components/common/email-print/EmailPrint";
import FullPageLoader from "../../../components/common/full-page-loader/FullPageLoader";
import PlanCoverageAccordion from "../../../components/common/plan-coverage-accordion/PlanCoverageAccordion";
import SuccessModalBody from '../../../components/common/success-modal-body/SuccessModalBody';
import { useUserStore } from "../../../store/userStore";
import DentalPlanDetails from "../../public/dental-plan-details/DentalPlanDetails";
import MedicalPlanDetails from "../../public/medical-plan-details/MedicalPlanDetails";
import { getApplicationTypeForAEM } from "../../public/medical-plan-details/medicalPlanDetailsUtils";
import { GRADE_LEVELS } from "../../public/medical-plans/constants";
import { getFormattedApplicantDetails } from "../../public/medical-plans/medicalPlansUtils";
import { DENTAL_PLAN_BENEFITS_ARR, MEDICAL_PLAN_BENEFITS_ARR, VISION_PLAN_BENEFITS_ARR } from "../../public/plan-recommendations/constants";
import { getCoverageTypeForDynamicAEM, getRecommededPlanCardLabels, getTotalPremiums } from "../../public/plan-recommendations/planRecommendationsUtils";
import ProviderDetails from "../../public/provider-details/ProviderDetails";
import VisionPlanDetails from "../../public/vision-plan-details/VisionPlanDetails";
import { atkSaveProposal } from "../product-selection/productSelectionServices";
import { getATKsaveProposalRequestPayload, getPrimaryProductTypeByCoverageType, getUpdatedProductsForReview } from "../product-selection/productSelectionUtils";
import './ReviewPlans.scss';
import { default as ReviewPlansContent } from './reviewPlans.json';

export default function ReviewPlans() {
    const [quoteOrderings, setQuoteOrderings] = useState<QuoteOrderings>([]);
    const [medicalRecommendedPlans, setMedicalRecommendedPlans] = useState<RecComparedPlans>([]);
    const [dentalRecommendedPlans, setDentalRecommendedPlans] = useState<RecComparedPlans>([]);
    const [visionRecommendedPlans, setVisionRecommendedPlans] = useState<RecComparedPlans>([]);
    const [pageContent, setContent] = useState<any>(null);
    const [totalPremiumArray, setTotalPremiumArray] = useState<TotalPremiums>([]);
    const [medicalContract, setMedicalContract] = useState<Contract | null>(null);
    const [dentalContract, setDentalContract] = useState<Contract | null>(null);
    const [visionContract, setVisionContract] = useState<Contract | null>(null);
    const [useCase, setUseCase] = useState<string>('');
    const [contentLoaded, setContentLoaded] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [medicalPdfUrl, setMedicalPdfUrl] = useState('');
    const [dentalPdfUrl, setDentalPdfUrl] = useState('');
    const [visionPdfUrl, setVisionPdfUrl] = useState('');
    const { comparedProducts, agentBrand, agentState, providerMetaData, agentZipCodeRes, agentCoverageType, updateComparedProducts, agentYear, aTKsaveProposalResponse, updateATKsaveProposalResponse, agentSSOInfo, state, brand, year, zipCodeRes, selectedProduct, updateSelectedProduct, selectedProductTab, enrollmentYear } = useGlobalStore((state) => state)
    const { role, isWellpoint } = useUserStore((state) => state);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { isAtk } = agentSSOInfo;
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const primaryProductType = getPrimaryProductTypeByCoverageType(agentCoverageType)

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.REVIEW_PLANS);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.ReviewPlans);
        } catch (error) {
            setContent(ReviewPlansContent.data.iospagecontentList.items[0].pageContent?.ReviewPlans);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, [agentZipCodeRes.stateCode]);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();

    }, [])

    useEffect(() => {
        if (showSuccessModal)
            setTimeout(() => {
                setShowSuccessModal(false);
            }, 5000);
    }, [showSuccessModal]);

    useEffect(() => {
        setContent(ReviewPlansContent.data.iospagecontentList.items[0].pageContent?.ReviewPlans);
        if (comparedProducts?.length) {
            let quoteOrderings: QuoteOrderings = []
            comparedProducts.map((el, index) => {
                const quoteOrdering: QuoteOrdering = {
                    quoteId: index.toString(),
                    quoteLineItem: Object.keys(el.medicalProduct ?? {}).length ? 1 : 0 + Object.keys(el?.dentalProduct ?? {}).length ? 1 : 0 + Object.keys(el?.visionProduct ?? {}).length ? 1 : 0,
                }
                quoteOrderings.push(quoteOrdering);
            })
            setQuoteOrderings(quoteOrderings.sort(function (a, b) {
                return b.quoteLineItem - a.quoteLineItem;
            }))
            let medicalRecommendedPlans: RecComparedPlans = []
            let dentalRecommendedPlans: RecComparedPlans = []
            let visionRecommendedPlans: RecComparedPlans = []
            comparedProducts.map((el, index) => {
                if (Object.keys(el?.medicalProduct ?? {}).length) {
                    medicalRecommendedPlans.push({
                        contract: el.medicalProduct ?? {} as Contract,
                        matchedProviders: el.matchedDoctors ?? [],
                        matchedMedications: el.matchedMedications ?? [],
                        pdfBrochureUrl: '',
                        quoteId: index.toString()
                    })
                }
                if (Object.keys(el?.dentalProduct ?? {}).length) {
                    dentalRecommendedPlans.push({
                        contract: el.dentalProduct ?? {} as Contract,
                        matchedProviders: el.matchedDentists ?? [],
                        matchedMedications: [],
                        pdfBrochureUrl: '',
                        quoteId: index.toString()
                    })
                }
                if (Object.keys(el?.visionProduct ?? {}).length) {
                    visionRecommendedPlans.push({
                        contract: el.visionProduct ?? {} as Contract,
                        matchedProviders: el.matchedEyeDoctors ?? [],
                        matchedMedications: [],
                        pdfBrochureUrl: '',
                        quoteId: index.toString()
                    })
                }
            })
            setMedicalRecommendedPlans(medicalRecommendedPlans)
            setDentalRecommendedPlans(dentalRecommendedPlans)
            setVisionRecommendedPlans(visionRecommendedPlans)

            calculateTotalPremium();
        } else {
            navigate(-1);
        }
    }, [comparedProducts])


    useEffect(() => {
        const isMedicalContract = medicalRecommendedPlans?.length > 0;
        const isDentalContract = dentalRecommendedPlans?.length > 0;
        const isVisionContract = visionRecommendedPlans?.length > 0;
        if (!isDeleted) {
            if (isMedicalContract) {
                getPdfURL(SCREEN_NAMES.MEDICAL_PLAN_DETAILS, getApplicationTypeForAEM(medicalRecommendedPlans[0].contract.onExchange, medicalRecommendedPlans[0].contract.poPlan));
            }
            if (isDentalContract) {
                getPdfURL(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME, getApplicationTypeForAEM(dentalRecommendedPlans[0].contract.onExchange, dentalRecommendedPlans[0].contract.poPlan));
            }
            if (isVisionContract) {
                getPdfURL(SCREEN_NAMES.VISION_PLAN_DETAILS, getApplicationTypeForAEM(visionRecommendedPlans[0].contract.onExchange, visionRecommendedPlans[0].contract.poPlan));
            }
        }
        else {
            if (isMedicalContract) {
                medicalRecommendedPlans.forEach(el => {
                    el.pdfBrochureUrl = medicalPdfUrl ?? '';
                })
            }
            if (isDentalContract) {
                dentalRecommendedPlans.forEach(el => {
                    el.pdfBrochureUrl = dentalPdfUrl ?? '';
                })
            }
            if (isVisionContract) {
                visionRecommendedPlans.forEach(el => {
                    el.pdfBrochureUrl = visionPdfUrl ?? '';
                })
            }
        }
    }, [medicalRecommendedPlans, dentalRecommendedPlans, visionRecommendedPlans])



    const getPdfURL = useCallback(async (screenName: string, planTyeForAEM: string): Promise<boolean> => {
        setLoading(true);
        if (screenName !== '') {
            try {
                let queryParam = '';
                if (isAtk) {
                    queryParam = ';state=' + agentState + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';plantype=' + planTyeForAEM + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + agentYear;
                } else {
                    queryParam = ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + planTyeForAEM + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + year;
                }
                const response = await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, queryParam)
                const cmsResponse = response?.data;

                // const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, screenName, brand, state);
                // const cmsResponse = response.data;

                switch (screenName) {
                    case SCREEN_NAMES.MEDICAL_PLAN_DETAILS:
                        medicalRecommendedPlans.forEach(el => {
                            el.pdfBrochureUrl = cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary;
                        })
                        setMedicalRecommendedPlans(medicalRecommendedPlans)
                        setMedicalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME:
                        dentalRecommendedPlans.forEach(el => {
                            el.pdfBrochureUrl = cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary;
                        })
                        setDentalRecommendedPlans(dentalRecommendedPlans)
                        setDentalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.VISION_PLAN_DETAILS:
                        visionRecommendedPlans.forEach(el => {
                            el.pdfBrochureUrl = cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary;
                        })
                        setVisionRecommendedPlans(visionRecommendedPlans)
                        setVisionPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    default:
                        break;
                }

            } catch (error) {
                setLoading(false);
                return false;
            }
        }
        setLoading(false);
        return true;

    }, [agentBrand, agentState, state, brand, medicalRecommendedPlans, dentalRecommendedPlans, visionRecommendedPlans]);

    const renderGradetag = (gradeLevels: GradeLevel[], isCurrentCoveragePlan: boolean = false) => {
        if (isCurrentCoveragePlan) {
            return (
                <div className={'grade-wrapper current-coverage-grade-container'}>
                    <span className="grade-label">{pageContent?.CURRENT_COVERAGE}</span>
                </div>
            );
        } else {
            const applicableGrade = getApplicableGrade(gradeLevels);

            switch (applicableGrade) {
                case GRADE_LEVELS.RECOMMENDED:
                case GRADE_LEVELS.POPULAR:
                    return (
                        <div className={applicableGrade === GRADE_LEVELS.RECOMMENDED ? 'grade-wrapper recommended-grade-container' : 'grade-wrapper popular-grade-level'}>
                            <span className="grade-label">{getGradeLabel(applicableGrade, getRecommededPlanCardLabels(pageContent?.PLAN_CARD_LABELS))}</span>
                        </div>
                    );
                default:
                    return null;
            }
        }
    };
    const marketTagClass = (market: boolean, colorado: boolean) => {
        if (colorado) {
            return MARKET_TAGS.COLORADO;
        } else if (market) {
            return MARKET_TAGS.MARKET;
        } else {
            return MARKET_TAGS.OFF_MARKET;
        }
    }

    const placeholders = () => {
        return (
            <>
                {(quoteOrderings?.length > 0 && quoteOrderings?.length <= 2) &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
                {quoteOrderings.length === 1 &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}

            </>
        )
    }

    const displayEmptyCard = () => {
        return (
            <div key={'empty'} className={'cs-card fwc-card-generic flat fwc-col-4'}> </div>
        )
    }


    const calculateTotalPremium = () => {
        const recommendedPlans: RecComparedPlans = [...medicalRecommendedPlans, ...dentalRecommendedPlans, ...visionRecommendedPlans]
        recommendedPlans?.length > 0 && quoteOrderings?.length > 0 ? setTotalPremiumArray(getTotalPremiums(recommendedPlans, quoteOrderings)) : setTotalPremiumArray([])
    }

    const onClickDetails = (contract: Contract, type: string) => {
        switch (type) {
            case PLAN_TYPES_NAMES.MEDICAL:
                setMedicalContract(contract);
                break;
            case PLAN_TYPES_NAMES.DENTAL:
                setDentalContract(contract);
                break;
            case PLAN_TYPES_NAMES.VISION:
                setVisionContract(contract);
                break;
            default:
                setMedicalContract(null);
                setDentalContract(null);
                setVisionContract(null);
        }
    }


    const findPlanBenefit = (code, plan) => {
        const benefit = plan.contract.plan[0].planBenefits.find(benefit => benefit.planBenefitCode === code);
        return benefit?.planBenefitValue;
    };

    const deleteComparedProduct = async (quoteId: string) => {
        setIsDeleted(true);
        const updatedMedicalRecommendedPlans = medicalRecommendedPlans.filter((plan) => plan.quoteId !== quoteId);
        setMedicalRecommendedPlans(updatedMedicalRecommendedPlans);
        const updatedDentalRecommendedPlans = dentalRecommendedPlans.filter((plan) => plan.quoteId !== quoteId);
        setDentalRecommendedPlans(updatedDentalRecommendedPlans);
        const updatedVisionRecommendedPlans = visionRecommendedPlans.filter((plan) => plan.quoteId !== quoteId);
        setVisionRecommendedPlans(updatedVisionRecommendedPlans);

        const selectedProduct: SelectedProduct = comparedProducts.find((product, index) => index.toString() === quoteId) ?? {} as SelectedProduct;
        updateComparedProducts(getUpdatedProductsForReview(primaryProductType, selectedProduct, comparedProducts));
        await handleSaveATKProposal();
    }

    const marketTagLabels = () => {
        return (
            <>
                <div className='fwc-row'>
                    <div className={'fwc-flex btm-xs  market-container'}>
                        <div className={'tag-container'}>
                            <div className={'market-tag M'}>{MARKET_TAGS.MARKET}</div>
                            {pageContent?.KEY.MARKET}
                        </div>
                        <div className={'tag-container'}>
                            <div className={'market-tag OM'}>{MARKET_TAGS.OFF_MARKET}</div>
                            {pageContent?.KEY.OFF_MARKET}
                        </div>
                        {
                            agentState === STATE_CODES.CO && <div className={'tag-container'}>
                                <div className={'market-tag CC'}>{MARKET_TAGS.COLORADO}</div>
                                {pageContent?.KEY.COLORADO}
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }

    const handleSaveATKProposal = async (buttonClicked: boolean = false) => {
        setLoading(true);
        const payload = await getATKsaveProposalRequestPayload(useGlobalStore.getState().comparedProducts);
        try {
            await atkSaveProposal(payload).then((response) => {
                updateATKsaveProposalResponse(response.data);
                setLoading(false);
                buttonClicked && setShowSuccessModal(true);
            }
            )
        }
        catch (error) {
            setLoading(false);
            console.warn('error', error)
        }
    }

    const getAtkPdfBrochureUrl = () => {
        switch (primaryProductType) {
            case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
                return medicalRecommendedPlans[0]?.pdfBrochureUrl;
            case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
                return dentalRecommendedPlans[0]?.pdfBrochureUrl;
            case PLAN_TYPES_NAMES.VISION_PRODUCT:
                return visionRecommendedPlans[0]?.pdfBrochureUrl;
            default:
                return '';
        }

    }

    const hideDeleteIcon = () => {
        switch (primaryProductType) {
            case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
                return medicalRecommendedPlans?.length > 1;
            case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
                return dentalRecommendedPlans?.length > 1;
            case PLAN_TYPES_NAMES.VISION_PRODUCT:
                return visionRecommendedPlans?.length > 1;
            default:
                return true;
        }
    }
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    }

    return (
        <div>
            {(!contentLoaded || loading) && <FullPageLoader />}
            <GlobalHeader />
            {contentLoaded && <div className={'header-container'}>
                <PageHeader>
                    <Row alignItems="center">
                        <Col
                            bottomMargin
                            lg="5"
                            sm="12"
                        >
                            <Title>
                                {pageContent?.REVIEW_PLANS}
                            </Title>
                        </Col>
                    </Row>
                </PageHeader>
            </div>
            }

            {contentLoaded && <div className='reviewPlans-container'>
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row hmo-row fwc-row-bm top-sm'>
                        <div className='fwc-col-10'>
                            <span id={'paln-type-link'} className='important-link' onClick={() => { navigate(-1) }}> {pageContent?.BACK_TO_ALL_PLANS} </span>
                        </div>
                        <div>
                            <EmailPrint pdfBrochureUrl={getAtkPdfBrochureUrl()} />
                        </div>
                    </div>
                }

                <div className="fwc-row coverage-accordion-container">
                    <PlanCoverageAccordion planType={primaryProductType} labels={pageContent?.ACCORDION_LABELS} colaradoPlans={false} applicants={getFormattedApplicantDetails(selectedProduct.medicalApplicants)} />
                </div>

                {medicalRecommendedPlans?.length > 0 && <div id="medical-plans">
                    <div className='fwc-row fwc-row-bm plan-type-info-wrapper'>
                        <span className={'sae-icon sae-icon-heart-health right-xxs'}></span>
                        <h2>{pageContent?.MEDICAL_PLANS}</h2>
                    </div>
                    {marketTagLabels()}
                    <div className='fwc-row fwc-row-bm'>
                        <div className={'planRec-tray-content'}>
                            {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                return (
                                    filterArr?.length > 0 ?
                                        filterArr?.map((plan: RecComparedContract) => (
                                            plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className="planRec-tray-plan fwc-col-4 fwc-card-generic">
                                                <div className="planRec-tray-plan-header">
                                                    <div id="plan-card-grade" className="grade-container">
                                                        {renderGradetag(plan?.contract.gradeLevels, false)}
                                                    </div>
                                                    <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                    {hideDeleteIcon() && <div className={'fwc-icon fwc-icon-delete flex-align-right'} onClick={() => deleteComparedProduct(plan.quoteId ?? '')} />}
                                                </div>
                                                <div className={"planRec-tray-plan-title slider"}>
                                                    {plan?.contract.planMarketingName}
                                                </div>
                                                <div>
                                                    {agentYear === enrollmentYear && !openEnrollmentStarted() ? pageContent?.EXCEPTION_MESSAGE_DURING_WS :
                                                        <button
                                                            className={`select-button fwc-btn fwc-btn-primary`}
                                                            data-analytics={{}}
                                                            onClick={() => {
                                                                comparedProducts.map((product) => {
                                                                    if (product.medicalProduct?.contractCode === plan.contract.contractCode) {
                                                                        updateSelectedProduct(product);
                                                                    }
                                                                })
                                                                navigate(NAV_PATHS.AGENT_PLAN_SELECTION_SUMMARY);
                                                            }}
                                                        >
                                                            {pageContent.APPLY_NOW}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                        : <div key={'empty'} className="planRec-tray-plan fwc-col-4 fwc-card-generic">

                                        </div>
                                )
                            })}
                            {placeholders()}
                        </div>
                    </div>

                    <div className='fwc-row fwc-row-bm'>
                        <div className="denta-plans-width">
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                    <div className='pdf-wrapper'>
                                                        <p className={'pdf-card-paragraph'}>
                                                            <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                            ></span>
                                                            <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                        </p>
                                                        <p>
                                                            <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.MEDICAL)}>{pageContent?.DETAILS}</p>
                                                        </p>
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}

                                {placeholders()}
                            </div>

                            {
                                (medicalRecommendedPlans?.length) && medicalRecommendedPlans[0].contract && (medicalRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                    <>
                                        {
                                            MEDICAL_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                <>
                                                    <div className={'cs-row-header'}>{pageContent?.MEDICAL_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                    <div className={'cs-row'}>

                                                        {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                            const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                            return (
                                                                filterArr?.length > 0 ?
                                                                    filterArr?.map((plan: RecComparedContract) => (
                                                                        plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                                            <p className={'cs-card-paragraph'}>
                                                                                <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                            </p>
                                                                        </div>
                                                                    ))
                                                                    : displayEmptyCard()
                                                            )
                                                        })}
                                                        {placeholders()}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                ))}

                            <div className={'cs-row-header'}>{pageContent?.DRUG_LIST}</div>
                            <div className={'cs-row'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr: RecComparedPlans = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-4  your-medication'}>
                                                    <MatchingListCard
                                                        icon={'sae-icon-prescription-pill-bottle'}
                                                        label={pageContent?.PROPS_LABELS.MEDICATIONS_LIST_LABEL}
                                                        showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.MEDICATIONS}`}
                                                        showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.MEDICATIONS}`}
                                                        data={getMedicationNames(plan?.matchedMedications ?? [])}
                                                        impNoticeText={''}
                                                        isCompare={true}
                                                    />
                                                </div>
                                            ))
                                            : <div key={'empty'} className={'cs-card fwc-col-4  your-medication'}>

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>
                            <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>

                            <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>


                            <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>

                            <div className={'cs-row-header'}>{pageContent?.DOCTOR_LIST}</div>
                            <div className={'cs-row fwc-row-bm'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-4 your-doctor'} onClick={() => setUseCase(PLAN_TYPES_NAMES.MEDICAL)}>
                                                    <MatchingListCard
                                                        icon={'sae-icon-doctors-bag'}
                                                        label={pageContent?.PROPS_LABELS.DOCTORS_LIST_LABEL}
                                                        showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                        showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                        providers={plan.matchedProviders}
                                                        tooltipMessage={''}
                                                        isCompare={true}
                                                    />
                                                </div>
                                            ))
                                            : <div key={'empty'} className={'cs-card fwc-col-4 your-doctor'}>

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>

                        </div>
                    </div>

                </div>}

                {
                    dentalRecommendedPlans?.length > 0 && <div id="dental-plans">
                        <div className='fwc-row fwc-row-bm plan-type-info-wrapper'>
                            <span className={'sae-icon sae-icon-tooth right-xxs'}></span>
                            <h2>{pageContent?.DENTAL_PLANS}</h2>
                        </div>
                        {!medicalRecommendedPlans?.length && marketTagLabels()}
                        <div className='fwc-row fwc-row-bm'>
                            <div className={'planRec-tray-content'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className="planRec-tray-plan fwc-col-4 fwc-card-generic">
                                                    <div className="planRec-tray-plan-header">
                                                        <div id="plan-card-grade" className="grade-container">
                                                            {renderGradetag(plan?.contract.gradeLevels, false)}
                                                        </div>
                                                        <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                        {hideDeleteIcon() && !medicalRecommendedPlans?.length && <div className={'fwc-icon fwc-icon-delete flex-align-right'} onClick={() => deleteComparedProduct(plan.quoteId ?? '')} />}
                                                    </div>
                                                    <div className={"planRec-tray-plan-title slider"}>
                                                        {plan?.contract.planMarketingName}
                                                    </div>
                                                    {
                                                        !medicalRecommendedPlans?.length && <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => { }}>{pageContent?.APPLY_NOW}</button>
                                                    }
                                                </div>
                                            ))
                                            : <div key={'empty'} className="planRec-tray-plan fwc-col-4 fwc-card-generic">

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>
                        </div>

                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className='pdf-wrapper'>
                                                            <p className={'pdf-card-paragraph'}>
                                                                <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                                ></span>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                            </p>
                                                            <p>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.DENTAL)}>{pageContent?.DETAILS}</p>
                                                            </p>
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}
                                    {placeholders()}
                                </div>
                                <div>

                                    {
                                        (!medicalRecommendedPlans?.length) && (dentalRecommendedPlans?.length) && dentalRecommendedPlans[0].contract && (dentalRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                            <>
                                                {
                                                    DENTAL_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                        <>
                                                            <div className={'cs-row-header'}>{pageContent?.DENTAL_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                            <div className={'cs-row'}>

                                                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                                    const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                                    return (
                                                                        filterArr?.length > 0 ?
                                                                            filterArr?.map((plan: RecComparedContract) => (
                                                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                                                    <p className={'cs-card-paragraph'}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                                    </p>
                                                                                </div>
                                                                            ))
                                                                            : displayEmptyCard()
                                                                    )
                                                                })}
                                                                {placeholders()}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ))}
                                </div>
                                <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                {
                                    !medicalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>
                                }

                                {!medicalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                }


                                {!medicalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>}
                                {!medicalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}
                                    {placeholders()}
                                </div>
                                }
                                <div className={'cs-row-header'}>{pageContent?.DENTIST_LIST}</div>
                                <div className={'cs-row fwc-row-bm'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-4 your-doctor'} onClick={() => setUseCase(PLAN_TYPES_NAMES.DENTAL)}>
                                                        <MatchingListCard
                                                            icon={'sae-icon-doctors-bag'}
                                                            label={pageContent?.PROPS_LABELS.DOCTORS_LIST_LABEL}
                                                            showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            providers={plan.matchedProviders}
                                                            tooltipMessage={''}
                                                            isCompare={true}
                                                        />
                                                    </div>
                                                ))
                                                : <div key={'empty'} className={'cs-card fwc-col-4 your-doctor'}>

                                                </div>
                                        )
                                    })}

                                    {placeholders()}
                                </div>

                            </div>
                        </div>

                    </div>
                }

                {
                    visionRecommendedPlans?.length > 0 && <div id="vision-plans">
                        <div className='fwc-row fwc-row-bm plan-type-info-wrapper'>
                            <span className={'sae-icon sae-icon-eye right-xxs'}></span>
                            <h2>{pageContent?.VISION_PLANS}</h2>
                        </div>
                        {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && marketTagLabels()}
                        <div className='fwc-row fwc-row-bm'>
                            <div className={'planRec-tray-content'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className="planRec-tray-plan fwc-col-4 fwc-card-generic">
                                                    <div className="planRec-tray-plan-header">
                                                        <div id="plan-card-grade" className="grade-container">
                                                            {renderGradetag(plan?.contract.gradeLevels, false)}
                                                        </div>
                                                        <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                        {hideDeleteIcon() && !medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'fwc-icon fwc-icon-delete flex-align-right'} onClick={() => deleteComparedProduct(plan.quoteId ?? '')} />}
                                                    </div>
                                                    <div className={"planRec-tray-plan-title slider"}>
                                                        {plan?.contract.planMarketingName}
                                                    </div>
                                                    {
                                                        !medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => { }}>{pageContent?.APPLY_NOW}</button>
                                                    }
                                                </div>
                                            ))
                                            : <div key={'empty'} className="planRec-tray-plan fwc-col-4 fwc-card-generic">

                                            </div>
                                    )
                                })}

                                {placeholders()}
                            </div>
                        </div>

                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className='pdf-wrapper'>
                                                            <p className={'pdf-card-paragraph'}>
                                                                <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                                ></span>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                            </p>
                                                            <p>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.VISION)}>{pageContent?.DETAILS}</p>
                                                            </p>
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>


                                {
                                    (!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length) && (visionRecommendedPlans?.length) && visionRecommendedPlans[0].contract && (visionRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                        <>
                                            {
                                                VISION_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                    <>
                                                        <div className={'cs-row-header'}>{pageContent?.VISION_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                        <div className={'cs-row'}>

                                                            {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                                const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                                return (
                                                                    filterArr?.length > 0 ?
                                                                        filterArr?.map((plan: RecComparedContract) => (
                                                                            plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                                                <p className={'cs-card-paragraph'}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                                </p>
                                                                            </div>
                                                                        ))
                                                                        : displayEmptyCard()
                                                                )
                                                            })}
                                                            {placeholders()}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    ))}




                                <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>

                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>}
                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>}


                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>}
                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                }

                                <div className={'cs-row-header'}>{pageContent?.EYE_DOCTOR_LIST}</div>
                                <div className={'cs-row fwc-row-bm'}>

                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-4 your-doctor'} onClick={() => setUseCase(PLAN_TYPES_NAMES.VISION)}>
                                                        <MatchingListCard
                                                            icon={'sae-icon-doctors-bag'}
                                                            label={pageContent?.PROPS_LABELS.DOCTORS_LIST_LABEL}
                                                            showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            providers={plan.matchedProviders}
                                                            tooltipMessage={''}
                                                            isCompare={true}
                                                        />
                                                    </div>
                                                ))
                                                : <div key={'empty'} className={'cs-card fwc-col-4 your-doctor'}>

                                                </div>
                                        )
                                    })}
                                    {placeholders()}
                                </div>

                            </div>
                        </div>

                    </div>
                }
                {
                    totalPremiumArray?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row-header'}>{pageContent?.TOTAL}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = totalPremiumArray?.filter((plan: any) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: any) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.quoteId} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan.totalPremium)}
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}

                                {placeholders()}
                            </div>

                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row-header'}>{pageContent?.DISCLAIMERS}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {

                                    return (
                                        <div key={ordering.quoteId} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                            <p className={'cs-card-paragraph'}>
                                                {pageContent?.DISCLAIMER_REC}
                                            </p>
                                        </div>
                                    )
                                })}

                                {placeholders()}
                            </div>

                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm' id='disclaimer'>
                        <div className='denta-plans-width'>
                            <div className="fwc-row-bm">
                                <p>*{pageContent?.DISCLAIMER_SEC.DISCLAIMER}:</p>
                                {isWellpoint && <p>{pageContent?.DISCLAIMER_SEC_WELLPOINT?.DISCLAIMER_1}</p>}
                                <p>{pageContent?.DISCLAIMER_SEC?.DISCLAIMER_1}</p>
                            </div>
                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className="fwc-col-8">
                            <button
                                className="select-button fwc-btn fwc-btn-secondary"
                                onClick={() => { navigate(-1) }}
                            >
                                {pageContent?.CANCEL}
                            </button>
                        </div>
                        <div className="fwc-col-4 align-button">
                            <button
                                className="select-button fwc-btn fwc-btn-secondary"
                                onClick={() => { navigate(-1) }}
                            >
                                {pageContent?.BACK}
                            </button>
                            <button
                                className="select-button fwc-btn fwc-btn-primary"
                                onClick={() => { handleSaveATKProposal(true) }}
                            >
                                {pageContent?.SAVE_QUOTE}
                            </button>
                        </div>
                    </div>
                }

                {useCase && providerMetaData && (
                    <ProviderDetails
                        useCase={useCase}
                    />
                )}

                {medicalContract && (
                    <MedicalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeMedicalPlanDetails={() => setMedicalContract(null)}
                        contract={medicalContract}
                        showCloseButton={true}
                    />
                )}
                {dentalContract && (
                    <DentalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeDentalPlanDetails={() => setDentalContract(null)}
                        contract={dentalContract}
                        showCloseButton={true}
                    />
                )}
                {visionContract && (
                    <VisionPlanDetails
                        isCurrentCoveragePlan={false}
                        closeVisionPlanDetails={() => setVisionContract(null)}
                        contract={visionContract}
                        showCloseButton={true}
                    />
                )}
            </div>}
            <Modal open={showSuccessModal} onClose={handleCloseSuccessModal}>
                <Modal.Body>
                    {<SuccessModalBody
                        heading={pageContent?.SUCCESS_POPUP_LABLES.HEADING}
                        description={pageContent?.SUCCESS_POPUP_LABLES.SUCCESS_MESSAGE}
                        closeButtonText={pageContent?.SUCCESS_POPUP_LABLES.OK}
                        closeModal={() => {
                            setShowSuccessModal(false);
                        }}
                    />
                    }
                </Modal.Body>
            </Modal>

        </div>
    )
}
