import { STATUSES } from '../../../../api/constants';
import { getZipcodeValidation } from '../../../../api/services/utilService';
import { getNavigationDetails } from '../../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import {
  BOOLEAN_VALUES,
  ENTITY_TYPES,
  IN_PROGRESS_APPLICATION_STATUSES,
  LANGUAGE_CODES,
  NAV_PATHS,
  NON_CONFLICT_SCREENS,
  PAGE_TO_PATH_NAME,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  PLAN_TYPES_ORDER,
  SET_APPLICATION_CONSTANTS,
  USER_DOMAINS,
  USER_ROLES,
  YES,
  YES_NO_VALUES
} from '../../../../shared/globalConstants';
import { initialMemberInfo } from '../../../../shared/memberInfoDefault';
import {
  compareTwoObjects,
  formatDateToYYYYMMDD,
  getFormattedCurrentCoveragePlan,
  getPlanYear,
  getPlanYearForLogin
} from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { PAYLOAD_INPUT } from '../../../secure/dashboard/constants';
import { getDashboardApplications } from '../../../secure/dashboard/dashboardServices';
import { getDashboardApplicationListingsPayload } from '../../../secure/dashboard/dashboardUtils';
import { DEFAULT_DENTAL_CURRENT_COVERAGE } from '../../dental-plans/constants';
import { getCurrentCoverageDentalPlan } from '../../dental-plans/dentalPlansUtils';
import { subsidyCalculator } from '../../estimate-savings/estimateSavings.service';
import { DEFAULT_MEDICAL_CURRENT_COVERAGE, MEDICAL_PLANS_TABS, PLAN_TIERS_ALL } from '../../medical-plans/constants';
import { fetchPlanList } from '../../medical-plans/medicalPlansServices';
import {
  getApplicableMetalTiers,
  getCurrentCoverageMedicalPlan,
  getFormattedApplicantDetails,
  setDomesticPartnerAsSpouse
} from '../../medical-plans/medicalPlansUtils';
import { DEFAULT_VISION_CURRENT_COVERAGE } from '../../vision-plans/constants';
import { getCurrentCoverageVisionPlan } from '../../vision-plans/visionPlansUtils';

import { ALPHA_NUMERIC_REGX, INPUT_TYPES, LOGIN_RESPONSE_CODES } from './constants';
import { getMemberInfo } from './loginServices';

var demographicChanged: boolean = false;

const validateUserName = (userName: string) => ALPHA_NUMERIC_REGX.test(userName);

const validatePassword = (password: string) => ALPHA_NUMERIC_REGX.test(password);

const getErrorMessage = (pageContent, inputType = INPUT_TYPES.USERNAME, value = '') => {
  switch (inputType) {
    case INPUT_TYPES.USERNAME:
      return pageContent?.INVALID_USER_NAME_1;
    case INPUT_TYPES.PASSWORD:
      return pageContent?.INVALID_PASSWORD_1;
    default:
      return '';
  }
};

const getPayloadForUserLogin = (userName: string = '', password: string = '', role: string) => {
  let saleSource;
  if (useGlobalStore.getState().deepLinkInfo?.refID || useGlobalStore.getState().deepLinkInfo?.call) {
    saleSource = {
      cid: useGlobalStore.getState().deepLinkInfo?.refID,
      cidPhone: useGlobalStore.getState().deepLinkInfo?.call,
      mutable: false,
      quoteSrc: '',
      channel: 'APE-CD'
    };
  }
  const payload = {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    loginRequest: {
      username: userName,
      password: password,
      role: role,
      saleSource: saleSource
    }
  };

  return payload;
};

const getLoginSuccessPayload = (loginData: LoginResponse, userName: string): LoginSuccessPayload => {
  return {
    userName: userName,
    userFirstName: loginData.loginResponse.firstName,
    userLastName: loginData.loginResponse.lastName,
    userEmailAddress: loginData.loginResponse.emailAddress,
    userPhoneNumber: loginData.shopperProfile?.person?.phone?.number ?? '',
    role: loginData.loginResponse.role,
    contacts: loginData.contacts ?? [],
    secretQuestionAnswers: loginData.loginResponse.secretQuestionAnswers,
    pingRiskId: loginData.pingRiskId ?? ''
  };
};

const getErrorObj = (errorKey: string, strings: any) => {
  const errorObj = {
    hasError: true,
    responseCode: errorKey,
    message: ''
  };
  switch (errorKey) {
    case LOGIN_RESPONSE_CODES.ACCOUNT_LOCKED:
      errorObj.message = strings?.ACCOUNT_LOCKED ?? '';
      break;
    case LOGIN_RESPONSE_CODES.ACCOUNT_DISABLED:
      errorObj.message = strings?.ACCOUNT_DISABLED ?? '';
      break;
    case LOGIN_RESPONSE_CODES.NOT_A_SHOPPER_OR_A_MEMBER:
      errorObj.message = strings?.INCORRECT_USERNAME ?? '';
      break;
    case LOGIN_RESPONSE_CODES.AUTHENTICATION_FAILED:
      errorObj.message = strings?.INCORRECT_PASSWORD ?? '';
      break;
    default:
      errorObj.message = `<b>${strings?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${strings?.SYSTEM_EXCEPTION_MESSAGE}`;
      break;
  }

  return errorObj;
};

const getWebAccount = (loginData: LoginResponse) => {
  return {
    webAccountGUID: loginData.loginResponse.webAccountGUID,
    shopperId: loginData.loginResponse.shopperId,
    webAccessId: loginData.loginResponse.webAccessId
  };
};

const getNavigationInfo = async (demographicId: string = '', role: string, demographicDiffCheck: boolean = true) => {
  try {
    const data = await getNavigationDetails(demographicId);
    const pathUrl = PAGE_TO_PATH_NAME[data?.navigation?.lastVisitedPage];
    if (pathUrl && role === USER_ROLES.SHOPPER) {
      return pathUrl;
    } else {
      return role === USER_ROLES.SHOPPER ? NAV_PATHS.HOME : demographicDiffCheck ? (pathUrl ? pathUrl : NAV_PATHS.BASICS_LANDING) : NAV_PATHS.BASICS_LANDING;
    }
  } catch (error) {
    return role === USER_ROLES.SHOPPER ? NAV_PATHS.HOME : NAV_PATHS.BASICS_LANDING;
  }
};

const fetchMemberInfo = async (webGUID: string = ''): Promise<MemberInfo> => {
  try {
    const data = await getMemberInfo(webGUID);
    const memberInfoResponse: MemberInfoResponse = data.data;
    if (memberInfoResponse.status === STATUSES.SUCCESS && LOGIN_RESPONSE_CODES.MEMBER_INFO_SUCCESS.includes(memberInfoResponse.responseMessage.responseCode)) {
      return memberInfoResponse.member;
    } else {
      return initialMemberInfo;
    }
  } catch (error) {
    return initialMemberInfo;
  }
};

const generatePlanDetailsPayload = (demographicInfo: DemographicInfo = {}, contract: string) => {
  const planYear = demographicInfo?.coverageEffectiveDate?.split('-')[0];
  const payload: GetPlanDetailsPayload = {
    zipCode: demographicInfo.zipCode ?? '',
    county: demographicInfo.county ?? '',
    countyCode: demographicInfo?.countyCode ?? '',
    state: demographicInfo?.state ?? '',
    coverageEffectiveDate: demographicInfo?.coverageEffectiveDate ?? '',
    marketSegment: demographicInfo?.marketSegment ?? '',
    planYear: planYear ?? '',
    contractCode: contract,
    rateRequest: YES,
    applicant: demographicInfo.applicant ?? []
  };
  return payload;
};

const getAllPlanDetailsPayload = (
  demographicInfo: DemographicInfo,
  lcontarctCode: string,
  planType: string,
  subsidyresponse: Subsidy,
  applicant: Applicant[]
): PlanSummaryPayload => {
  const planYear = demographicInfo?.coverageEffectiveDate?.split('-')[0];
  const applicantInfo: Applicant[] = getApplicantInfo(demographicInfo?.applicant || [], applicant);
  let payload: PlanSummaryPayload = {
    brand: demographicInfo.brand ?? '',
    planTypes: [planType],
    metalTierTypes: planType === PLAN_TYPES_NAMES.MEDICAL ? getApplicableMetalTiers(getFormattedApplicantDetails(applicantInfo ?? [])) : PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: demographicInfo.zipCode ?? '',
    county: demographicInfo.county ?? '',
    state: demographicInfo.state ?? '',
    coverageEffectiveDate: formatDateToYYYYMMDD(demographicInfo?.coverageEffectiveDate ?? ''), //Update the format
    planYear: planYear ?? '',
    applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(applicantInfo ?? [], false)),
    subsidy: {
      subsidyAmt: planType === PLAN_TYPES_NAMES.MEDICAL ? (subsidyresponse?.subsidyAmount?.toString() ?? '0') : null,
      costShareReduction: planType === PLAN_TYPES_NAMES.MEDICAL ? (Number(subsidyresponse?.costShareReductionValue) ?? 0) : null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: demographicInfo.countyCode ?? '',
    contractList: {
      contractBundle: [
        {
          contractCode: lcontarctCode
        }
      ]
    }
  };

  return payload;
};

const getApplicantInfo = (demographicInfoApplicants: Applicant[], selectedApplicants: Applicant[]): Applicant[] => {
  let matchedApplicants: Applicant[] = [];
  if (selectedApplicants.length > 0) {
    demographicInfoApplicants?.map((app) => {
      selectedApplicants.map((applicant) => {
        if (app.applicantId === applicant.applicantId) {
          matchedApplicants.push(app);
        }
      });
    });
  } else {
    demographicInfoApplicants?.map((app) => {
      matchedApplicants.push(app);
    });
  }
  return matchedApplicants;
};

const loadPlans = async (payload: PlanSummaryPayload): Promise<Contract | null> => {
  return fetchPlanList(payload)
    .then((response) => {
      const plandata: PlanSummaryResponse = response.data;
      const plan = plandata.planSummaryResponse.plans.contract[0];

      return plan;
    })
    .catch((error) => {
      console.warn('loginUtils.ts getSelectedPlans error :', error);
      return null;
    });
};

const getSelectedPlans = async (data: ValidateOTPResponse | LoginResponse): Promise<SelectedAllPlans> => {
  let selectedPlans: SelectedAllPlans = {
    selectedMedicalPlan: {
      plan: null,
      applicants: getFormattedApplicantDetails(useGlobalStore.getState().applicantFormDetails),
      matchedProviders: [],
      matchedMedications: []
    },
    selectedDentalPlan: {
      plan: null,
      applicants: getFormattedApplicantDetails(useGlobalStore.getState().applicantFormDetails),
      matchedProviders: [],
      matchedMedications: []
    },
    selectedVisionPlan: {
      plan: null,
      applicants: getFormattedApplicantDetails(useGlobalStore.getState().applicantFormDetails),
      matchedProviders: [],
      matchedMedications: []
    }
  };
  const quote = data?.shopperProfile?.quotes?.[0];

  if (quote?.quoteLineItem?.length ?? 0 > 0) {
    const promises: Promise<void>[] = quote!.quoteLineItem!.map(async (quoteLineItem: QuoteLineItem) => {
      const payload: PlanSummaryPayload = getAllPlanDetailsPayload(
        data.shopperProfile?.demographicInfo!,
        quoteLineItem?.contract?.contractCode,
        quoteLineItem?.contract.planType,
        data.shopperProfile?.demographicInfo?.subsidy!,
        quoteLineItem?.applicant
      );

      const plan = await loadPlans(payload);
      let idx = 0;
      if (plan) {
        if (plan.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL) {
          idx = 1;
          selectedPlans.selectedMedicalPlan.plan = plan;
          selectedPlans.selectedMedicalPlan.applicants = getApplicantInfo(data.shopperProfile?.demographicInfo?.applicant!, quoteLineItem?.applicant);
        } else if (plan.plan[0].planType === PLAN_TYPES_NAMES.DENTAL) {
          idx = 2;
          selectedPlans.selectedDentalPlan.plan = plan;
          selectedPlans.selectedDentalPlan.applicants = getApplicantInfo(data.shopperProfile?.demographicInfo?.applicant!, quoteLineItem?.applicant);
        } else if (plan.plan[0].planType === PLAN_TYPES_NAMES.VISION) {
          idx = 3;
          selectedPlans.selectedVisionPlan.plan = plan;
          selectedPlans.selectedVisionPlan.applicants = getApplicantInfo(data.shopperProfile?.demographicInfo?.applicant!, quoteLineItem?.applicant);
        }
        if (idx < 3 && idx > 1 && !selectedPlans.selectedVisionPlan.applicants) {
          selectedPlans.selectedVisionPlan.applicants = getApplicantInfo(data.shopperProfile?.demographicInfo?.applicant!, quoteLineItem?.applicant);
        } else if (idx < 2 && !selectedPlans.selectedDentalPlan.applicants) {
          selectedPlans.selectedDentalPlan.applicants = getApplicantInfo(data.shopperProfile?.demographicInfo?.applicant!, quoteLineItem?.applicant);
        }
      }
    });

    await Promise.all(promises);
    return selectedPlans;
  } else {
    return selectedPlans;
  }
};

const getSelectedFavouritePlans = (plans: Favourites[]): Promise<SelectedFavouritePlans> => {
  let selectedFavPlans: SelectedFavouritePlans = {
    medicalPlans: [] as string[],
    dentalPlans: [] as string[],
    visionPlans: [] as string[]
  };
  plans[0].quoteLineItem.map((quoteLineItem: QuoteLineItem) => {
    switch (quoteLineItem?.contract?.planType) {
      case PLAN_TYPES.MEDICAL:
        selectedFavPlans.medicalPlans.push(quoteLineItem?.contract?.contractCode as string);
        break;
      case PLAN_TYPES.DENTAL:
        selectedFavPlans.dentalPlans.push(quoteLineItem?.contract?.contractCode as string);
        break;
      case PLAN_TYPES.VISION:
        selectedFavPlans.visionPlans.push(quoteLineItem?.contract?.contractCode as string);
        break;
    }
  });
  return Promise.resolve(selectedFavPlans);
};

const getConflictInfo = async (locationPath: string, PlanInfo: SelectedPlan) => {
  if (!NON_CONFLICT_SCREENS.includes(locationPath) || PlanInfo?.plan?.contractCode) {
    return true;
  } else {
    return false;
  }
};

const loadApplications = async (webAccountGUID: string): Promise<Application[]> => {
  const payload: getApplicationListingsPayload = getDashboardApplicationListingsPayload(
    webAccountGUID,
    USER_DOMAINS.APP,
    PAYLOAD_INPUT.PARTNETID,
    PAYLOAD_INPUT.NOOFAPPLICATIONPERPAGE,
    PAYLOAD_INPUT.PAGENUMBER
  );

  return getDashboardApplications(payload)
    .then((response) => {
      const data: getApplicationListingsResponse = response.data;
      const applications: Application[] = data.response.applications;

      useGlobalStore.getState().updateApplications(applications);
      return applications;
    })
    .catch((error) => {
      console.warn('loginUtils.ts getApplicationConflictInfo error :', error);

      return [];
    });
};

const getInProgressApplication = async (webAccountGUID: string): Promise<Application | null> => {
  const applications = await loadApplications(webAccountGUID);
  const inProgressApplication = applications.find((application) => IN_PROGRESS_APPLICATION_STATUSES.includes(application.applicationStatus));

  return inProgressApplication ?? null;
};

const handleCurrentCoverages = (currentCoverages: CurrentCoverages) => {
  if (currentCoverages) {
    useGlobalStore.getState().updateMemberPlanTypes(currentCoverages);
    const currentCoverageMedicalPlan = getCurrentCoverageMedicalPlan(currentCoverages);
    const currentCoverageDentalPlan = getCurrentCoverageDentalPlan(currentCoverages);
    const currentCoverageVisionPlan = getCurrentCoverageVisionPlan(currentCoverages);

    if (currentCoverageMedicalPlan?.contractCode) {
      const formattedCurrentCoverageMedicalPlan = getFormattedCurrentCoveragePlan(DEFAULT_MEDICAL_CURRENT_COVERAGE, currentCoverageMedicalPlan);
      useGlobalStore.getState().updateCurrentCoverageMedicalPlan(formattedCurrentCoverageMedicalPlan, currentCoverageMedicalPlan?.mappedContractCode ?? '');
    }
    if (currentCoverageDentalPlan?.contractCode) {
      const formattedCurrentCoverageDentalPlan = getFormattedCurrentCoveragePlan(DEFAULT_DENTAL_CURRENT_COVERAGE, currentCoverageDentalPlan);
      useGlobalStore.getState().updateCurrentCoverageDentalPlan(formattedCurrentCoverageDentalPlan, currentCoverageDentalPlan?.mappedContractCode ?? '');
    }
    if (currentCoverageVisionPlan?.contractCode) {
      const formattedCurrentCoverageVisionPlan = getFormattedCurrentCoveragePlan(DEFAULT_VISION_CURRENT_COVERAGE, currentCoverageVisionPlan);
      useGlobalStore.getState().updateCurrentCoverageVisionPlan(formattedCurrentCoverageVisionPlan, currentCoverageVisionPlan?.mappedContractCode ?? '');
    }
  }
};

const processDemographicInfo = async (demographicInfo: DemographicInfo, noDemographicDataInLogin?: boolean) => {
  // this function is to pre-populate the demographic info from login response post login
  const globalStore = useGlobalStore.getState();
  const response = await getZipcodeValidation({ zipCode: demographicInfo?.zipCode || '', marketSegment: USER_DOMAINS.MARKET_SEGMENT });
  const planYear = demographicInfo?.coverageEffectiveDate
    ? getPlanYearForLogin(demographicInfo?.coverageEffectiveDate)
    : getPlanYear(response.data.zipCodeResponse?.zipCode?.coverageEffectiveDate?.coverageDt, demographicInfo?.coverageTypeFlag || '');
  let county = demographicInfo?.county;
  if (noDemographicDataInLogin && !county) {
    county = response.data.zipCodeResponse?.zipCode?.countyList?.county[0]?.countyName;
  }
  useUserStore.getState().updateDemographicInfo(demographicInfo);
  globalStore.updateZipCodeResponse(response.data?.zipCodeResponse?.zipCode);
  globalStore.updateZipCodeFormDetails(demographicInfo?.zipCode || '', county || '', planYear);
  globalStore.updatePlanTypes(county || '', planYear);
  globalStore.updateCoverageType(demographicInfo?.coverageTypeFlag || '');
  globalStore.updateProgressSteps(getProgressStepsConfig(demographicInfo?.coverageTypeFlag, useUserStore.getState().isWellpoint));
  globalStore.updateApplicantFormDetails(demographicInfo?.applicant || []);
  globalStore.updateEditZipCodeRes(response.data?.zipCodeResponse?.zipCode);
  globalStore.updateEditZipCodeFormDetails(demographicInfo?.zipCode || '', county || '', planYear);
  globalStore.updateEditPlanTypes(county || '', planYear);
  globalStore.updateEditCoverageType(demographicInfo?.coverageTypeFlag || '');

  const SubsidyData = {
    dependents:
      Number(demographicInfo?.subsidy?.houseHoldSize) > 0
        ? Number(demographicInfo?.subsidy?.houseHoldSize) - (Array.isArray(demographicInfo?.applicant) ? demographicInfo?.applicant?.length : 0)
        : 0,
    houseHoldIncome: demographicInfo?.subsidy?.houseHoldIncome || 0
  };

  demographicInfo?.subsidy?.subsidyAmount && demographicInfo?.subsidy?.subsidyAmount > 0 && globalStore.updateSelectedTab(MEDICAL_PLANS_TABS.MARKET_PLANS);
  demographicInfo?.subsidy?.subsidyAmount &&
    demographicInfo?.subsidy?.subsidyAmount > 0 &&
    globalStore.updateSelectedOptionInEstimateSavings(MEDICAL_PLANS_TABS.MARKET_PLANS);
  demographicInfo?.subsidy?.subsidyAmount && demographicInfo?.subsidy?.subsidyAmount > 0 && globalStore.updateIsSubsidydataApplied(true);
  globalStore.updateHouseholdMemberValues(SubsidyData);
  Number(demographicInfo?.subsidy?.subsidyAmount) > 0 &&
    (await subsidyCalculator(
      SubsidyData,
      demographicInfo.zipCode,
      demographicInfo.countyCode,
      demographicInfo.coverageEffectiveDate,
      demographicInfo.applicant
    ).then((response) => {
      globalStore.updateEstimateSavingsResponse(response.data);
    }));

  if (demographicInfo?.savedUserEntity?.length) {
    let drugsList: any = [];
    let hmcFilters: HmcFilter = { questionaires: [] };
    demographicInfo?.savedUserEntity.map((entity) => {
      if (entity.entityType === ENTITY_TYPES.DRUG) {
        drugsList.push({
          formularyId: entity.entityId,
          drugLabel: entity.entityLabel,
          genericProductId: entity.compositeEntityId,
          nationalDrugCode: null,
          drugType: entity.entitySubType,
          isGenericOptionsAvailable: entity.isGenericOptAvail === YES_NO_VALUES.YES ? BOOLEAN_VALUES.TRUE.toUpperCase() : BOOLEAN_VALUES.FALSE.toUpperCase()
        });
      }
      if (entity.entityType === ENTITY_TYPES.HMC) {
        hmcFilters.questionaires.push({
          code: entity.entityId,
          value: entity.compositeEntityId
        });
      }
    });
    drugsList.length && globalStore.updatedDrugsList(drugsList);
    drugsList.length && globalStore.updateMatchMedicationsToPlansStatus(true);
    hmcFilters.questionaires.length && globalStore.updateHmcFilter(hmcFilters);
  }
};

const populateUserDetailsWithoutDemographic = async (data: ValidateOTPResponse | LoginResponse, sharedDemographicInfo: DemographicInfo) => {
  try {
    // this function is to pre-populate the Quote details from login response post login for quote shared via email but ignores demographic info
    const globalStore = useGlobalStore.getState();
    data.shopperProfile?.agentConnect && globalStore.updateAgentInfo(data.shopperProfile.agentConnect);
    //If logged in user is a member get and update member info
    if (data?.loginResponse?.role === USER_ROLES.MEMBER) {
      const memeberInfo = await fetchMemberInfo(data?.loginResponse?.webAccountGUID);
      if (memeberInfo.cmcEligible === SET_APPLICATION_CONSTANTS.YES) {
        globalStore.updateMemberInfo(memeberInfo);
        memeberInfo.demographicInfo && !sharedDemographicInfo && (await processDemographicInfo(memeberInfo.demographicInfo)); //check with Jesweer
        const sortedPlanTypes = memeberInfo.currentCoverages.sort((a, b) => PLAN_TYPES_ORDER[a.planType] - PLAN_TYPES_ORDER[b.planType]);
        memeberInfo.demographicInfo &&
          !sharedDemographicInfo &&
          globalStore.updateCoverageType(memeberInfo.currentCoverages.length > 0 ? PLAN_TYPES[sortedPlanTypes[0].planType] : '');
        handleCurrentCoverages(memeberInfo.currentCoverages);
        checkIfBasicDetailsmatch(sharedDemographicInfo, memeberInfo.demographicInfo);
      } else {
        useUserStore.getState().updateRole(USER_ROLES.SHOPPER);
        useUserStore.getState().updateShowCreateProfileFlag(true);
      }
    }
  } catch (error) {
    console.error('StandaloneLoginUtils.ts populateUserDetailsWithoutDemographic error :', error);
    throw error;
  }
};

const populateUserDetails = async (data: ValidateOTPResponse | LoginResponse) => {
  try {
    // this function is to pre-populate the Quote details from login response post login
    const globalStore = useGlobalStore.getState();
    data.shopperProfile?.agentConnect && globalStore.updateAgentInfo(data.shopperProfile.agentConnect);
    //If logged in user is a member get and update member info
    if (data?.loginResponse?.role === USER_ROLES.MEMBER) {
      const memeberInfo = await fetchMemberInfo(data?.loginResponse?.webAccountGUID);
      if (memeberInfo.cmcEligible === SET_APPLICATION_CONSTANTS.YES) {
        globalStore.updateMemberInfo(memeberInfo);
        memeberInfo.demographicInfo &&
          !data.shopperProfile?.demographicInfo &&
          (await processDemographicInfo(memeberInfo.demographicInfo, data?.shopperProfile?.demographicInfo ? false : true));
        const sortedPlanTypes = memeberInfo.currentCoverages.sort((a, b) => PLAN_TYPES_ORDER[a.planType] - PLAN_TYPES_ORDER[b.planType]);
        memeberInfo.demographicInfo &&
          !data.shopperProfile?.demographicInfo &&
          globalStore.updateCoverageType(memeberInfo.currentCoverages.length > 0 ? PLAN_TYPES[sortedPlanTypes[0].planType] : '');
        handleCurrentCoverages(memeberInfo.currentCoverages);
        checkIfBasicDetailsmatch(data.shopperProfile?.demographicInfo, memeberInfo.demographicInfo);
      } else {
        useUserStore.getState().updateRole(USER_ROLES.SHOPPER);
        useUserStore.getState().updateShowCreateProfileFlag(true);
      }
    }

    if (data?.shopperProfile?.demographicInfo !== null) {
      useUserStore.getState().updateLoginStatus(true);
      data?.shopperProfile?.demographicInfo && (await processDemographicInfo(data?.shopperProfile?.demographicInfo));
      if (data.loginResponse.role === USER_ROLES.MEMBER) {
        globalStore.memberInfo?.demographicInfo &&
          !useGlobalStore.getState().applicantFormDetails.length &&
          (await processDemographicInfo(globalStore.memberInfo?.demographicInfo));
      }
      const quotes = data.shopperProfile?.quotes || [];

      useUserStore.getState().updateQuotes(quotes);
      const selectedPlans = await getSelectedPlans(data);
      selectedPlans.selectedMedicalPlan && globalStore.updateSelectedPlan(selectedPlans.selectedMedicalPlan);
      selectedPlans.selectedDentalPlan && globalStore.updateSelectedDentalPlan(selectedPlans.selectedDentalPlan);
      selectedPlans.selectedVisionPlan && globalStore.updateSelectedVisionPlan(selectedPlans.selectedVisionPlan);

      const favourites = data.shopperProfile?.favourites || [];
      if (favourites[0]?.quoteLineItem.length) {
        useUserStore.getState().updateFavourites(favourites);
        const favouritePlans = await getSelectedFavouritePlans(favourites);
        globalStore.updateFavouriteMedicalPlans(favouritePlans.medicalPlans);
        globalStore.updateFavouriteDentalPlans(favouritePlans.dentalPlans);
        globalStore.updateFavouriteVisionPlans(favouritePlans.visionPlans);
      }
    } else {
      useUserStore.getState().updateLoginStatus(true);
    }
  } catch (error) {
    throw error;
  }
};

const checkIfBasicDetailsmatch = (loginDemographics, memberDemographics) => {
  const keysToExclude = [
    'firstName',
    'email',
    'disabled',
    'isTabaccoUser',
    'applicantId',
    'address',
    'isPolicyApplicant',
    'gender',
    'phoneNumber',
    'phoneType',
    'smsPermission'
  ];
  if (
    loginDemographics &&
    loginDemographics?.zipCode &&
    loginDemographics?.state &&
    memberDemographics?.zipCode &&
    memberDemographics?.state &&
    loginDemographics?.applicant &&
    memberDemographics?.applicant &&
    loginDemographics?.zipCode === memberDemographics?.zipCode &&
    loginDemographics?.state === memberDemographics?.state &&
    compareTwoObjects(loginDemographics.applicant, memberDemographics.applicant, keysToExclude)
  ) {
    demographicChanged = true;
  } else {
    demographicChanged = false;
  }
};

const getDemographicChanged = () => {
  return demographicChanged;
};

const getProgressStepsConfig = (coverageTypeFlag: string | undefined, isWellpoint: boolean): ProgressStep[] => {
  if (coverageTypeFlag === PLAN_TYPES.MEDICAL) {
    return isWellpoint ? useGlobalStore.getState().medicalSteps : useGlobalStore.getState().medicalDentalVisionSteps;
  } else if (coverageTypeFlag === PLAN_TYPES.DENTAL) {
    return useGlobalStore.getState().dentalSteps;
  } else if (coverageTypeFlag === PLAN_TYPES.VISION) {
    return useGlobalStore.getState().visionSteps;
  } else {
    return useGlobalStore.getState().medicalDentalVisionSteps;
  }
};
const getAgentConnectPayload = (agentInfo: AgentConnect) => {
  const agentConnectPayload: SaveShopperAgentConnect = {
    agentConnect: {
      parentTin: agentInfo?.parentTin ?? '',
      agentType: agentInfo?.agentType ?? '',
      licenseNO: agentInfo?.licenseNo ?? '',
      phone: agentInfo?.phone ?? '',
      agencyName: agentInfo?.agencyName ?? '',
      brand: agentInfo?.brand ?? '',
      faxExt: agentInfo?.faxExt ?? '',
      agentId: agentInfo?.agentId ?? '',
      lastName: agentInfo?.lastName ?? '',
      npn: agentInfo?.npn ?? '',
      email: agentInfo?.email ?? '',
      agentCode: agentInfo?.agentCode ?? '',
      fax: agentInfo?.fax ?? '',
      firstName: agentInfo?.firstName ?? '',
      paidTIN: agentInfo?.paidTIN ?? '',
      phoneext: agentInfo?.phoneext ?? '',
      lobState: agentInfo?.lobState ?? '',
      address: agentInfo?.address ?? {
        addressLine1: '',
        city: agentInfo.city ?? '',
        state: agentInfo.state ?? '',
        zip: agentInfo.zip ?? ''
      },

      campaign: {
        cid: ''
      },
      shopperId: useUserStore.getState().shopperId ?? '',
      webAcctId: useUserStore.getState().webAccessId ?? '',
      userId: useUserStore.getState().webAccountGUID ?? ''
    }
  };
  return agentConnectPayload;
};

const validateAgentConflict = (currentAgent: AgentConnect, previousAgent: AgentConnect | AgentOfRecord): boolean => {
  if (
    (currentAgent?.firstName || currentAgent?.lastName) &&
    (previousAgent?.firstName || previousAgent?.lastName) &&
    currentAgent?.firstName !== previousAgent?.firstName &&
    currentAgent?.lastName !== previousAgent?.lastName
  ) {
    return true;
  } else if (currentAgent?.agencyName && previousAgent?.agencyName && currentAgent?.agencyName !== previousAgent?.agencyName) {
    return true;
  } else {
    return false;
  }
};
export {
  fetchMemberInfo,
  getAgentConnectPayload,
  getApplicantInfo,
  getConflictInfo,
  getDemographicChanged,
  getErrorMessage,
  getErrorObj,
  getInProgressApplication,
  getLoginSuccessPayload,
  getNavigationInfo,
  getPayloadForUserLogin,
  getSelectedFavouritePlans,
  getSelectedPlans,
  getWebAccount,
  loadPlans,
  populateUserDetails,
  populateUserDetailsWithoutDemographic,
  processDemographicInfo,
  validateAgentConflict,
  validatePassword,
  validateUserName
};
