import { Modal, Tooltip } from '@sydney-broker-ui/ios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LoginModal from '../../../pages/public/login/login-modal/LoginModal';
import TwoFAModalBody from '../../../pages/public/two-fa-modal/TwoFAModalBody';
import LogoutPopup from '../../../pages/secure/logout/LogoutPopup';
import AgentDetailsModal from '../agent-details-modal/AgentDetailsModal';
import ContactUs from './contact-us/ContactUs';
import MobileMenu from './mobile-menu/MobileMenu';

import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { getAgentName, isEmpty } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

import { MAC_APPOINTMENT_TOOL_URL } from '../../../api/api-name-list';
import menu from '../../../images/mobile-menu.svg';
import { TWO_FA_USE_CASES } from '../../../pages/public/two-fa-modal/constants';
import { BRAND_NAMES, COVERAGE_PERIOD, LANGUAGE_CODES, NAV_PATHS, PTB_CONTACT_US_URL, SCREEN_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { LANGUAGE_NAME } from '../global-footer/GlobalFooterConstants';
import './GlobalHeader.scss';
import { default as GlobalHeaderContent } from './globalHeaderContent.json';
import { default as stateBasedDecision } from './stateBasedQuoteApply.json';
const { REACT_APP_ENV } = process.env;

function GlobalHeader({ showOnlyLogo = false, notClickable = false }: GlobalHeaderProps) {
  let headerContent = GlobalHeaderContent.data.iospagecontentList.items[0].pageContent.GlobalHeader;

  const navigate = useNavigate();
  const location = useLocation();

  const [showSlider, setShowSlider] = useState(false);
  const [showMobileSlider, setShowMobileSlider] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [contactPhone, setContactPhone] = useState('');
  const [showAgentDetails, setShowAgentDetails] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30);
  const [pageContentStateBased, setPageContentStateBased] = useState<any>(null);
  const [logo, setLogo] = useState('');
  const userStore = useUserStore(state => state);

  const { zipCodeRes, agentBrand, agentInfo, deepLinkInfo, validState, state, isPublicAgentConnect, zipcode, triggerLoginModal, agentSSOInfo, isPlanSummaryEditable, coverageRule, updateTriggerLoginModal, brand, updateStateCofig, updateEnrollmentYear, updateGlobalEnrollmentPeriods, updateDeepLinkCall, updateDeepLinkRefID } = useGlobalStore(state => state);
  const { loggedIn, isWellpoint, showCreateProfileFlag, showLoginButtonFlag, role, isPHILogin } = useUserStore((state) => state);
  const { isAtk } = agentSSOInfo

  const agentName = getAgentName(agentInfo ?? {} as AgentOfRecord, deepLinkInfo.agentConnectData, isPublicAgentConnect);

  const getContent = useCallback(async (): Promise<boolean> => {
    let cmsResponse;
    let configContent;
    let enrollmentYear;
    let globalEnrollmentPeriods;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.GLOBAL_HEADER);
      cmsResponse = response.data;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.GlobalHeader);
      const configResponse = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.STATE_BASED_CONFIG);
      configContent = configResponse.data.data.iospagecontentList.items[0].pageContent.coveragePeriod;
      enrollmentYear = configResponse.data.data.iospagecontentList.items[0].pageContent.ENROLLMENT_YEAR;
      globalEnrollmentPeriods = configResponse.data.data.iospagecontentList.items[0].pageContent.GLOBAL_ENROLLMENT_PERIOD;
    } catch (error) {
      configContent = stateBasedDecision.coveragePeriod;
      enrollmentYear = stateBasedDecision.ENROLLMENT_YEAR;
      globalEnrollmentPeriods = stateBasedDecision.GLOBAL_ENROLLMENT_PERIOD;
      setContent(headerContent);
      if (isWellpoint) {
        setLogo(headerContent?.WELLPOINT_LOGO)
      } else {
        setLogo(headerContent?.ABCBS_LOGO);
      }
      return false;
    } finally {
      setLogo(cmsResponse?.data?.iospagecontentList?.items[0]?.pageContent?.GlobalHeader?.ABCBS_LOGO);
      updateStateCofig(configContent);
      updateEnrollmentYear(enrollmentYear);
      updateGlobalEnrollmentPeriods(globalEnrollmentPeriods);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const onChangeShowModal = (e) => {
    if (!loggedIn) {
      updateTriggerLoginModal(true);
    }
    else {
      setShowLogoutPopup(true);
    }
  };

  const handleModalClose = (e) => {
    updateTriggerLoginModal(false);
  };

  const handleTwoFAModalClose = () => {
    setShowTwoFAModal(false);
  };

  const onChangeShowSlider = (e) => {
    setShowSlider(true);
  };
  const onChangeShowMobileSlider = (e) => {
    setShowMobileSlider(true);
  };
  const handleChildChange = useCallback((e) => {
    setShowSlider(e);
  }, []);

  const handleMobileChildChange = useCallback((e) => {
    setShowMobileSlider(false);
    if (e.target?.id === 'log-in') {
      onChangeShowModal(true);
    } else if (e.target?.id === 'contact-us') {
      onChangeShowSlider(true);
    }
  }, []);

  const handleContactSubmit = (data) => {
    // console.log(data);
  };

  const getContactPhone = (state, refSource, role) => {
    if (pageContent !== null && pageContent !== undefined && !isEmpty(pageContent)) {
      try {
        const contact_states = Object.keys(pageContent?.CONTACT_US_PHONE);
        //set default contact starts. Don't update in deeplinkStore if refsource is not there else it will send to healthSherpa
        if (deepLinkInfo.call) {
          setContactPhone(deepLinkInfo.call);
        }
        else if (brand === BRAND_NAMES.WELLPOINT_BRAND) {
          setContactPhone(pageContent?.CONTACT_US_PHONE.DEFAULT_WLP?.default);
        }
        else {
          setContactPhone(pageContent?.CONTACT_US_PHONE.DEFAULT.default);
        }
        //set default contact ends

        if (refSource && !deepLinkInfo.refID) {
          if (deepLinkInfo.state && contact_states.includes(deepLinkInfo.state)) {
            updateDeepLinkCall(pageContent?.CONTACT_US_PHONE[deepLinkInfo.state][refSource]['phone']);
            updateDeepLinkRefID(pageContent?.CONTACT_US_PHONE[deepLinkInfo.state][refSource]['refid']);
          }
          else if (brand === BRAND_NAMES.WELLPOINT_BRAND) {
            if (deepLinkInfo.state === '') {
              updateDeepLinkCall(pageContent?.CONTACT_US_PHONE['DEFAULT_WLP'][refSource]);
            }
            else {
              updateDeepLinkCall(pageContent?.CONTACT_US_PHONE.DEFAULT_WLP?.default);
            }
          }
          else if (brand !== BRAND_NAMES.WELLPOINT_BRAND) {
            if (deepLinkInfo.state === '') {
              updateDeepLinkCall(pageContent?.CONTACT_US_PHONE['DEFAULT'][refSource]);
            }
            else {
              updateDeepLinkCall(pageContent?.CONTACT_US_PHONE.DEFAULT?.default);
            }
          }
          setContactPhone(deepLinkInfo.call);
        }
        else if (state && contact_states.includes(state) && refSource === '' && role !== '') {
          setContactPhone(pageContent?.CONTACT_US_PHONE[state]['default'][role.toLowerCase()]);
        }
      }
      catch (error) {
        setContactPhone(pageContent?.CONTACT_US_PHONE.DEFAULT.default);
      }
    }
  }

  const showProfile = () => {
    if (loggedIn) {
      navigate(NAV_PATHS.UPDATE_PROFILE);
    } else {
      navigate(NAV_PATHS.REGISTRATION);
    }
  };

  const handleLogoUpdate = () => {
    if (agentBrand === BRAND_NAMES.ABC || brand === BRAND_NAMES.ABC || deepLinkInfo.brand === BRAND_NAMES.ABC) {
      setLogo(pageContent?.ABC_LOGO);
    } else if (isWellpoint) {
      setLogo(pageContent?.WELLPOINT_LOGO);
    } else {
      setLogo(pageContent?.ABCBS_LOGO);
    }
  }

  const initiate = async () => {
    await getContent();
  }

  useEffect(() => {
    initiate();
    if (loggedIn) {
      updateTriggerLoginModal(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === NAV_PATHS.BASICS_LANDING) {
      handleLogoUpdate();
    }
  }, [zipcode]);

  useEffect(() => {
    if (location.pathname === NAV_PATHS.HOME || location.pathname === NAV_PATHS.INDIVIDUALS) {
      handleLogoUpdate();
    }
  }, [brand]);

  useEffect(() => {
    handleLogoUpdate();
  }, [agentBrand]);

  useEffect(() => {
    if (contentLoaded) {
      handleLogoUpdate();
    }
  }, [contentLoaded]);

  useEffect(() => {
    getContactPhone(validState, deepLinkInfo.refSource, role);
  }, [deepLinkInfo.refSource, role, pageContent, validState, deepLinkInfo.call]);

  useEffect(() => {
    if (showLogoutPopup) {
      const timeoutId = setTimeout(() => {
        setShowLogoutPopup(false);
      }, 30000);
      const intervalId = setInterval(() => {
        setRemainingTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
    else {
      setRemainingTime(30);
    }
  }, [showLogoutPopup]);

  const handleLogoClick = () => {
    if ((loggedIn || isPHILogin) && role === USER_ROLES.MEMBER) {
      navigate(NAV_PATHS.BASICS_LANDING);
    } else {
      navigate(NAV_PATHS.HOME)
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (userStore?.loggedIn && (location.pathname !== NAV_PATHS.PLAN_SUMMARY && location.pathname !== NAV_PATHS.DASHBOARD && location.pathname !== NAV_PATHS.PLAN_RECOMMENDATIONS)) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location.pathname]);

  const renderPTBText = () => {
    if (isAtk) {
      return (
        <div className="dotted-line">
          <p className='ptb-text'>{pageContent?.PRODUCER_TOOLBOX}</p>
        </div>
      )
    }
  }

  const handleContactUsClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isAtk) {
      window.open(PTB_CONTACT_US_URL, '_blank')
    } else {
      onChangeShowSlider(e);
    }
  }

  const showSpanishButtonLink = () => {
    const today = dayjs().toISOString();

    const coverageRuleObj = coverageRule.find(period => period.period === COVERAGE_PERIOD.OE);

    return coverageRuleObj ? today >= dayjs(coverageRuleObj?.startDate).toISOString() : false;
  };

  const renderSpanishButton = () => {
    if (showSpanishButtonLink()) {
      return (
        <a href='#'><span className={`${isWellpoint ? "wellpoint-spanish-buttton" : "spanish-buttton"}`}>{LANGUAGE_NAME?.SPANISH}</span></a>
      )
    } else {
      return (
        <Tooltip message={pageContent?.SPANISH_BUTTON_TOOLTIP_TEXT}
          labelAriaTipTerm={'Help for Spanish'} >
          <span className={`${isWellpoint ? "wellpoint-spanish-buttton" : "spanish-buttton"}`} title={pageContent?.SPANISH_BUTTON_TOOLTIP_TEXT}>{LANGUAGE_NAME?.SPANISH}
          </span></Tooltip>
      )
    }
  }

  return (
    <nav>
      <div className="mdg-bento-menu">
        <div className="fwc-container">
          {!contentLoaded ? (<div></div>)
            :
            (<>
              <div className={`fwc-row ${isAtk ? 'atk-header' : ''}`}>
                <a href="javascript:void(0)" className={`img-cont ${notClickable || isAtk ? 'no-pointer' : ''}`} onClick={notClickable || isAtk ? undefined : handleLogoClick} aria-label={'Logo Navigate Home'}>
                  <img src={logo} className={isWellpoint ? 'wellpointlogo' : 'logo'} id={'brand-logo'} alt="BrandLogo" data-analytics={'brandLogoTopNavIos'} />
                </a>
                {renderPTBText()}
                {!showOnlyLogo &&
                  <div className="flex-align-right global-nav-links">
                    <div className="fwc-flex j-end a-center content-container">
                      <div className={'fwc-flex a-center'}>
                        <>
                          {((loggedIn && agentName?.trim()) || isPublicAgentConnect) && !isAtk &&
                            <div className='agent-container' >
                              <a
                                href="javascript:void(0)"
                                className="right-xs fwc-flex a-center"
                                id={'agent-info'}
                                onClick={() => {
                                  setShowAgentDetails(true)
                                }}
                              >
                                <div role={'img'} title={'Agent Details'} aria-label={'Agent Details'} className="sae-icon sae-icon-business-contact contact-icons right-xxs" />
                                <span className='agent-label global-nav-link'>{pageContent?.YOUR_AGENT + ': '}</span>
                                <span className='agent-name global-nav-link'>{agentName}</span>
                              </a>
                            </div>
                          }</>
                        <a
                          href="javascript:void(0)"
                          className="right-xs fwc-flex a-center"
                          id={'contact-us'}
                          data-analytics={'tfnLinkTopNavIos'}
                          onClick={(e) => {
                            handleContactUsClick(e)
                          }}
                        >
                          <div role={'img'} title={'Contact Us'} aria-label={'Contact Us'} className="sae-icon sae-icon-phone"></div>
                          <span className={'global-nav-link contact-us-label'}>{isAtk ? pageContent?.CONTACT_US : `${pageContent?.CONTACT_US}`}</span>
                          {!isAtk && <span className={'header-phone-link global-nav-link'}>{contactPhone}</span>}
                        </a>
                        {isPlanSummaryEditable && !showCreateProfileFlag && !isAtk && role !== USER_ROLES.MEMBER && (
                          <a data-analytics={'createProfileLinkTopNavIos'} href="javascript:void(0)" className="left-xxs right-xxs" id={'create-profile'} onClick={() => showProfile()}>
                            <span className="sae-icon sae-icon-contacts right-xxs"></span>{' '}
                            <span className={'global-nav-link'}>{loggedIn ? pageContent?.PROFILE : pageContent?.CREATE_PROFILE}</span>
                          </a>
                        )}
                        {isWellpoint &&
                          <div className="spanish-content-button">
                            {renderSpanishButton()}
                          </div>
                        }
                        {!showLoginButtonFlag && !isAtk && (
                          <a
                            href="javascript:void(0)"
                            className="left-xxs mobile-menu-container"
                            id={'mobile-menu'}
                            onClick={(e) => {
                              onChangeShowMobileSlider(e);
                            }}
                          >
                            <img src={menu} className="mobile-menu-svg" id={'mobile-menu-icon'} alt="MobileMenu" />
                          </a>
                        )}
                        {(!showLoginButtonFlag && !isAtk && !(isPHILogin && !loggedIn)) && (
                          <button
                            className="fwc-btn fwc-btn-primary left-xs global-nav-link"
                            id={'log-in'}
                            data-analytics={`${!loggedIn ? "logInButtonTopNavIos" : "logOutButtonTopNavIos"}`}
                            onClick={(e) => {
                              onChangeShowModal(e);
                            }}
                          >
                            {!loggedIn ? pageContent?.LOG_IN : pageContent?.LOG_OUT}
                          </button>
                        )}
                      </div>
                      <div>
                        <ContactUs
                          contactPhone={deepLinkInfo.call}
                          showSlider={showSlider}
                          onSubmit={handleContactSubmit}
                          onChange={handleChildChange}
                        ></ContactUs>
                        <MobileMenu
                          contactPhone={deepLinkInfo.call}
                          showMobileSlider={showMobileSlider}
                          onLinkClick={handleMobileChildChange}
                          onClose={handleMobileChildChange}
                        ></MobileMenu>
                        <Modal open={triggerLoginModal} onClose={handleModalClose} title={pageContent?.LOGIN_TITLE}>
                          <Modal.Body>
                            <LoginModal
                              showModal={triggerLoginModal}
                              closeLoginModal={() => {
                                updateTriggerLoginModal(false);
                              }}
                              initiateTwoFa={() => {
                                updateTriggerLoginModal(false);
                                setModalHeading(pageContent?.TWO_FA_MODAL_HEADING);
                                setShowTwoFAModal(true);
                              }}
                            />
                          </Modal.Body>
                        </Modal>
                        <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
                          <Modal.Body>
                            <TwoFAModalBody
                              useCase={TWO_FA_USE_CASES.LOGIN}
                              showModal={showTwoFAModal}
                              showFooter={true}
                              closeModal={handleTwoFAModalClose}
                              hideModalHeading={() => setModalHeading('')}
                              updateModalHeading={(heading) => setModalHeading(heading)}
                            />
                          </Modal.Body>
                        </Modal>
                        <Modal open={showAgentDetails} onClose={() => setShowAgentDetails(false)} title={pageContent?.AGENT_DETAILS}>
                          <Modal.Body>
                            <AgentDetailsModal labels={pageContent} onClose={() => setShowAgentDetails(false)} />
                          </Modal.Body>
                        </Modal>
                        {
                          showLogoutPopup && <LogoutPopup showPopup={showLogoutPopup}
                            popupClose={() => setShowLogoutPopup(false)} remainingTime={remainingTime} />
                        }
                      </div>
                    </div>
                  </div>}
              </div>
              {!showOnlyLogo && loggedIn && role === USER_ROLES.MEMBER &&
                <div className="fwc-row">
                  <div className='mac-appointmenttool-container'>
                    <a
                      href={REACT_APP_ENV === "prod1" || REACT_APP_ENV === "prod2" ? MAC_APPOINTMENT_TOOL_URL.PROD : MAC_APPOINTMENT_TOOL_URL.TEST_ENVIRONMENT}
                      className="right-xxs"
                      id={'agent-info'}
                    >{pageContent?.SCHEDULE_AN_APPOINTMENT}</a>
                    <span>{pageContent?.PLAN_ADVISOR}<span><Tooltip icon message={pageContent?.TOOL_TIP_MESSAGE}
                      labelAriaTipTerm={'Help for Deductibles'} /></span></span>
                  </div>
                </div>
              }
            </>
            )}
        </div>
      </div>
    </nav>
  );
}
export default GlobalHeader;
