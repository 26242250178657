import dayjs from 'dayjs';
import {
  PROVIDER_TYPES,
  PROVIDER_TYPES_DENTAL,
  PROVIDER_TYPES_DENTAL_PROVIDER_MAPPING,
  PROVIDER_TYPES_MAPPING,
  PROVIDER_TYPES_VISION,
  PROVIDER_TYPES_VISION_MAPPING
} from '../../../pages/public/find-your-doctor/constants';
import { getApplicantInfo } from '../../../pages/public/login/login-modal/loginUtils';
import { getCSR, getFormattedApplicantDetails, getSubsidyAmount } from '../../../pages/public/medical-plans/medicalPlansUtils';
import {
  BOOLEAN_VALUES,
  ENTITY_TYPES,
  GLOBAL_STATUS,
  LANGUAGE_CODES,
  MARKET_VALUE,
  NAV_PATHS,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  QUOTE_STATUS,
  QUOTE_TYPES,
  USER_DOMAINS,
  YES_NO_VALUES
} from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getcurrentAnchorPlan } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getDemographic, getNavigation, saveDemographic, saveDemographicsAndQuote, saveNavigation, saveQuote } from './SaveAndResumeLaterServices';

const getApplicantsForPlans = (selectedApplicantData: Applicant[], storeApplicantData: Applicant[]): Applicant[] => {
  return selectedApplicantData.map((selectedApplicant) => {
    return storeApplicantData.find((storeApplicant) => storeApplicant.applicantId === selectedApplicant.applicantId) || selectedApplicant;
  });
};
async function getQuoteDetails(plans, applicantDetails: Applicant[]) {
  const quoteLineItem: QuoteLineItem[] = plans.reduce((acc: QuoteLineItem[], plan) => {
    if (plan.plan?.contractCode) {
      const contractCode: any = plan.plan?.contractCode;
      const planType: any = plan.plan?.plan[0].planType;
      const quoteItem: QuoteLineItem = {
        contract: {
          contractCode: contractCode,
          planType: planType,
          portFolio: plan.plan?.onExchange ? 'true' : 'false'
        },
        applicant: plan.applicants ? getApplicantsForPlans(plan.applicants, applicantDetails) : applicantDetails,
        status: QUOTE_STATUS.ACTIVE
      };
      acc.push(quoteItem);
    }
    return acc;
  }, []);
  return quoteLineItem;
}

const handleSaveData = async (data: StoresQuoteAndUser) => {
  switch (data.location) {
    case NAV_PATHS.BASICS_LANDING:
      await saveDemographicInfo(data);
      break;
    case NAV_PATHS.ESTIMATE_COST_SAVINGS:
      await saveDemographicInfo(data);
      break;
    case NAV_PATHS.CREDIT_CALCULATOR:
      await saveDemographicInfo(data);
      break;
    case NAV_PATHS.OPTIONS:
      await saveDemographicInfo(data);
      break;
    case NAV_PATHS.MEDICAL_LISTING:
      await SaveDemographicsAndQuoteInfo(data);
      break;
    case NAV_PATHS.DENTAL_LISTING:
      await SaveDemographicsAndQuoteInfo(data);
      break;
    case NAV_PATHS.VISION_LISTING:
      await SaveDemographicsAndQuoteInfo(data);
      break;
    case NAV_PATHS.PLAN_SUMMARY:
      await SaveDemographicsAndQuoteInfo(data);
      break;
  }
};

const generateSubsidyPayload = (
  subsidyresponse: EstimateSavingsResponse,
  houseHoldInfo: HouseholdMemberDetails,
  applicantsLength: number,
  demographicId: string,
  subsidyId: string
) => {
  let subsidy: Subsidy = {
    houseHoldIncome: houseHoldInfo.houseHoldIncome ? Number(houseHoldInfo.houseHoldIncome) : 0,
    dependentUnder21: 0,
    dependentAbove21: 0,
    subsidyAmount: Number(getSubsidyAmount(subsidyresponse)),
    spouseAge: 0,
    fpl: 0,
    houseHoldSize: Number(houseHoldInfo.dependents) + applicantsLength,
    planTypeSelection: MARKET_VALUE,
    costShareReductionValue: Number(getCSR(subsidyresponse))
  };
  if (demographicId !== '0' && demographicId.length) {
    subsidy = {
      ...subsidy,
      demographicsId: Number(demographicId),
      subsidyId: Number(subsidyId)
    };
  }

  return subsidy;
};

const validateApplicantInfo = (globalStore: StoresQuoteAndUser) => {
  let isZipCodeChanged: boolean = false;
  let isCountyChanged: boolean = false;
  let isStateChanged: boolean = false;
  let isCoverageTypeChanged: boolean = false;
  let isApplicantFormDetailsChanged: boolean = false;
  if (globalStore.globalStore.applicantFormDetails.length !== globalStore.userStore.demographicInfo?.applicant?.length || 0) {
    isApplicantFormDetailsChanged = true;
  } else if (globalStore.globalStore.zipcode !== globalStore.userStore.demographicInfo?.zipCode || 0) {
    isZipCodeChanged = true;
  } else if (globalStore.globalStore.county !== globalStore.userStore.demographicInfo?.county || 0) {
    isCountyChanged = true;
  } else if (globalStore.globalStore.state !== globalStore.userStore.demographicInfo?.state || 0) {
    isStateChanged = true;
  } else if (globalStore.globalStore.coverageType !== globalStore.userStore.demographicInfo?.coverageType || 0) {
    isCoverageTypeChanged = true;
  } else if (JSON.stringify(globalStore.globalStore.applicantFormDetails) !== JSON.stringify(globalStore.userStore.demographicInfo?.applicant) || 0) {
    isApplicantFormDetailsChanged = true;
  }
  return isZipCodeChanged || isCountyChanged || isStateChanged || isCoverageTypeChanged || isApplicantFormDetailsChanged;
};

async function generateSaveDemographicPayload(globalStore: StoresQuoteAndUser): Promise<SaveDemographicRequest> {
  let demographicId: string = '0';
  // if any of the demographic/applicant info changed and there is no existing quote, set demographicId to 0
  if (useGlobalStore.getState().changeDemographicFlag) {
    demographicId = '0';
  } else {
    demographicId = globalStore.userStore.demographicInfo?.demographicId || '';
  }
  const subsidyId = globalStore.userStore.demographicInfo?.subsidy?.subsidyId || '';
  let demographicInfo: SaveDemographicRequest = {
    demographicInfo: {
      brand: globalStore.globalStore.brand,
      zipCode: globalStore.globalStore.zipcode,
      county: globalStore.globalStore.county,
      countyCode: globalStore.globalStore.countyCode,
      state: globalStore.globalStore.state,
      coverageEffectiveDate: formatDateToYYYYMMDD(globalStore.globalStore.coverageDate),
      coverageType: globalStore.globalStore.coverageType,
      shopperId: globalStore.userStore.shopperId,
      applicant: getFormattedApplicantDetails(globalStore.globalStore.applicantFormDetails),
      demographicId: demographicId,
      subsidy: generateSubsidyPayload(
        globalStore.globalStore.subsidyresponse,
        globalStore.globalStore.householdMemberValues,
        globalStore.globalStore.applicantFormDetails.length,
        demographicId,
        subsidyId.toString()
      ),
      hmcFilters: JSON.stringify(globalStore.globalStore.hmcFilter),
      savedUserEntity: generateSavedUserEntityPayload(globalStore),
      city: '',
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      proposalId: '',
      eid: '',
      currentAnchorPlan: getcurrentAnchorPlan(globalStore.globalStore.memberInfo.currentCoverages),
      agentId: '',
      channel: '',
      customerRefNumber: '',
      vendorLeadId: '',
      cid: '',
      proposalIdRef: '',
      hpaId: '',
      language: LANGUAGE_CODES.ENGLISH,
      emailConsent: '',
      source: ''
    }
  };

  return demographicInfo;
}

async function saveDemographicInfo(globalStore: StoresQuoteAndUser) {
  const demographicInfo = await generateSaveDemographicPayload(globalStore);
  const headers = {
    Webguid: globalStore.userStore.webAccountGUID
  };
  const response = await saveDemographic(demographicInfo, headers);
  if (response.data) {
    useUserStore.getState().updateDemographicInfo(response.data?.demographicInfo);
    updateApplicantsInfo(globalStore.globalStore.applicantFormDetails, response.data?.demographicInfo);
  }
  if (useUserStore.getState().quotes?.[0]?.quoteLineItem && useGlobalStore.getState().changeDemographicFlag) {
    useUserStore.getState().updateQuotes([]);
  } else {
    useGlobalStore.getState().updateChangeDemographicFlag(false);
  }

  return response.data;
}

async function SaveDemographicsAndQuoteInfo(globalStore: StoresQuoteAndUser) {
  const demographicInfo = await generateSaveDemographicPayload(globalStore);
  const quotePayload = await generateSaveQuotePayload(globalStore, globalStore.globalStore.applicantFormDetails);

  //code to save favaourite plans
  const selectedFavouritePlans: { planType: string; contractCode: string }[] = [];
  if (globalStore.globalStore.favouriteMedicalPlans.length > 0) {
    globalStore.globalStore.favouriteMedicalPlans.map((plan: string) => {
      selectedFavouritePlans.push({ planType: PLAN_TYPES.MEDICAL, contractCode: plan });
    });
  }
  if (globalStore.globalStore.favouriteDentalPlans.length > 0) {
    globalStore.globalStore.favouriteDentalPlans.map((plan: string) => {
      selectedFavouritePlans.push({ planType: PLAN_TYPES.DENTAL, contractCode: plan });
    });
  }
  if (globalStore.globalStore.favouriteVisionPlans.length > 0) {
    globalStore.globalStore.favouriteVisionPlans.map((plan: string) => {
      selectedFavouritePlans.push({ planType: PLAN_TYPES.VISION, contractCode: plan });
    });
  }
  let favouritePlans: any;
  if (selectedFavouritePlans.length > 0) {
    favouritePlans = await generateFavouritePayload(globalStore, selectedFavouritePlans, globalStore.globalStore.applicantFormDetails);
  }
  let finalQuoteInfo = favouritePlans ? [quotePayload.quote[0], favouritePlans.quote[0]] : [quotePayload.quote[0]];

  let saveQuoteDetails: SaveQuoteRequest = {
    quote: finalQuoteInfo
  };

  let payload: SaveDemographicsAndQuoteRequest = {
    demographicInfo: demographicInfo?.demographicInfo || {},
    quote: saveQuoteDetails ? saveQuoteDetails.quote : []
  };

  try {
    const response = await saveDemographicsAndQuote(payload, { Webguid: globalStore.userStore.webAccountGUID });
    if (response.data) {
      useUserStore.getState().updateDemographicInfo(response.data?.demographicInfo);
      updateApplicantsInfo(globalStore.globalStore.applicantFormDetails, response.data?.demographicInfo);
      useUserStore.getState().updateQuotes(response.data?.quote);
      useGlobalStore.getState().updateChangeDemographicFlag(false);
      if (globalStore.location === NAV_PATHS.PLAN_SUMMARY) {
        if (response.data?.quote[0]?.quoteLineItem.length) {
          response.data?.quote[0]?.quoteLineItem.map((quoteItem: QuoteLineItem) => {
            if (quoteItem?.contract?.planType === PLAN_TYPES_NAMES.MEDICAL) {
              useGlobalStore.getState().updateSelectedPlan({
                ...useGlobalStore.getState().selectedPlan,
                applicants: getFormattedApplicantDetails(getApplicantInfo(response.data?.demographicInfo?.applicant, quoteItem?.applicant))
              });
            } else if (quoteItem?.contract?.planType === PLAN_TYPES_NAMES.DENTAL) {
              useGlobalStore.getState().updateSelectedDentalPlan({
                ...useGlobalStore.getState().selectedDentalPlan,
                applicants: getFormattedApplicantDetails(getApplicantInfo(response.data?.demographicInfo?.applicant, quoteItem?.applicant))
              });
            } else if (quoteItem?.contract?.planType === PLAN_TYPES_NAMES.VISION) {
              useGlobalStore.getState().updateSelectedVisionPlan({
                ...useGlobalStore.getState().selectedVisionPlan,
                applicants: getFormattedApplicantDetails(getApplicantInfo(response.data?.demographicInfo?.applicant, quoteItem?.applicant))
              });
            }
          });
        }
        useGlobalStore.getState().updateIsSaveQuoteApiCalled(true);
      }
    }
  } catch (error) {
    console.warn('Error saving demographic and quote:', error);
  }
}

const getPages = (data: Pages[]) => {
  const pages = data;
  const pageDetails: Pages[] = pages.map((page) => {
    return {
      pageName: page.pageName,
      status: page.status,
      lastVisitedDate: page.lastVisitedDate,
      pageGroupId: page.pageGroupId
    };
  });
  return pageDetails;
};

async function generateSaveNavigationPayload(globalStore: StoresQuoteAndUser, pageInfo: Pages[]): Promise<SaveNavigationRequest> {
  const navigationDetails = {
    navigation: {
      pages: getPages(pageInfo),
      webGuid: globalStore.userStore.webAccountGUID,
      acnDemographic: globalStore.userStore.demographicInfo?.demographicId ?? '',
      lastVisitedPage: pageInfo.filter((page, i) => i === pageInfo.length - 1)?.[0]?.pageName || NAV_PATHS.HOME
    }
  };
  return navigationDetails;
}

async function generateSaveQuotePayload(globalStore: StoresQuoteAndUser, applicantDetails: Applicant[]): Promise<SaveQuoteRequest> {
  const selectedPlans = [globalStore.globalStore.selectedPlan, globalStore.globalStore.selectedVisionPlan, globalStore.globalStore.selectedDentalPlan];
  const quoteLineItem: QuoteLineItem[] = await getQuoteDetails(selectedPlans, applicantDetails);
  const totalPremium = getTotalAmount(selectedPlans);
  const quoteInfo: SaveQuoteRequest = {
    quote: [
      {
        demographicId: globalStore.userStore.demographicInfo.demographicId,
        type: QUOTE_TYPES.QUOTE,
        status: QUOTE_STATUS.ACTIVE,
        quoteLineItem: quoteLineItem,
        totalPremium: totalPremium,
        quoteId: globalStore.userStore.quotes[0]?.quoteId ?? ''
      }
    ]
  };
  return quoteInfo;
}

async function generateFavouritePayload(globalStore: StoresQuoteAndUser, plans, applicantDetails: Applicant[]): Promise<SaveQuoteRequest> {
  const quoteLineItem: QuoteLineItem[] = await getFavouritePlanDetails(plans, applicantDetails);
  const quoteInfo: SaveQuoteRequest = {
    quote: [
      {
        demographicId: globalStore.userStore.demographicInfo.demographicId,
        type: QUOTE_TYPES.FAVOURITE,
        status: QUOTE_STATUS.ACTIVE,
        quoteLineItem: quoteLineItem,
        quoteId: globalStore.userStore.favourites[0]?.quoteId ?? ''
      }
    ]
  };
  return quoteInfo;
}

async function getFavouritePlanDetails(plans: any, applicantDetails: Applicant[]) {
  const quoteLineItem: QuoteLineItem[] = plans.reduce((acc: QuoteLineItem[], plan) => {
    if (plan.contractCode) {
      const quoteItem: QuoteLineItem = {
        contract: {
          contractCode: plan.contractCode,
          planType: plan.planType,
          portFolio: 'null'
        },
        applicant: applicantDetails,
        status: QUOTE_STATUS.ACTIVE
      };
      acc.push(quoteItem);
    }
    return acc;
  }, []);
  return quoteLineItem;
}

async function saveQuoteInfo(globalStore: StoresQuoteAndUser) {
  let data: { demographicInfo: { applicant: Applicant[] } };
  if (useGlobalStore.getState().changeDemographicFlag) {
    await SaveDemographicsAndQuoteInfo(globalStore);
  } else {
    if (globalStore.userStore.demographicInfo?.demographicId) {
      // check if demographicId exists
      data = await getDemographicDetails(globalStore.userStore.demographicInfo?.demographicId);
    } else {
      // if not, save demographic info
      data = await saveDemographicInfo(globalStore);
    }
    let applicant = data?.demographicInfo?.applicant; // use the applicant details from the response
    if (!applicant && globalStore.globalStore.applicantFormDetails) {
      // if applicant details are not available, save demographic info
      const data = await saveDemographicInfo(globalStore); // if applicant details are not available, save demographic info
      applicant = data?.response.demographicInfo?.applicant;
    }
    //code to save favaourite plans
    const selectedFavouritePlans: { planType: string; contractCode: string }[] = [];
    if (globalStore.globalStore.favouriteMedicalPlans.length > 0) {
      globalStore.globalStore.favouriteMedicalPlans.map((plan: string) => {
        selectedFavouritePlans.push({ planType: PLAN_TYPES.MEDICAL, contractCode: plan });
      });
    }
    if (globalStore.globalStore.favouriteDentalPlans.length > 0) {
      globalStore.globalStore.favouriteDentalPlans.map((plan: string) => {
        selectedFavouritePlans.push({ planType: PLAN_TYPES.DENTAL, contractCode: plan });
      });
    }
    if (globalStore.globalStore.favouriteVisionPlans.length > 0) {
      globalStore.globalStore.favouriteVisionPlans.map((plan: string) => {
        selectedFavouritePlans.push({ planType: PLAN_TYPES.VISION, contractCode: plan });
      });
    }

    const quoteInfo = await generateSaveQuotePayload(globalStore, applicant);
    const headers = {
      Webguid: globalStore.userStore.webAccountGUID
    };
    let favouritePlans: any;
    if (selectedFavouritePlans.length > 0) {
      favouritePlans = await generateFavouritePayload(globalStore, selectedFavouritePlans, applicant);
    }
    let finalQuoteInfo = favouritePlans ? [quoteInfo.quote[0], favouritePlans.quote[0]] : [quoteInfo.quote[0]];

    let saveQuoteDetails: SaveQuoteRequest = {
      quote: finalQuoteInfo
    };
    try {
      const saveQuoteResponse = await saveQuote(saveQuoteDetails, headers);
      const quote: Quotes[] = saveQuoteResponse?.data?.quote;
      useUserStore.getState().updateQuotes(quote);
    } catch (error) {
      // Handle the error here
      console.error('Error saving quote:', error);
      return null;
    }
  }
}

const getTotalAmount = (plans: any[]) => {
  const amount = plans.map((plan) => (plan?.plan?.rateData?.rate?.totSubsidizedPrem ? plan?.plan?.rateData?.rate?.totSubsidizedPrem : 0.0));
  const sum = amount.reduce((total, currentValue) => total + currentValue, 0);
  return sum.toFixed(2);
};

async function getNavigationDetails(demographicId) {
  const headers = {
    acnDemographic: demographicId
  };
  const response = await getNavigation(headers);
  return response.data;
}

const getDemographicDetails = async (demographicId: string) => {
  let response;
  try {
    response = await getDemographic(demographicId);
    return response.data;
  } catch (error) {
    console.error('Error fetching demographic details:', error);
    return null;
  } finally {
  }
};

async function saveNavigationInfo(globalStore: StoresQuoteAndUser, pageInfo: Pages[]) {
  const navigationDetails = await generateSaveNavigationPayload(globalStore, pageInfo);
  const headers = {
    Webguid: globalStore.userStore.webAccountGUID
  };
  try {
    const response = await saveNavigation(navigationDetails, headers);
    return response.data;
  } catch (error) {
    console.warn('Error saving navigation:', error);
    return null;
  }
}

const getCurrentPageDetails = (data: any, visitedPages: Pages[]) => {
  const currentDate = dayjs().toISOString();
  let status = GLOBAL_STATUS.INPROGRESS;

  const page = {
    pageName: data.pageName,
    status: status,
    lastVisitedDate: currentDate,
    pageGroupId: data.pageName
  };
  return page;
};

const updatePageDetails = (visitedPages: Pages[], currentPage: Pages) => {
  const uniqueVisitedPages = Array.from(new Set(visitedPages.map((page) => page.pageName))).map((pageName) => {
    return visitedPages.find((page) => page.pageName === pageName);
  });
  const page = uniqueVisitedPages.find((page) => page?.pageName === currentPage?.pageName);
  if (page) {
    page.status = GLOBAL_STATUS.INPROGRESS;
  }
  const filteredPages = uniqueVisitedPages.filter((page) => page?.pageName !== currentPage?.pageName);

  // Update the status for the remaining records
  const updatedPages = filteredPages.map((page) => {
    return { ...page, status: GLOBAL_STATUS.COMPLETED };
  });
  const finalResult = updatedPages.length > 0 ? [...updatedPages, { ...currentPage }] : [currentPage];
  return finalResult;
};

const generateSavedUserEntityPayload = (globalStore: StoresQuoteAndUser) => {
  let savedUserEntity: SavedUserEntity[] = [];
  const allMatchedProviders: Provider[] = [
    ...globalStore.globalStore.savedProviders,
    ...globalStore.globalStore.savedDentists,
    ...globalStore.globalStore.savedEyeDoctors
  ];
  // save matched providers
  allMatchedProviders.map((providers) => {
    savedUserEntity.push({
      entityId: providers.providerIdentifier,
      entityType: ENTITY_TYPES.PROVIDER,
      entitySubType: getProviderType(providers.providerTypeCodeList[0]), // get the first provider type
      compositeEntityId: providers.address.addressId,
      entityLabel: globalStore.globalStore.zipcode,
      isGenericOptAvail: '',
      isMatched: '',
      tierName: ''
    });
  });
  // save matched drugs list
  globalStore.globalStore.drugsList.map((drug: DrugDetails) => {
    savedUserEntity.push({
      entityId: drug.formularyId.toString(),
      entityType: ENTITY_TYPES.DRUG,
      entitySubType: drug.drugType,
      compositeEntityId: drug.genericProductId,
      entityLabel: drug.drugLabel,
      isGenericOptAvail: drug.isGenericOptionsAvailable === BOOLEAN_VALUES.TRUE.toUpperCase() ? YES_NO_VALUES.YES : YES_NO_VALUES.NO,
      isMatched: '',
      tierName: ''
    });
  });
  // save HMC filters
  globalStore.globalStore.hmcFilter.questionaires.map((questionaire) => {
    savedUserEntity.push({
      entityId: questionaire.code,
      entityType: ENTITY_TYPES.HMC,
      entitySubType: '',
      compositeEntityId: questionaire.value,
      entityLabel: '',
      isGenericOptAvail: '',
      isMatched: '',
      tierName: ''
    });
  });
  return savedUserEntity;
};

const getProviderType = (providerTypeCode: string) => {
  const providerTypes = PROVIDER_TYPES.concat(PROVIDER_TYPES_DENTAL).concat(PROVIDER_TYPES_VISION);
  const providerType = providerTypes.find((provider) => provider.value === providerTypeCode);
  return providerType ? providerType.id : '';
};

const getProviderTypeCode = (providerType: string | undefined) => {
  const providerTypes = PROVIDER_TYPES_MAPPING.concat(PROVIDER_TYPES_DENTAL_PROVIDER_MAPPING).concat(PROVIDER_TYPES_VISION_MAPPING);
  const providerTypeCode = providerTypes.find((provider) => provider.id === providerType);
  return providerTypeCode ? providerTypeCode?.value : '';
};

const updateApplicantsInfo = (applicantDetails: Applicant[], demographicInfo: DemographicInfo) => {
  let copyOfDemographicApplicants: Applicant[] = [...demographicInfo?.applicant!];
  applicantDetails.forEach((applicant) => {
    for (let index = 0; index < copyOfDemographicApplicants.length; index++) {
      const demographicApplicant = copyOfDemographicApplicants[index];
      if (
        demographicApplicant.applicantType === applicant.applicantType &&
        demographicApplicant.firstName === applicant.firstName &&
        demographicApplicant.dateOfBirth === applicant.dateOfBirth &&
        demographicApplicant.isTabaccoUser === applicant.isTabaccoUser
      ) {
        if (demographicApplicant.applicantId !== applicant.applicantId) {
          applicant.applicantId = demographicApplicant.applicantId;
        }
        copyOfDemographicApplicants.splice(index, 1);
        break;
      }
    }
  });
  useGlobalStore.getState().updateApplicantFormDetails(applicantDetails);
};

export {
  SaveDemographicsAndQuoteInfo,
  generateSubsidyPayload,
  getApplicantsForPlans,
  getCurrentPageDetails,
  getNavigationDetails,
  getProviderType,
  getProviderTypeCode,
  getQuoteDetails,
  handleSaveData,
  saveDemographicInfo,
  saveNavigationInfo,
  saveQuoteInfo,
  updatePageDetails
};
