import dayjs from 'dayjs';
import { OFF_EXCHANGE_BASE_URLS } from '../../../api/api-name-list';
import { setDomesticPartnerAsSpouse } from '../../../pages/public/medical-plans/medicalPlansUtils';
import {
  AGENT_ROLES,
  APPLICANT_TYPES,
  ATK_APPLY_NOW_AVAILABLE_STATES,
  ATK_PRINT_TEMPLATENAME,
  BOOLEAN_VALUES,
  DATE_FORMATS,
  LANGUAGE_CODES,
  NAV_PATHS,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  SET_APPLICATION_CONSTANTS
} from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

const getFormattedEmailApplicantDetails = (applicants: Applicant[]): EmailApplicant[] => {
  const formattedEmailApplicants = applicants.map((applicant) => {
    return {
      dob: formatDateToYYYYMMDD(applicant.dateOfBirth),
      tobaccoUser: applicant?.isTabaccoUser ? applicant?.isTabaccoUser?.toUpperCase() : 'NO',
      type: applicant.applicantType === APPLICANT_TYPES.DOMESTICPARTNER ? APPLICANT_TYPES.SPOUSE : applicant.applicantType
    };
  });

  return formattedEmailApplicants;
};

const converBase64ToPdf = (pdfString: string) => {
  const byteCharacters = atob(pdfString.replace(/\s+/g, ''));
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const fileURL = window.URL.createObjectURL(blob);
  window.open(fileURL, '_blank');
};

const selectNowCheck = () => {
  let day = dayjs().date();
  let month = dayjs().month();
  return day >= 1 && day <= 31 && month === 10;
};

const checkTransitionExist = () => {
  let day = dayjs().date();
  let month = dayjs().month() + 1; // dayjs months are 0-indexed
  return day >= 1 && day <= 31 && month >= 10;
};

const calculateTotalCost = (medicalCost: number = 0, denatalCost: number = 0, visionCost: number = 0) => {
  let lselectedMedicalCost = medicalCost ? medicalCost : 0;
  let lselectedDentalCost = denatalCost ? denatalCost : 0;
  let lselectedVisionCost = visionCost ? visionCost : 0;
  return (lselectedMedicalCost + lselectedDentalCost + lselectedVisionCost)?.toFixed(2);
};

const getAtkProposalUserPayload = (): AtkSendProposalUser => {
  const { agentApplicantFormDetails, aTKsaveProposalResponse } = useGlobalStore.getState();
  const [primaryApplicant]: Applicant[] = getFormattedApplicantDetailsForATKProposal(agentApplicantFormDetails, true);
  return {
    dob: primaryApplicant?.dateOfBirth ?? '',
    email: primaryApplicant?.email ?? '',
    firstName: primaryApplicant?.firstName ?? '',
    lastName: primaryApplicant?.lastName ?? '',
    status: aTKsaveProposalResponse?.user?.status ?? '',
    type: aTKsaveProposalResponse?.user?.type ?? '',
    shopperId: aTKsaveProposalResponse?.user?.shopperId ?? ''
  };
};

const getFormattedApplicantDetailsForATKProposal = (applicants: Applicant[], onlyPrimary?: boolean): Applicant[] => {
  let dummyId = 0;
  const filteredApplicants = onlyPrimary ? applicants.filter((applicant) => applicant.applicantType === APPLICANT_TYPES.PRIMARY) : applicants;

  const formattedApplicants = filteredApplicants.map((applicant) => {
    dummyId++;

    const updatedApplicant = {
      ...applicant,
      dateOfBirth: formatDateToYYYYMMDD(applicant.dateOfBirth),
      isTabaccoUser: applicant?.isTabaccoUser ? applicant?.isTabaccoUser?.toUpperCase() : 'NO',
      applicantId: applicant.applicantId ?? dummyId.toString()
    };

    if (updatedApplicant.gender === '') {
      delete applicant.gender;
    }

    return updatedApplicant;
  });

  return formattedApplicants;
};

const getCoverageType = (agentCoverageType: string): string => {
  switch (agentCoverageType) {
    case PLAN_TYPES.MDV:
    case PLAN_TYPES.MEDICAL:
      return PLAN_TYPES.MEDICAL;
    case PLAN_TYPES.DENTAL:
      return PLAN_TYPES.DENTAL;
    case PLAN_TYPES.VISION:
      return PLAN_TYPES.VISION;
    default:
      return '';
  }
};

const getAtkProposalDemographic = (): DemographicInfo => {
  const {
    agentZipcode,
    agentCoverageDate,
    agentCountyCode,
    agentState,
    agentBrand,
    agentCounty,
    agentCoverageType,
    agentSSOInfo,
    agentApplicantFormDetails,
    aTKsaveProposalResponse
  } = useGlobalStore.getState();
  return {
    zipCode: agentZipcode,
    coverageEffectiveDate: dayjs(agentCoverageDate).format(DATE_FORMATS.YYYYMMDD),
    countyCode: agentCountyCode,
    state: agentState,
    brand: agentBrand,
    county: agentCounty,
    coverageType: getCoverageType(agentCoverageType),
    applicant: setDomesticPartnerAsSpouse(getFormattedApplicantDetailsForATKProposal(agentApplicantFormDetails)),
    demographicId: aTKsaveProposalResponse?.demographicInfo?.demographicId,
    agentId: aTKsaveProposalResponse?.agent?.agentId,
    shopperId: aTKsaveProposalResponse?.user?.shopperId ?? '',
    hpaId: '',
    customerRefNumber: agentSSOInfo?.applicantInfo?.customerRefNumber ?? ''
  };
};

const getAgentDetailsByState = (agentSSOInfo: AgentSSOInfo, state: string) => {
  const agentDetails = agentSSOInfo?.agentInfo?.agent[0]?.states?.state.find((item) => item.stateName === state);
  return agentDetails;
};

const getAtkProposalAgentPayload = (agentSSOInfo: AgentSSOInfo, state: string): AtkSendProposalAgent => {
  const { agentUserID, writingTIN, writingTINAgentType, NPN, agentFName, agentLName, agencyName, webAcctId } = agentSSOInfo.agentInfo.agent[0];
  const agentDetailsByState = getAgentDetailsByState(agentSSOInfo, state);

  return {
    smUserID: agentSSOInfo.eventHeader.smUserID ?? '',
    brokerUserID: agentSSOInfo.eventHeader.brokerUserID ?? '',
    agentType: agentSSOInfo.agentType ?? '',
    agentUserID: agentUserID,
    writingTIN: writingTIN,
    writingTINAgentType: writingTINAgentType,
    npn: NPN,
    agentFirstName: agentFName,
    agentLastName: agentLName,
    agencyName: agencyName ?? agentDetailsByState?.agencyInfo?.agency?.agencyName ?? '',
    email: agentDetailsByState?.email ?? '',
    phone: agentDetailsByState?.phone ?? '',
    phoneExt: agentDetailsByState?.phoneExt ?? '',
    fax: agentDetailsByState?.fax ?? '',
    licenseNumber: agentDetailsByState?.licenseNumber ?? '',
    address: {
      addressLine1: agentDetailsByState?.agentAddressInfo?.agentAddress?.address1 ?? '',
      city: agentDetailsByState?.agentAddressInfo?.agentAddress?.city ?? '',
      state: agentDetailsByState?.agentAddressInfo?.agentAddress?.state ?? '',
      postalCode: agentDetailsByState?.agentAddressInfo?.agentAddress?.zip ?? ''
    },
    paidTin: agentDetailsByState?.agencyInfo?.agency?.paidTIN ?? '',
    parentTin: agentDetailsByState?.agencyInfo?.agency?.parentTIN ?? '',
    agentCode: agentDetailsByState?.agencyInfo?.agency?.agentCode ?? '',
    agentWebGuid: agentSSOInfo.agentType === AGENT_ROLES.DELEGATE || agentSSOInfo.agentType === AGENT_ROLES.TELEASSISTANT ? agentUserID : '', //for DELEGATE or TELEASSISTANT send agentUserID else send ''
    webAcctId: webAcctId
    // webAcctId: '142857'
  };
};

const replaceHtmlTags = (htmlString: string): string => {
  let benifitValue: string = htmlString;
  benifitValue = benifitValue.replace(/\"/g, '');
  benifitValue = benifitValue.replace(/<a href=/g, '');
  benifitValue = benifitValue.replace(/target=_blank>here<\/a>/g, '');
  benifitValue = benifitValue.replace(/<br>/g, ' ');
  benifitValue = benifitValue.replace(/&reg;/g, '');
  benifitValue = benifitValue.replace(/&#174;/g, '');
  benifitValue = benifitValue.replace(/<\/?b>/g, '');

  return benifitValue;
};

const getAtkPlanBenefits = (productBenefits: PlanBenefit[]): AtkPlanBenefit[] => {
  return productBenefits.map((benefit) => {
    return {
      displayName: benefit.planBenefitDisplayName,
      value: replaceHtmlTags(benefit.planBenefitValue),
      sortOrder: benefit.planBenefitSortOrder.toString()
    };
  });
};

const getProductObj = (product: Contract, pdfBrochureUrl: string, applicants: Applicant[]): AtkProposalContract => {
  const { isWellpoint } = useUserStore.getState();
  const { REACT_APP_ENV } = process.env;
  const url = new URL(window.location.origin);
  const { aTKsaveProposalResponse } = useGlobalStore.getState();

  return {
    planType: product?.plan[0]?.planType ?? '',
    applicant: applicants,
    planName: `${product?.planMarketingName} (${product?.contractCode})`,
    contractCode: product?.contractCode ?? '',
    onExchangeIndicator: product?.onExchange.toString() ?? '',
    coloradoOptionPlanIndicator: product?.poPlan === true ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE,
    brochureUrl: pdfBrochureUrl,
    // planDetailsURL: `${isWellpoint ? WELLPOINT_OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? ''] : OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/proposal/plans/plandetail?proposalID=${aTKsaveProposalResponse?.proposalId}&typeofCoverage=medical&popup=medical&PlanId=${product?.medicalProduct?.plan[0]?.planID}&contractCode=${product?.medicalProduct?.contractCode}`,
    planDetailsURL: isWellpoint
      ? `${url.origin}/individuals/proposal/plan-details?proposalID=${aTKsaveProposalResponse?.proposalId}&coverageType=${useGlobalStore.getState().agentCoverageType}&planType=${product?.plan[0]?.planType}&PlanId=${product?.plan[0]?.planID}&contractCode=${product?.contractCode}`
      : `${OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/proposal/plans/plandetail?proposalID=${aTKsaveProposalResponse?.proposalId}&typeofCoverage=medical&popup=medical&PlanId=${product?.plan[0]?.planID}&contractCode=${product?.contractCode}`,
    planBenefit: getAtkPlanBenefits(product?.plan[0]?.planBenefits ?? []),
    rate: {
      totalMonthlyPremium: product?.rateData?.rate?.totalPremium?.toFixed(2).toString() ?? '0.0',
      SubsidizedPremium: product?.rateData?.rate?.subsidyAmtApplied?.toFixed(2).toString() ?? '0.0',
      totalMemberResponsibility: product?.rateData?.rate?.totSubsidizedPrem?.toFixed(2).toString() ?? '0.0'
    }
  };
};

const getplanContracts = (product: SelectedProduct, pdfBrochureUrl: string): AtkProposalContract[] => {
  const planContracts: AtkProposalContract[] = [];
  const medicalProduct = product?.medicalProduct && getProductObj(product?.medicalProduct, pdfBrochureUrl, product?.medicalApplicants);
  const dentalProduct = product?.dentalProduct && getProductObj(product?.dentalProduct, pdfBrochureUrl, product?.dentalApplicants);
  const visionProduct = product?.visionProduct && getProductObj(product?.visionProduct, pdfBrochureUrl, product?.visionApplicants);
  medicalProduct && planContracts.push(medicalProduct);
  dentalProduct && planContracts.push(dentalProduct);
  visionProduct && planContracts.push(visionProduct);
  return planContracts;
};

const showApplyNowButton = (product: SelectedProduct, state: string, labels: any): string => {
  const { isWellpoint } = useUserStore.getState();
  const { agentYear } = useGlobalStore.getState();
  if (isWellpoint) {
    return !product?.medicalProduct?.onExchange || ATK_APPLY_NOW_AVAILABLE_STATES.includes(state)
      ? agentYear === labels?.WELLPOINT?.WINDOW_SHOPPING_YEAR && labels?.WELLPOINT?.IS_WINDOW_SHOPPING_PERIOD === 'true'
        ? SET_APPLICATION_CONSTANTS.NO
        : SET_APPLICATION_CONSTANTS.YES
      : SET_APPLICATION_CONSTANTS.NO;
  } else {
    //Anthem flow - need to add condition for show apply now button
    return SET_APPLICATION_CONSTANTS.NO;
  }
};

const getApplyNowButtonURL = (product: SelectedProduct, state: string, labels: any): string => {
  const { isWellpoint } = useUserStore.getState();
  const { aTKsaveProposalResponse, agentYear } = useGlobalStore.getState();
  if (isWellpoint) {
    return !product?.medicalProduct?.onExchange || ATK_APPLY_NOW_AVAILABLE_STATES.includes(state)
      ? agentYear === labels?.WELLPOINT?.WINDOW_SHOPPING_YEAR && labels?.WELLPOINT?.IS_WINDOW_SHOPPING_PERIOD === 'true'
        ? labels?.WELLPOINT?.EXCEPTION_MESSAGE_DURING_WS
        : `${window.location.origin}${NAV_PATHS.STANDALONE_LOGIN}?quoteId=${aTKsaveProposalResponse?.quotes?.quote[0]?.quoteId}&state=${state}`
      : labels?.ATK_APPLY_NOW_EXCEPTION_MESSAGE;
  } else {
    //Anthem flow - need to add condition for apply now url
    return labels?.ATK_APPLY_NOW_EXCEPTION_MESSAGE;
  }
};

const getATKProposalQuotePayload = (comparedProducts: SelectedProducts, state: string, labels: any, pdfBrochureUrl: string): AtkSendProposalQuote[] => {
  return comparedProducts.map((product, index) => {
    const medicalPremium = product?.medicalProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
    const dentalPremium = product?.dentalProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
    const visionPremium = product?.visionProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
    return {
      sortOrder: (index + 1).toString(),
      displayApplyNow: showApplyNowButton(product, state, labels),
      applyNow: getApplyNowButtonURL(product, state, labels),
      totalPremium: calculateTotalCost(medicalPremium, dentalPremium, visionPremium).toString() ?? '0.00',
      planContract: getplanContracts(product, pdfBrochureUrl)
    };
  });
};

const getAtkSendProposalPayload = (emailFormData: EmailProps, labels: any, pdfBrochureUrl: string): AtkSendProposalPayload => {
  const { agentSSOInfo, comparedProducts, agentState, aTKsaveProposalResponse } = useGlobalStore.getState();
  const payload = {
    user: getAtkProposalUserPayload(),
    demographicInfo: getAtkProposalDemographic(),
    agent: getAtkProposalAgentPayload(agentSSOInfo, agentState),
    status: aTKsaveProposalResponse?.status ?? '',
    proposalId: aTKsaveProposalResponse?.proposalId ?? '',
    languageCode: LANGUAGE_CODES.EN.toUpperCase(),
    toEmail: emailFormData.email,
    subject: emailFormData.subject,
    message: emailFormData.message,
    disclaimer1: labels?.WELLPOINT?.DISCLAIMER_1,
    disclaimer2: labels?.WELLPOINT?.DISCLAIMER_2,
    disclaimer3: '',
    disclaimer4: '',
    disclaimer5: '',
    proposalQuote: getATKProposalQuotePayload(comparedProducts, agentState, labels, pdfBrochureUrl)
  };

  return payload;
};

const getAtkPrintAgentInfo = () => {
  const { agentSSOInfo, comparedProducts, agentState, aTKsaveProposalResponse, agentBrand, agentApplicantFormDetails } = useGlobalStore.getState();
  const { isWellpoint } = useUserStore.getState();
  const { agentUserID, writingTIN, writingTINAgentType, NPN, agentFName, agentLName, agencyName, webAcctId } = agentSSOInfo.agentInfo.agent[0];
  const agentDetailsByState = getAgentDetailsByState(agentSSOInfo, agentState);
  const agentInfo: AtkPrintAgent = {
    agentTin: writingTIN,
    parentTin: agentDetailsByState?.agencyInfo?.agency?.parentTIN ?? '',
    firstName: agentFName,
    lastName: agentLName,
    brand: agentBrand,
    licenceNumber: agentDetailsByState?.licenseNumber ?? '',
    agencyName: agencyName ?? agentDetailsByState?.agencyInfo?.agency?.agencyName ?? '',
    address1: agentDetailsByState?.agentAddressInfo?.agentAddress?.address1 ?? '',
    address2: agentDetailsByState?.agentAddressInfo?.agentAddress?.address2 ?? '',
    city: agentDetailsByState?.agentAddressInfo?.agentAddress?.city ?? '',
    stateCode: agentDetailsByState?.agentAddressInfo?.agentAddress?.state ?? '',
    zipCode: agentDetailsByState?.agentAddressInfo?.agentAddress?.zip ?? '',
    agentPhone: agentDetailsByState?.phone as string,
    aorPhone: null,
    email: agentDetailsByState?.email as string,
    npn: NPN,
    aor: null
  };
  return agentInfo;
};

const getAdditionalParams = (disclaimer: string, logoUrl: string) => {
  const { comparedProducts } = useGlobalStore.getState();
  const printAdditionalParams: AtkPrintAdditionalParams = {
    logoUrl: logoUrl,
    templateName: ATK_PRINT_TEMPLATENAME,
    disclaimer: disclaimer
  };
  return printAdditionalParams;
};

const getAge = (dob: string, coverageDate: string): number => {
  const dobDate = dayjs(dob);
  const age = dayjs(coverageDate).diff(dobDate, 'month');
  return age >= 12 ? Math.floor(age / 12) : age;
};

const getPresentValue = (applicant: Applicant, type: string): boolean => {
  const { comparedProducts } = useGlobalStore.getState();

  switch (type) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return comparedProducts[0]?.medicalApplicants?.some((medicalApplicant) => medicalApplicant?.applicantId === applicant?.applicantId) || false;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return comparedProducts[0]?.dentalApplicants?.some((dentalApplicant) => dentalApplicant?.applicantId === applicant?.applicantId) || false;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return comparedProducts[0]?.visionApplicants?.some((visionApplicant) => visionApplicant?.applicantId === applicant?.applicantId) || false;
    default:
      return false;
  }
};

const getAtkPrintApplicants = () => {
  const { agentApplicantFormDetails, agentCoverageDate } = useGlobalStore.getState();
  const { isWellpoint } = useUserStore.getState();
  const atkApplicants: AtkPrintApplicants[] = [];
  agentApplicantFormDetails.map((applicant) => {
    atkApplicants.push({
      type: applicant?.applicantType === APPLICANT_TYPES.DOMESTICPARTNER ? APPLICANT_TYPES.SPOUSE : applicant?.applicantType,
      firstName: applicant?.firstName,
      dob: applicant?.dateOfBirth,
      tobaccoUser: applicant?.isTabaccoUser,
      age: getAge(applicant?.dateOfBirth, agentCoverageDate),
      medicalPresent: isWellpoint ? true : getPresentValue(applicant, PLAN_TYPES_NAMES.MEDICAL_PRODUCT),
      dentalPresent: isWellpoint ? false : getPresentValue(applicant, PLAN_TYPES_NAMES.DENTAL_PRODUCT),
      visionPresent: isWellpoint ? false : getPresentValue(applicant, PLAN_TYPES_NAMES.VISION_PRODUCT)
    });
  });
  return atkApplicants;
};

const getAtkPrintDemographics = () => {
  const { agentZipcode, agentCoverageDate, agentState, agentBrand, agentCounty, aTKsaveProposalResponse } = useGlobalStore.getState();
  const PrintDemographics: AtkPrintDemographics = {
    id: parseInt(aTKsaveProposalResponse?.demographicInfo?.demographicId as string),
    stateCode: agentState,
    zipCode: agentZipcode,
    county: agentCounty,
    coverageDate: formatDateToYYYYMMDD(agentCoverageDate),
    createdDate: formatDateToYYYYMMDD(aTKsaveProposalResponse?.createdTS as string),
    applicants: getAtkPrintApplicants(),
    agent: getAtkPrintAgentInfo(),
    brand: agentBrand
  };
  return PrintDemographics;
};

const getAtkPrintPlanBenefits = (productBenefits: PlanBenefit[]): AtkPrintPlanBenefit[] => {
  return productBenefits.map((benefit) => {
    return {
      name: benefit.planBenefitDisplayName,
      value: benefit.planBenefitValue,
      sortOrder: benefit.planBenefitSortOrder
    };
  });
};

const getAtkPlan = (plan: Contract) => {
  const AtkPrintPlan: AtkPrintPlan[] = [];
  AtkPrintPlan.push({
    displayName: plan.plan[0]?.planDisplayName,
    planName: plan.plan[0]?.planName,
    type: plan.plan[0]?.planType,
    benefits: getAtkPrintPlanBenefits(plan?.plan[0]?.planBenefits)
  });
  return AtkPrintPlan;
};

const getAddOnProductObj = (product: Contract): AtkPrintAddOn => {
  return {
    planName: product?.planMarketingName,
    totalMonthlyPremium: product?.rateData?.rate?.totSubsidizedPrem?.toFixed(2).toString() ?? '0.0',
    contractCode: product?.contractCode ?? '',
    planType: product?.plan[0]?.planType ?? ''
  };
};

const getAddOnProducts = (primaryProduct: Contract, selectedProduct: SelectedProduct) => {
  let addOnProducts: AtkPrintAddOn[] = [];
  switch (primaryProduct?.plan[0]?.planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      selectedProduct?.dentalProduct && addOnProducts.push(getAddOnProductObj(selectedProduct?.dentalProduct));
      selectedProduct?.visionProduct && addOnProducts.push(getAddOnProductObj(selectedProduct?.visionProduct));
      break;
    case PLAN_TYPES_NAMES.DENTAL:
      selectedProduct?.visionProduct && addOnProducts.push(getAddOnProductObj(selectedProduct?.visionProduct));
      break;
    case PLAN_TYPES_NAMES.VISION:
      return [];
    default:
      return [];
  }
  return addOnProducts;
};

const getPrintProductObj = (primaryProduct: Contract, selectedProduct: SelectedProduct) => {
  const medicalPremium = selectedProduct?.medicalProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
  const dentalPremium = selectedProduct?.dentalProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
  const visionPremium = selectedProduct?.visionProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
  const totalCost = calculateTotalCost(medicalPremium, dentalPremium, visionPremium).toString() ?? '0.00';
  return {
    contract: {
      contractCode: primaryProduct?.contractCode,
      planMarketingName: primaryProduct?.planMarketingName,
      plan: getAtkPlan(primaryProduct),
      tier: primaryProduct?.tiersType
    },
    rate: {
      totalMonthlyPremium: primaryProduct?.rateData?.rate?.totalPremium?.toString() as string,
      subsidizedPremium: primaryProduct?.rateData?.rate?.subsidyAmtApplied?.toString() as string,
      totalMemberResponsibility: primaryProduct?.rateData?.rate?.totSubsidizedPrem?.toString() as string
    },
    addOnPlan: primaryProduct && getAddOnProducts(primaryProduct, selectedProduct),
    quoteTotalCost: totalCost
  };
};

const getAtkContractList = () => {
  const { comparedProducts } = useGlobalStore.getState();
  const atkContract: AtkPrintContractList[] = [];

  comparedProducts.map((product) => {
    const primaryProduct = product?.medicalProduct ? product?.medicalProduct : product?.dentalProduct ? product?.dentalProduct : product?.visionProduct;

    const medicalProduct = primaryProduct && getPrintProductObj(primaryProduct, product);
    medicalProduct && atkContract.push(medicalProduct);
  });
  return atkContract;
};

const getAtkPrintPayload = (disclaimer: string, logoUrl: string) => {
  const printPayload: AtkPrintPayload = {
    additionalParams: getAdditionalParams(disclaimer, logoUrl),
    demographics: getAtkPrintDemographics(),
    contractList: getAtkContractList()
  };
  return printPayload;
};

const getEmailAdress = () => {
  const { agentApplicantFormDetails } = useGlobalStore.getState();
  const [primaryApplicant]: Applicant[] = getFormattedApplicantDetailsForATKProposal(agentApplicantFormDetails, true);
  return primaryApplicant?.email;
};

export {
  calculateTotalCost,
  checkTransitionExist,
  converBase64ToPdf,
  getAgentDetailsByState,
  getAtkPrintPayload,
  getAtkPrintPlanBenefits,
  getAtkProposalAgentPayload,
  getAtkProposalUserPayload,
  getAtkSendProposalPayload,
  getEmailAdress,
  getFormattedEmailApplicantDetails,
  replaceHtmlTags,
  selectNowCheck
};
